// Pricing.js

import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

function Pricing() {
  // ?????? ???? ????? (6 ??.) ? ??????? ????????? �Scope of Work�
  // ? ????????? ??????? ??????????. ????? ?????? ?? ???? ????.
  const services = [
    {
      name: 'Email campaigns',
      tasks: [
        'Analysis & goal setting',
        'Audience segmentation & database prep',
        'Content & template design',
        'Launching, monitoring & analytics',
        'A/B testing & optimization',
        'Reporting & future planning'
      ],
      price: '\u20AC0.01 / per email'
    },
    {
      name: 'Parsing contacts',
      tasks: [
        'Identifying objectives & data sources',
        'Tool selection & legal compliance',
        'Data scraping & filtering',
        'Validation (emails, phones)',
        'Segmentation & final hand-off'
      ],
      price: '\u20AC0.01 / per email'
    },
    {
      name: 'Google Ads',
      tasks: [
        'Strategic planning & competitor analysis',
        'Keyword research & grouping',
        'Campaign structure & ad copy',
        'Tracking (Analytics / Tag Manager)',
        'Ongoing optimization & A/B tests',
        'Reporting & scaling'
      ],
      price: '\u20AC400 - \u20AC1,200'
    },
    {
      name: 'Facebook Ads',
      tasks: [
        'Goal setting & audience targeting',
        'Creative development (images/videos)',
        'Ad campaigns setup & budgeting',
        'Pixel tracking & retargeting',
        'Performance monitoring & adjustments',
        'Reporting & expansion'
      ],
      price: '\u20AC300 - \u20AC1,200'
    },
    {
      name: 'VK Ads',
      tasks: [
        'Strategy & audience analysis',
        'Ad creatives (banners, text, video)',
        'Campaign settings & targeting',
        'Daily monitoring & bid adjustments',
        'A/B testing & optimization',
        'Reporting & scaling'
      ],
      price: '\u20AC300 - \u20AC1,000'
    },
    {
      name: 'SMM marketing',
      tasks: [
        'Brand & audience audit',
        'Content strategy & plan',
        'Profile design & content creation',
        'Community management & engagement',
        'Paid promotion & collaborations',
        'Analytics & ROI reporting'
      ],
      price: '\u20AC300 - \u20AC1,200'
    },
  ];

  return (
  
  <>
      <Helmet>
        <title>Pricing - Mailsball</title>
        <meta
          name="description"
          content="Discover our pricing for marketing services including email campaigns, SMS campaigns, Google Ads, Facebook Ads, VK Ads, and SMM marketing. Transparent pricing per project or monthly + tax."
        />
        <meta
          name="keywords"
          content="pricing, marketing services, email campaigns, SMS campaigns, Google Ads, Facebook Ads, VK Ads, SMM marketing, Mailsball"
        />
        <link rel="canonical" href="https://mailsball.com/pricing" />
        {/* Open Graph */}
        <meta property="og:title" content="Pricing - Mailsball" />
        <meta
          property="og:description"
          content="Discover our pricing for marketing services including email campaigns, SMS campaigns, Google Ads, Facebook Ads, VK Ads, and SMM marketing. Transparent pricing per project or monthly + tax."
        />
        <meta property="og:url" content="https://mailsball.com/pricing" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://mailsball.com/images/Loogoo.png" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Pricing - Mailsball" />
        <meta
          name="twitter:description"
          content="Discover our pricing for marketing services including email campaigns, SMS campaigns, Google Ads, Facebook Ads, VK Ads, and SMM marketing. Transparent pricing per project or monthly + tax."
        />
        <meta name="twitter:image" content="https://mailsball.com/images/Loogoo.png" />
      </Helmet>
    <Box sx={{ maxWidth: 1200, mx: 'auto', px: 2, py: 4 }}>
     <Box sx={{ textAlign: 'center', mb: 1, pb: 2 }}>
  <Typography
    variant="h1"
    sx={{
      fontWeight: 'bold',
      mb: 2,
      fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' }
    }}
  >
    Pricing
  </Typography>
  <Typography
    variant="h6"
    sx={{
      fontWeight: 400,
      color: 'gray',
      mx: 'auto',
      maxWidth: 600,
      fontSize: { xs: '1rem', sm: '1.125rem', md: '1.125rem' },
      lineHeight: 1.2
    }}
  >
Discover our competitive pricing options for a wide range of marketing services tailored to your needs.
  </Typography>
</Box>
    

      {/* ??????? ??? ??????? */}
      <TableContainer component={Paper} sx={{ backgroundColor: '#fff' }}>
        <Table>
          {/* ????????? ??????? */}
          <TableHead sx={{ backgroundColor: '#f7f7f7' }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Service
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Scope of Work
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Price Range
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* ???? ???????: ?????? ?????? = ???? ?????? */}
          <TableBody>
            {services.map((service, index) => (
              <TableRow key={index}>
                {/* 1) ???????? ?????? */}
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {service.name}
                  </Typography>
                </TableCell>

                {/* 2) ??????? ???????? ????? ? ???? ?????? */}
                <TableCell>
                  <ul style={{ margin: 0, paddingLeft: '20px' }}>
                    {service.tasks.map((task, i) => (
                      <li key={i}>
                        <Typography variant="body2" sx={{ color: '#444' }}>
                          {task}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </TableCell>

                {/* 3) ??????? ???????? / ????????? */}
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {service.price}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#888' }}>
                    per project / monthly + tax
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </>
  );
}

export default Pricing;
