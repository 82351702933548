// PrivateLayout.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Outlet } from 'react-router-dom';
import ProfileMenu from '../components/ProfileMenu';
import MenuNavLeft from '../MenuNavLeft';
import SidebarMenu from '../components/SidebarMenu';
import { getMenuItems } from '../menuItems';
import NotificationBell from '../components/NotificationBell';
import CustomNotificationContent from '../components/CustomNotificationContent';
import { useSnackbar } from 'notistack';
// Важно импортировать:
import { io } from 'socket.io-client';
import axios from 'axios';

const drawerWidth = 270;

function PrivateLayout({ authenticated, user: initialUser, handleLogout }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  // Локальное состояние пользователя
  const [user, setUser] = useState(initialUser);

  // Состояния для списка заявок и "непрочитанных"
  const [requests, setRequests] = useState([]);
  const [unreadRequests, setUnreadRequests] = useState(new Set());

  // Реф для хранения сокета
  const socketRef = useRef(null);
  
  useEffect(() => {
  if (user?._id) {
    const notViewed = requests.filter(
      r => !(r.viewedBy || []).includes(user._id) && r.status !== 'Closed'
    );
    setUnreadRequests(new Set(notViewed.map(r => r._id)));
  }
}, [requests, user]);

  // При загрузке (или при смене user) запрашиваем уведомления
  useEffect(() => {
    if (authenticated && user?._id) {
      axios.get('/api/notifications', { withCredentials: true })
        .then(res => {
          setNotifications(res.data || []);
        })
        .catch(err => console.error('Failed to fetch notifications:', err));
    }
  }, [authenticated, user]);
  
useEffect(() => {
  if (!socketRef.current) {
    socketRef.current = io('https://mailsball.com', { withCredentials: true });
  }
  const socket = socketRef.current;
  if (user?._id) {
  socket.emit('join', user._id); // <-- это главное!
}

  socket.on('notificationCreated', (newNotif) => {
    console.log('Received new notification in PrivateLayout:', newNotif);
    // Просто обновляем state, без enqueueSnackbar
    if (
      newNotif.user &&
      user?._id &&
      newNotif.user.toString() === user._id.toString()
    ) {
      setNotifications(prev => [newNotif, ...prev]);
    }
  });

  return () => {
    socket.off('notificationCreated');
  };
}, [user]);

  // Функция отметки уведомления как прочитанного
  const markNotificationAsRead = (notificationId) => {
    axios.patch('/api/notifications/read', { notificationId }, { withCredentials: true })
      .then(() => {
        setNotifications(prev =>
          prev.map(n =>
            n._id === notificationId ? { ...n, isRead: true } : n
          )
        );
      })
      .catch(err => console.error('Failed to mark notification as read:', err));
  };

  // Инициализация сокета и подписка на "requestUpdated"
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://mailsball.com', { withCredentials: true });
    }
    const socket = socketRef.current;
    
    if (user?._id) {
  socket.emit('join', user._id); // <-- это главное!
}

    socket.on('requestUpdated', (updatedReq) => {
      setRequests(prevRequests => {
        const index = prevRequests.findIndex(r => r._id === updatedReq._id);
        let newArr;
        if (index === -1) {
          newArr = [...prevRequests, updatedReq];
        } else {
          newArr = prevRequests.map(r =>
            r._id === updatedReq._id ? updatedReq : r
          );
        }
        return newArr;
      });
    });

    return () => {
      socket.off('requestUpdated');
    };
  }, [user]);

  // Загружаем список заявок при изменении пользователя
  useEffect(() => {
    if (!authenticated || !user?._id) return;

    axios.get('/api/requests', { withCredentials: true })
      .then((res) => {
        const data = res.data || [];
        setRequests(data);

        // Используем данные из ответа (data), а не текущее состояние requests
        const notViewed = data.filter(
          r => !(r.viewedBy || []).includes(user._id) && r.status !== 'Closed'
        );
        setUnreadRequests(new Set(notViewed.map(r => r._id)));
      })
      .catch((err) => {
        console.error('Failed to fetch requests:', err);
      });
  }, [authenticated, user]);

  // При изменении initialUser (из App.js) — обновляем локальное состояние
  useEffect(() => {
    if (initialUser && initialUser.role) {
      setUser(initialUser);
    }
  }, [initialUser]);

  // Функция обновления user-данных
  const updateUser = (newUserData) => {
    setUser(prevUser => ({
      ...prevUser,
      ...newUserData
    }));
  };

  // Открыть/закрыть мобильное меню
  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  // Формируем пункты меню
  const menuItems = getMenuItems(authenticated);

  // Для Header (Desktop) отбираем Home и Request Form
  const headerItems = menuItems.filter(
    (item) => item.text === 'Home' || item.text === 'Request Form'
  );

  // Кнопка "Pricing"
  const pricingButton = (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: '#ffc800',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(204, 133, 0, 0.08)'
        }
      }}
    >
      Pricing
    </Button>
  );

  // Кнопка "Logout"
  const authButton = (
    <Button
      onClick={handleLogout}
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Logout
    </Button>
  );

  // Считаем число непрочитанных
  const unreadCount = unreadRequests.size;
      
  
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Верхняя панель (AppBar) */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#300259',
          boxShadow: 'none'
        }}
      >
        <Toolbar>
          {/* Логотип */}
          <Link
            to="/"
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <img
              src="/images/logo.svg"
              alt="Mailsball Logo"
              style={{ height: '17px', marginRight: '10px' }}
            />
          </Link>

          <Box sx={{ flexGrow: 1 }} />
         
          {isMobile ? (
            // На мобильных — иконка меню
            <IconButton sx={{ color: '#fff' }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          ) : (
            // На десктопе — пункты хедера: Home, Request Form, Pricing и профиль
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {headerItems.map((item) => (
                <Button
                  key={item.text}
                  component={Link}
                  to={item.path}
                  sx={{
                    color: '#fff',
                    textTransform: 'none'
                  }}
                >
                  {item.text}
                </Button>
              ))}
              {pricingButton}
            {authenticated ? (
  [
    <NotificationBell
      key="notification-bell"
      notifications={notifications}
      onMarkAsRead={markNotificationAsRead}
      user={user}
      handleLogout={handleLogout}
    />,
    <ProfileMenu key="profile-menu" user={user} handleLogout={handleLogout} />
  ]
) : (
  authButton
)}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Левое меню */}
      {isMobile ? (
        <SidebarMenu
          menuItems={menuItems}
          mobileOpen={mobileOpen}
          toggleDrawer={toggleDrawer}
          isAuthenticated={authenticated}
          handleLogout={handleLogout}
        />
      ) : (
        <MenuNavLeft user={user} unreadCount={unreadCount} />
      )}

      {/* Основной контент */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        <Box sx={{ flex: 1, p: 2 }}>
          <Outlet
            context={{
              authenticated,
              user,
              handleLogout,
              updateUser,
              requests,
              setRequests,
              unreadRequests,
              setUnreadRequests,
              notifications, 
              markNotificationAsRead
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PrivateLayout;
