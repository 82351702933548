// AccountStatusIcon.jsx
import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AccountStatusIcon = ({ status, validationErrors }) => {
  if (status === 'Active') {
    return <CheckCircleIcon color="success" />;
  }
  return (
    <Tooltip title={
      `SMTP: ${validationErrors?.smtp || 'OK'}, IMAP: ${validationErrors?.imap || 'OK'}`
    }>
      <ErrorIcon color="error" />
    </Tooltip>
  );
};

export default AccountStatusIcon;
