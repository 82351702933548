import React from 'react';
import {
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Description as DescriptionIcon,
  TableChart as TableChartIcon,
  Slideshow as SlideshowIcon,
  MusicNote as MusicNoteIcon,
  Movie as MovieIcon,
  InsertDriveFile as InsertDriveFileIcon,
  FolderZip as FolderZipIcon
} from '@mui/icons-material';

// ???????????? MIME-???? ? ??????????? ????????
export const iconMap = {
  // PDF
  'application/pdf': <PictureAsPdfIcon />,

  // Word
  'application/msword': <DescriptionIcon />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <DescriptionIcon />,

  // Excel
  'application/vnd.ms-excel': <TableChartIcon />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <TableChartIcon />,

  // PowerPoint
  'application/vnd.ms-powerpoint': <SlideshowIcon />,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': <SlideshowIcon />,

  // ?????? (ZIP, RAR, 7z)
  'application/zip': <FolderZipIcon />,
  'application/x-rar-compressed': <FolderZipIcon />,
  'application/x-7z-compressed': <FolderZipIcon />,

  // ????????
  'image/jpeg': <ImageIcon />,
  'image/png': <ImageIcon />,
  'image/gif': <ImageIcon />,
  'image/webp': <ImageIcon />,

  // ?????
  'audio/mpeg': <MusicNoteIcon />,
  'audio/ogg': <MusicNoteIcon />,
  'audio/wav': <MusicNoteIcon />,

  // ?????
  'video/mp4': <MovieIcon />,
  'video/quicktime': <MovieIcon />,

  // ????????? ?????
  'text/plain': <DescriptionIcon />,
  'application/rtf': <DescriptionIcon />
};

// ???????-??????, ???? ?????? ????????? ?????? ????? ???????:
export function getIconByMimeType(mimeType) {
  return iconMap[mimeType] || <InsertDriveFileIcon />;
}
