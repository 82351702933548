//Messages.js

import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import MessagesTab from './MessagesTab';
import SmtpTab from './SmtpTab';
import 'react-quill/dist/quill.snow.css';

function Messages() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Tabs value={currentTab} onChange={handleChangeTab}>
        <Tab label="Messages" />
        <Tab label="SMTP" />
      </Tabs>

      {currentTab === 0 && <MessagesTab />}
      {currentTab === 1 && <SmtpTab />}
    </Box>
  );
}

export default Messages;