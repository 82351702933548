// src/components/Telegram.js

import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Snackbar,
  Alert
} from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useOutletContext, useNavigate } from 'react-router-dom';
import FileDownload from 'js-file-download';
import DeleteIcon from '@mui/icons-material/Delete';
import { loadStripe } from '@stripe/stripe-js';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet-async';

// Format date as dd.mm.yyyy
function formatDate(dateStr) {
  const date = new Date(dateStr);
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
}

// Format time as HH:mm (24h)
function formatTime(dateStr) {
  const date = new Date(dateStr);
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  return `${hh}:${min}`;
}

const hoursOptions = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));

// Stripe integration
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Categories for copying contacts (if needed)
const CATEGORIES = [
  'Construction', 'IT', 'Recruiting', 'Marketing', 'Finance', 'Healthcare',
  'Education', 'Manufacturing', 'Retail', 'Hospitality', 'Transportation',
  'Telecommunications', 'Energy', 'Consulting'
];

// Styled status button for payment status
const StatusButton = styled('span')(({ theme, status }) => {
  let bgColor;
  switch (status) {
    case 'Waiting': bgColor = '#f0ad4e'; break;
    case 'Checking': bgColor = '#5bc0de'; break;
    case 'Paid': bgColor = '#5cb85c'; break;
    case 'Failing': bgColor = '#d9534f'; break;
    default: bgColor = '#777';
  }
  return {
    backgroundColor: bgColor,
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '12px',
    fontSize: '0.8rem',
    display: 'inline-block'
  };
});

function Telegram() {
  const { authenticated, user, handleLogout } = useOutletContext();
  const isAdmin = user?.role === 'admin' || user?.isAdmin;
  const navigate = useNavigate();

  // Tabs: My Sessions, Client Sessions, Reports
  const [activeTab, setActiveTab] = useState(0);
  const [reportStartDate, setReportStartDate] = useState('');
  const [reportEndDate, setReportEndDate] = useState('');
  const [reportData, setReportData] = useState({
    totalSessions: 0,
    totalPaid: 0,
    totalUnpaid: 0,
  });
  const [filteredReports, setFilteredReports] = useState([]);
  const [reports, setReports] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [guestSessionToken, setGuestSessionToken] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  // For Telegram parsing, we use a single input field (channel URL)
  const [channelURL, setChannelURL] = useState('');
  // Force scrapingMethod and sourceType for Telegram
  const scrapingMethod = 'Telegram';
  const sourceType = 'Telegram';
  const sourceDetail = channelURL;

  const [isParsing, setIsParsing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [contactsFound, setContactsFound] = useState(0);
  const [parsingReport, setParsingReport] = useState(null);
  const [parsingPaused, setParsingPaused] = useState(false);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [parsingName, setParsingName] = useState('');

  const [renameDialog, setRenameDialog] = useState({ open: false, sessionId: null, currentName: '' });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, sessionId: null });

  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [copySession, setCopySession] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentSessionId, setCurrentSessionId] = useState(null);

  const [statusDialog, setStatusDialog] = useState({ open: false, sessionId: null, currentStatus: '', newStatus: '' });
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  // Format time left for guest session
  const formatTimeLeft = (sec) => {
    if (sec <= 0) return '00:00:00';
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = sec % 60;
    const hh = h < 10 ? '0' + h : h;
    const mm = m < 10 ? '0' + m : m;
    const ss = s < 10 ? '0' + s : s;
    return `${hh}:${mm}:${ss}`;
  };

  const [reportFilters, setReportFilters] = useState({
    parsingName: '',
    date: '',
    time: '',
    closedAt: '',
    paymentStatus: '',
    amount: ''
  });

  const [filters, setFilters] = useState({
    parsingName: '',
    parsingMethod: '',
    sourceType: '',
    sourceDetail: '',
    quantity: '',
    price: '',
    paymentStatus: '',
    date: '',
    time: '',
    expiresIn: ''
  });

  // Filter reports based on date range and other criteria
const filterReports = () => {
  const filtered = reports.filter(report => {
    const effectiveDate = report.closedAt ? new Date(report.closedAt) : new Date(report.createdAt);
    const start = reportStartDate ? new Date(reportStartDate) : null;
    const end = reportEndDate ? new Date(reportEndDate) : null;
    if (start && effectiveDate < start) return false;
    if (end && effectiveDate > end) return false;
    return true;
  });

  setFilteredReports(filtered);
  const totalSessions = filtered.length;
  const totalPaid = filtered
    .filter(r => r.paymentStatus === 'Paid')
    .reduce((sum, r) => sum + r.quantity / 100, 0);
  const totalUnpaid = filteredReportsFromReports
  .filter(r => r.paymentStatus === 'Waiting')
  .reduce((sum, r) => sum + r.quantity / 100, 0);
  setReportData({ totalSessions, totalPaid, totalUnpaid });
};

  const fetchReports = async () => {
    try {
      const config = { withCredentials: true };
      if (!authenticated) {
        config.headers = { 'X-Guest-Token': guestSessionToken };
      }
      const res = await axios.get('/api/telegram/reports', config);
      setReports(res.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  // Fetch Telegram parsing status
  const fetchParsingStatus = async () => {
    try {
      const res = await axios.get('/api/telegram/status');
      setProgress(res.data.progress);
      setTimeRemaining(res.data.timeRemaining);
      setContactsFound(res.data.contactsFound);
    } catch (error) {
      console.error('Error fetching parsing status:', error);
    }
  };

  // Polling for parsing status every 5 seconds when parsing is active
  useEffect(() => {
    let timer;
    if (isParsing) {
      fetchParsingStatus();
      timer = setInterval(() => {
        fetchParsingStatus();
      }, 5000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isParsing]);

  useEffect(() => {
    fetchReports();
  }, [authenticated, guestSessionToken]);

  useEffect(() => {
    filterReports();
  }, [reportStartDate, reportEndDate, sessions]);

  // Guest session handling
  useEffect(() => {
    if (!authenticated) {
      const savedToken = localStorage.getItem('guestSessionToken');
      const savedExpire = localStorage.getItem('guestSessionExpire');
      if (!savedToken || !savedExpire || Date.now() > Number(savedExpire)) {
        const newToken = `guest-${Math.random().toString(36).slice(2)}`;
        const newExpire = Date.now() + 24 * 60 * 60 * 1000;
        localStorage.setItem('guestSessionToken', newToken);
        localStorage.setItem('guestSessionExpire', newExpire.toString());
        setGuestSessionToken(newToken);
        setTimeLeft(Math.floor((newExpire - Date.now()) / 1000));
      } else {
        setGuestSessionToken(savedToken);
        setTimeLeft(Math.floor((Number(savedExpire) - Date.now()) / 1000));
      }
    }
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated && guestSessionToken) {
      const timerId = setInterval(() => {
        const expire = localStorage.getItem('guestSessionExpire');
        if (!expire) {
          clearInterval(timerId);
          return;
        }
        const diff = Number(expire) - Date.now();
        if (diff <= 0) {
          setTimeLeft(0);
          clearInterval(timerId);
        } else {
          setTimeLeft(Math.floor(diff / 1000));
        }
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [authenticated, guestSessionToken]);

  const fetchSessions = async () => {
    if (!authenticated && !guestSessionToken) return;
    setLoadingSessions(true);
    try {
      const config = { withCredentials: true };
      if (!authenticated) {
        config.headers = { 'X-Guest-Token': guestSessionToken };
      }
      const res = await axios.get('/api/telegram/sessions', config);
      setSessions(res.data);
    } catch (error) {
      console.error('Error fetching Telegram sessions:', error);
    } finally {
      setLoadingSessions(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, [guestSessionToken, authenticated]);

  // Handle Telegram channel URL input change
  const handleChannelChange = (e) => {
    setChannelURL(e.target.value);
  };

  const handleStart = async () => {
    if (!authenticated && !guestSessionToken) return;
    if (!channelURL) {
      showSnackbar('Please enter a Telegram channel URL', 'error');
      return;
    }
    setIsParsing(true);
    try {
      const payload = { channel: channelURL, sourceType, scrapingMethod };
      if (!authenticated) {
        payload.guestSessionToken = guestSessionToken;
      }
      const res = await axios.post('/api/telegram/start', payload);
      const { contactsFound, timeTaken, results } = res.data;
      setContactsFound(contactsFound);
      setParsingReport({ contactsFound, timeTaken, results });
      showSnackbar('Telegram parsing session started successfully', 'success');
    } catch (error) {
      console.error('Error during Telegram parsing:', error);
      showSnackbar('Telegram parsing failed', 'error');
    } finally {
      setIsParsing(false);
      fetchSessions();
    }
  };

  const handleStop = () => {
    setParsingPaused(true);
  };

  const handleContinue = () => {
    setParsingPaused(false);
  };

  const handleFinish = async () => {
    setIsParsing(false);
    if (!parsingReport) {
      setParsingReport({ contactsFound, timeTaken: 60 - timeRemaining, results: [] });
    }
    if (currentSessionId) {
      try {
        await axios.put(`/api/telegram/sessions/${currentSessionId}`, { closedAt: new Date() });
        fetchSessions();
      } catch (error) {
        console.error('Error updating closedAt:', error);
      }
    }
  };

  const handleSaveParsing = async () => {
    if (!parsingName) {
      showSnackbar('Please enter a parsing name', 'info');
      return;
    }
    try {
      const payload = {
        parsingName,
        scrapingMethod,
        sourceType,
        sourceDetail,
        quantity: parsingReport ? parsingReport.contactsFound : contactsFound,
        results: parsingReport ? parsingReport.results : []
      };
      if (!authenticated && guestSessionToken) {
        payload.guestSessionToken = guestSessionToken;
      }
      const response = await axios.post('/api/telegram/sessions', payload);
      setCurrentSessionId(response.data.session._id);
      showSnackbar('Telegram parsing session saved successfully', 'success');
      setOpenSaveModal(false);
      setParsingName('');
      fetchSessions();
    } catch (error) {
      console.error('Error saving Telegram parsing session:', error);
      showSnackbar('Failed to save Telegram parsing session', 'error');
    }
  };

  const openCopyDialog = (session) => {
    setCopyDialogOpen(true);
    setCopySession(session);
    const endpoint = session.scrapingMethod === 'Email'
      ? '/api/contacts/groups'
      : '/api/sms-contacts/groups';
    axios.get(endpoint)
      .then(res => { setGroupOptions(res.data); })
      .catch(err => {
        console.error('Error fetching groups:', err);
        showSnackbar('Failed to load groups', 'error');
      });
  };

  const closeCopyDialog = () => {
    setCopyDialogOpen(false);
    setCopySession(null);
    setSelectedGroup('');
    setIsNewGroup(false);
    setNewGroupName('');
    setSelectedCategory('');
  };

  const handleCopy = async () => {
    if (!copySession) return;
    if (isNewGroup) {
      if (!newGroupName || !selectedCategory) {
        showSnackbar('Please enter new group name and select a category', 'info');
        return;
      }
      try {
        await axios.post('/api/contacts/groups', { name: newGroupName, category: selectedCategory });
      } catch (error) {
        console.error('Error creating group:', error);
        showSnackbar('Failed to create new group', 'error');
        return;
      }
    } else {
      if (!selectedGroup) {
        showSnackbar('Please select a group', 'info');
        return;
      }
    }
    const groupNameToUse = isNewGroup ? newGroupName : selectedGroup;
    const isEmail = copySession.scrapingMethod === 'Email';
    const payload = isEmail
      ? { groupName: groupNameToUse, emails: copySession.results }
      : { groupName: groupNameToUse, phones: copySession.results };
    const endpoint = isEmail
      ? '/api/contacts/groups/add-emails'
      : '/api/sms-contacts/groups/add-phones';
    try {
      await axios.post(endpoint, payload);
      showSnackbar('Contacts copied successfully', 'success');
      closeCopyDialog();
    } catch (error) {
      console.error('Error copying contacts:', error);
      showSnackbar('Failed to copy contacts', 'error');
    }
  };

  const openRenameDialogHandler = (sessionId, currentName) => {
    setRenameDialog({ open: true, sessionId, currentName });
  };

  const closeRenameDialog = () => {
    setRenameDialog({ open: false, sessionId: null, currentName: '' });
  };

  const handleRename = async () => {
    try {
      await axios.put(
        `/api/telegram/sessions/${renameDialog.sessionId}`,
        { parsingName: renameDialog.currentName },
        !authenticated ? { headers: { 'X-Guest-Token': guestSessionToken } } : {}
      );
      closeRenameDialog();
      fetchSessions();
      showSnackbar('Successfully renamed session', 'success');
    } catch (error) {
      console.error('Error renaming session:', error);
      showSnackbar('Failed to rename session', 'error');
    }
  };

  const openDeleteDialog = (sessionId) => {
    setDeleteDialog({ open: true, sessionId });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, sessionId: null });
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/telegram/sessions/${deleteDialog.sessionId}`,
        !authenticated ? { headers: { 'X-Guest-Token': guestSessionToken } } : {}
      );
      closeDeleteDialog();
      fetchSessions();
      showSnackbar('Successfully deleted session', 'success');
    } catch (error) {
      console.error('Error deleting session:', error);
      showSnackbar('Failed to delete session', 'error');
    }
  };

  const handleExport = async (sessionId) => {
    try {
      const config = { responseType: 'blob' };
      if (!authenticated) {
        config.headers = { 'X-Guest-Token': guestSessionToken };
      }
      const response = await axios.get(`/api/telegram/sessions/${sessionId}/export`, config);
      FileDownload(response.data, `telegram_session_${sessionId}.xlsx`);
      showSnackbar('Export success', 'success');
    } catch (error) {
      console.error('Export error:', error);
      showSnackbar('Export failed', 'error');
    }
  };

  const handleAdminStatusSelect = (session, newStatus) => {
    setStatusDialog({ open: true, sessionId: session._id, currentStatus: session.paymentStatus, newStatus });
  };

  const confirmStatusChange = async () => {
    try {
      await axios.put(`/api/telegram/sessions/${statusDialog.sessionId}/status`, { paymentStatus: statusDialog.newStatus });
      setStatusDialog({ open: false, sessionId: null, currentStatus: '', newStatus: '' });
      fetchSessions();
      fetchReports();
      showSnackbar('Payment status updated successfully', 'success');
    } catch (error) {
      showSnackbar('Failed to update payment status', 'error');
      console.error(error);
    }
  };

  const unpaidSessions = sessions.filter(s => s.paymentStatus !== 'Paid');
  let baseTotal = unpaidSessions.reduce((sum, s) => sum + s.quantity / 100, 0);
  const tax = baseTotal * 0.22;
  const totalWithTax = baseTotal + tax;
  const totalUnpaidEuro = baseTotal.toFixed(2);

  const handlePayAll = async () => {
    if (baseTotal < 0.5) {
      showSnackbar('Total is below 0.50€, nothing to pay or too small', 'info');
      return;
    }
    try {
      const amountInCents = Math.round(baseTotal * 100);
      const ids = unpaidSessions.map(s => s._id);
      const idsString = ids.join(',');
      const response = await axios.post('/api/payment/checkout-session', {
        amount: amountInCents,
        currency: 'eur',
        sessionIds: idsString
      });
      const { id } = response.data;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      if (error) {
        console.error("Stripe Checkout error:", error);
      }
    } catch (err) {
      console.error("Payment error (PayAll):", err);
      showSnackbar('Payment failed or was cancelled', 'error');
    }
  };

  const handleExportReport = async () => {
    try {
      const response = await axios.post('/api/telegram/export-report', {
        startDate: reportStartDate,
        endDate: reportEndDate,
        reports: filteredReports,
      }, { responseType: 'blob' });
      FileDownload(response.data, `telegram_report_${reportStartDate}_${reportEndDate}.xlsx`);
      showSnackbar('Export success', 'success');
    } catch (error) {
      console.error('Export error:', error);
      showSnackbar('Export failed', 'error');
    }
  };

  const applyFilters = (session) => {
    if (filters.parsingName && !session.parsingName?.toLowerCase().includes(filters.parsingName.toLowerCase())) return false;
    if (filters.parsingMethod && !session.scrapingMethod?.toLowerCase().includes(filters.parsingMethod.toLowerCase())) return false;
    if (filters.sourceType && !session.sourceType?.toLowerCase().includes(filters.sourceType.toLowerCase())) return false;
    if (filters.sourceDetail && !session.sourceDetail?.toLowerCase().includes(filters.sourceDetail.toLowerCase())) return false;
    if (filters.quantity && !session.quantity?.toString().includes(filters.quantity)) return false;
    if (reportFilters.dateRange && reportFilters.dateRange[0] && reportFilters.dateRange[1]) {
      const [start, end] = reportFilters.dateRange;
      const created = new Date(session.createdAt);
      if (created < start || created > end) return false;
    }
    if (reportFilters.time) {
      const sessionHour = new Date(session.createdAt).getHours();
      const filterHour = parseInt(reportFilters.time, 10);
      if (sessionHour !== filterHour) return false;
    }
    const dateFormatted = session.createdAt ? formatDate(session.createdAt) : '';
    if (filters.date && !dateFormatted.toLowerCase().includes(filters.date.toLowerCase())) return false;
    const timeFormatted = session.createdAt ? String(new Date(session.createdAt).getHours()).padStart(2, '0') : '';
    if (reportFilters.time && timeFormatted !== reportFilters.time) return false;
    if (!isAdmin) {
      const priceEuro = (session.quantity / 100).toFixed(2);
      if (filters.price && !priceEuro.includes(filters.price)) return false;
      if (filters.paymentStatus && !session.paymentStatus?.toLowerCase().includes(filters.paymentStatus.toLowerCase())) return false;
    }
    if (filters.expiresIn) {
      const expiresIn = session.expireAt ? Math.max(0, Math.floor((new Date(session.expireAt) - new Date()) / 60000)) : '';
      if (!expiresIn.toString().includes(filters.expiresIn)) return false;
    }
    return true;
  };

  const applyReportFilters = (session) => {
    if (reportFilters.parsingName && !session.parsingName?.toLowerCase().includes(reportFilters.parsingName.toLowerCase())) return false;
    if (reportFilters.dateRange && reportFilters.dateRange[0] && reportFilters.dateRange[1]) {
      const [start, end] = reportFilters.dateRange;
      const created = new Date(session.createdAt);
      if (created < start || created > end) return false;
    }
    const dateFormatted = session.createdAt ? formatDate(session.createdAt) : '';
    if (reportFilters.date && !dateFormatted.toLowerCase().includes(reportFilters.date.toLowerCase())) return false;
    if (reportFilters.time) {
      const sessionHour = new Date(session.createdAt).getHours();
      const filterHour = parseInt(reportFilters.time, 10);
      if (sessionHour !== filterHour) return false;
    }
    const timeFormatted = session.createdAt ? String(new Date(session.createdAt).getHours()).padStart(2, '0') : '';
    if (reportFilters.time && timeFormatted !== reportFilters.time) return false;
    const closedAtStr = session.closedAt ? new Date(session.closedAt).toLocaleString().toLowerCase() : '';
    if (reportFilters.closedAt && !closedAtStr.includes(reportFilters.closedAt.toLowerCase())) return false;
    if (reportFilters.paymentStatus && !session.paymentStatus?.toLowerCase().includes(reportFilters.paymentStatus.toLowerCase())) return false;
    const amountStr = (session.quantity / 100).toFixed(2);
    if (reportFilters.amount && !amountStr.includes(reportFilters.amount)) return false;
    return true;
  };

  const filteredReportsFromReports = reports.filter(report => applyReportFilters(report));

  const getUniqueValues = (sessions, key) => {
    const values = sessions.map(session => session[key]).filter(Boolean);
    return [...new Set(values)];
  };

const mySessions = isAdmin
  ? sessions.filter(s => s.userId && s.userId.toString().toLowerCase() === 'admin')
  : sessions.filter(s => s.userId && s.userId.toString() === user.id.toString());
  
const clientSessions = sessions.filter(s => {
  // для админа оставляем только сессии не от "admin"
  return s.userId && s.userId.toString().toLowerCase() !== 'admin';
});

const filteredSessions = activeTab === 0 
  ? mySessions 
  : (isAdmin ? clientSessions : mySessions);
  
  function formatHour(date) {
  return String(date.getHours()).padStart(2, '0');
}

const totalSessions = filteredReportsFromReports.length;
const totalPaid = filteredReportsFromReports
  .filter(r => r.paymentStatus === 'Paid')
  .reduce((sum, r) => sum + r.quantity / 100, 0);
const totalUnpaid = filteredReportsFromReports
  .filter(r => r.paymentStatus === 'Waiting')
  .reduce((sum, r) => sum + r.quantity / 100, 0);

  return (
    <>
      <Helmet>
        <title>Telegram Parsing - Mailsball</title>
        <meta
          name="description"
          content="Detailed view of Telegram parsing sessions and data extraction for marketing campaigns by Mailsball. Monitor parsing status, export results, and manage your sessions."
        />
        <meta name="keywords" content="telegram parsing, data extraction, telegram, marketing, Mailsball" />
        <link rel="canonical" href="https://mailsball.com/telegram" />
        <meta property="og:title" content="Telegram Parsing - Mailsball" />
        <meta property="og:description" content="Detailed view of Telegram parsing sessions and data extraction for marketing campaigns by Mailsball." />
        <meta property="og:url" content="https://mailsball.com/telegram" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://mailsball.com/images/logo-black-1.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Telegram Parsing - Mailsball" />
        <meta name="twitter:description" content="Detailed view of Telegram parsing sessions and data extraction for marketing campaigns by Mailsball." />
        <meta name="twitter:image" content="https://mailsball.com/images/Loogoo.png" />
      </Helmet>

      <Box sx={{ p: 2, position: 'relative' }}>
        {!authenticated && !isAdmin && timeLeft !== null && timeLeft > 0 && (
          <Box sx={{ position: 'absolute', top: 10, right: 10, color: 'red', fontWeight: 'bold' }}>
            <Tooltip title="Your guest session expires soon. Save your sessions by registering.">
              <span>Time left: {formatTimeLeft(timeLeft)}</span>
            </Tooltip>
          </Box>
        )}
{!isAdmin && (
        <Box sx={{ textAlign: 'center', mb: 1 }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' }
            }}
          >
            Telegram Parsing
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              color: 'gray',
              mx: 'auto',
              maxWidth: 600,
              fontSize: { xs: '1rem', sm: '1.125rem', md: '1.125rem' },
              lineHeight: 1.2
            }}
          >
            Extract up-to-date channel and user data directly from Telegram channels for efficient marketing campaigns.
          </Typography>
        </Box>
)}
        {authenticated && (
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
            <Tab label="My Sessions" />
            {isAdmin && <Tab label="Client Sessions" />}
            <Tab label="Reports" />
          </Tabs>
        )}

        {/* New Telegram Parsing Form */}
        {(!authenticated || activeTab === 0) && (
          <>
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>New Telegram Parsing</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Single input for Telegram channel URL */}
                <TextField
                  label="Enter Telegram Channel URL"
                  value={channelURL}
                  onChange={handleChannelChange}
                  fullWidth
                />

                <Box>
                  {isParsing ? (
                    <Box>
                      <Typography>Parsing in progress...</Typography>
                      <Typography>Progress: {progress}%</Typography>
                      <Typography>Time Remaining: {timeRemaining > 0 ? timeRemaining : 0} sec</Typography>
                      <Typography>Contacts Found: {contactsFound}</Typography>
                    </Box>
                  ) : (
                    <Typography></Typography>
                  )}
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="contained" onClick={handleStart} disabled={isParsing}>
                    Start
                  </Button>
                  <Button variant="outlined" onClick={handleStop} disabled={!isParsing || parsingPaused}>
                    Stop
                  </Button>
                  <Button variant="outlined" onClick={handleContinue} disabled={!isParsing || !parsingPaused}>
                    Continue
                  </Button>
                  <Button variant="contained" color="success" onClick={handleFinish} disabled={!isParsing}>
                    Finish
                  </Button>
                </Box>

                {parsingReport && (
                  <Paper sx={{ p: 2, mt: 2, backgroundColor: '#e0f7fa' }}>
                    <Typography variant="h6">Parsing Report</Typography>
                    <Typography>Contacts Found: {parsingReport.contactsFound}</Typography>
                    <Typography>Time Taken: {parsingReport.timeTaken} sec</Typography>
                    <Button variant="contained" onClick={() => setOpenSaveModal(true)}>
                      Save Parsing
                    </Button>
                  </Paper>
                )}
              </Box>
            </Paper>

            {/* My Sessions Table */}
            <Paper sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Telegram Parsing Sessions</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Parsing Name</TableCell>
                      <TableCell>Channel Name</TableCell>
                      <TableCell>Admins</TableCell>
                      <TableCell>Subscribers</TableCell>
                      <TableCell>Views</TableCell>
                      {!isAdmin && <TableCell>Price</TableCell>}
                      {!isAdmin && <TableCell>Payment Status</TableCell>}
                      <TableCell>Actions</TableCell>
                      <TableCell><strong>Date</strong></TableCell>
                      <TableCell><strong>Time</strong></TableCell>
                      <TableCell>Expires In</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          options={getUniqueValues(isAdmin ? mySessions : sessions, 'parsingName')}
                          value={filters.parsingName}
                          onChange={(event, newValue) => setFilters(prev => ({ ...prev, parsingName: newValue || '' }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Filter" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          options={getUniqueValues(isAdmin ? mySessions : sessions, 'channelInfo.name')}
                          value={filters.parsingMethod}
                          onChange={(event, newValue) => setFilters(prev => ({ ...prev, parsingMethod: newValue || '' }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Filter" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          options={getUniqueValues(isAdmin ? mySessions : sessions, 'channelInfo.adminsCount')}
                          value={filters.sourceType}
                          onChange={(event, newValue) => setFilters(prev => ({ ...prev, sourceType: newValue || '' }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Filter" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          options={getUniqueValues(isAdmin ? mySessions : sessions, 'channelInfo.subscribersCount')}
                          value={filters.sourceDetail}
                          onChange={(event, newValue) => setFilters(prev => ({ ...prev, sourceDetail: newValue || '' }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Filter" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          options={getUniqueValues(isAdmin ? mySessions : sessions, 'channelInfo.views')}
                          value={filters.quantity}
                          onChange={(event, newValue) => setFilters(prev => ({ ...prev, quantity: newValue || '' }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Filter" />
                          )}
                        />
                      </TableCell>
                      {!isAdmin && (
                        <TableCell>
                          <Autocomplete
                            freeSolo
                            options={getUniqueValues(isAdmin ? mySessions : sessions, 'price')}
                            value={filters.price}
                            onChange={(event, newValue) => setFilters(prev => ({ ...prev, price: newValue || '' }))}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" placeholder="Filter" />
                            )}
                          />
                        </TableCell>
                      )}
                      {!isAdmin && (
                        <TableCell>
                          <Autocomplete
                            freeSolo
                            options={getUniqueValues(isAdmin ? mySessions : sessions, 'paymentStatus')}
                            value={filters.paymentStatus}
                            onChange={(event, newValue) => setFilters(prev => ({ ...prev, paymentStatus: newValue || '' }))}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" placeholder="Filter" />
                            )}
                          />
                        </TableCell>
                      )}
                      <TableCell>{/* No filter for Actions */}</TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="From"
                            endText="To"
                            value={reportFilters.dateRange || [null, null]}
                            onChange={(newValue) => setReportFilters(prev => ({ ...prev, dateRange: newValue }))}
                            renderInput={(startProps, endProps) => (
                              <>
                                <TextField {...startProps} variant="standard" />
                                <Box sx={{ mx: 2 }}>–</Box>
                                <TextField {...endProps} variant="standard" />
                              </>
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Autocomplete
                            options={hoursOptions}
                            value={reportFilters.time}
                            onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, time: newValue || '' }))}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Filter"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {reportFilters.time && (
                                        <IconButton
                                          size="small"
                                          onClick={() => setReportFilters(prev => ({ ...prev, time: '' }))}
                                        >
                                          <ClearIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>{}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSessions
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map(session => {
                        const expiresIn = session.expireAt ? Math.max(0, Math.floor((new Date(session.expireAt) - new Date()) / 60000)) : '-';
                        const priceEuro = (session.quantity / 100).toFixed(2);
                        // Define export-related variables for each session row
                        let isExportDisabled = false;
                        let exportButtonVariant = 'outlined';
                        let exportButtonColor = 'inherit';
                        if (!isAdmin) {
                          isExportDisabled = session.paymentStatus !== 'Paid';
                          exportButtonVariant = !isExportDisabled ? 'contained' : 'outlined';
                          exportButtonColor = !isExportDisabled ? 'success' : 'inherit';
                        }
                        return (
                          <TableRow key={session._id} hover sx={{ cursor: 'pointer' }} onClick={() => navigate(`/telegram/${session._id}`)}>
                            <TableCell>{session.parsingName}</TableCell>
                            <TableCell>{session.channelInfo?.name || '-'}</TableCell>
                            <TableCell>{session.channelInfo?.adminsCount || '-'}</TableCell>
                            <TableCell>{session.channelInfo?.subscribersCount || '-'}</TableCell>
                            <TableCell>{session.channelInfo?.views || '-'}</TableCell>
                            {!isAdmin && <TableCell>{"\u20AC"}{priceEuro}</TableCell>}
                            {!isAdmin && (
                              <TableCell>
                                <StatusButton status={session.paymentStatus}>
                                  {session.paymentStatus}
                                </StatusButton>
                              </TableCell>
                            )}
                            <TableCell>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                {authenticated && (
                                  <Button variant="outlined" size="small" onClick={(ev) => { ev.stopPropagation(); openCopyDialog(session); }}>
                                    Copy
                                  </Button>
                                )}
                                <Tooltip title={isExportDisabled ? 'Button will become active after payment' : ''}>
                                  <span>
                                    <Button
                                      variant={exportButtonVariant}
                                      color={exportButtonColor}
                                      size="small"
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        if (!isExportDisabled) {
                                          handleExport(session._id);
                                        }
                                      }}
                                      disabled={isExportDisabled}
                                    >
                                      Export
                                    </Button>
                                  </span>
                                </Tooltip>
                                <Button variant="outlined" size="small" onClick={(ev) => { ev.stopPropagation(); openRenameDialogHandler(session._id, session.parsingName); }}>
                                  Rename
                                </Button>
                                <Button variant="outlined" size="small" color="error" onClick={(ev) => { ev.stopPropagation(); openDeleteDialog(session._id); }}>
                                  Delete
                                </Button>
                              </Box>
                            </TableCell>
                            <TableCell>{session.createdAt ? formatDate(session.createdAt) : ''}</TableCell>
                            <TableCell>{session.createdAt ? formatTime(session.createdAt) : ''}</TableCell>
                            <TableCell>{session.expireAt ? Math.max(0, Math.floor((new Date(session.expireAt) - new Date()) / 60000)) + ' min' : '-'}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center" mt={2}>
                <Pagination count={Math.ceil(filteredSessions.length / rowsPerPage)} page={page} onChange={(e, value) => setPage(value)} />
              </Box>
            </Paper>

            {/* (For guests) Pay multiple sessions */}
            {!isAdmin && (
              <Paper sx={{ p: 2, mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Pay multiple sessions:
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography>
                    Total price to pay: <strong>{"\u20AC"}{totalUnpaidEuro}</strong>
                  </Typography>
                  <Button variant="contained" color="primary" disabled={baseTotal < 0.5} onClick={handlePayAll}>
                    Pay
                  </Button>
                </Box>
                <Typography variant="caption" color="text.secondary">
                  *Price is 0.01{"\u20AC"}/contact, minimal 0.50{"\u20AC"} total.
                </Typography>
              </Paper>
            )}
          </>
        )}

        {/* Client Sessions */}
        {authenticated && isAdmin && activeTab === 1 && (
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Client Sessions</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Parsing Name</TableCell>
                    <TableCell>Channel Name</TableCell>
                    <TableCell>Admins</TableCell>
                    <TableCell>Subscribers</TableCell>
                    <TableCell>Views</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Actions</TableCell>
                    <TableCell><strong>Date</strong></TableCell>
                    <TableCell><strong>Time</strong></TableCell>
                    <TableCell>Expires In</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'parsingName')}
                        value={filters.parsingName}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, parsingName: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'channelInfo.name')}
                        value={filters.parsingMethod}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, parsingMethod: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'channelInfo.adminsCount')}
                        value={filters.sourceType}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, sourceType: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'channelInfo.subscribersCount')}
                        value={filters.sourceDetail}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, sourceDetail: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'channelInfo.views')}
                        value={filters.quantity}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, quantity: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'price')}
                        value={filters.price}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, price: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(clientSessions, 'paymentStatus')}
                        value={filters.paymentStatus}
                        onChange={(event, newValue) => setFilters(prev => ({ ...prev, paymentStatus: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>{/* No filter for Actions */}</TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          startText="From"
                          endText="To"
                          value={reportFilters.dateRange || [null, null]}
                          onChange={(newValue) => setReportFilters(prev => ({ ...prev, dateRange: newValue }))}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField {...startProps} variant="standard" />
                              <Box sx={{ mx: 2 }}>–</Box>
                              <TextField {...endProps} variant="standard" />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Autocomplete
                          options={hoursOptions}
                          value={reportFilters.time}
                          onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, time: newValue || '' }))}
                          clearOnEscape
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Filter"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {reportFilters.time && (
                                      <IconButton
                                        size="small"
                                        onClick={() => setReportFilters(prev => ({ ...prev, time: '' }))}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    )}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>{}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientSessions
                    .filter(s => applyFilters(s))
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map(session => {
                      const expiresIn = session.expireAt ? Math.max(0, Math.floor((new Date(session.expireAt) - new Date()) / 60000)) : '-';
                      const priceEuro = (session.quantity / 100).toFixed(2);
                      let isExportDisabled = false;
                      let exportButtonVariant = 'outlined';
                      let exportButtonColor = 'inherit';
                      if (!isAdmin) {
                        isExportDisabled = session.paymentStatus !== 'Paid';
                        exportButtonVariant = !isExportDisabled ? 'contained' : 'outlined';
                        exportButtonColor = !isExportDisabled ? 'success' : 'inherit';
                      }
                      return (
                        <TableRow key={session._id} hover sx={{ cursor: 'pointer' }} onClick={() => navigate(`/telegram/${session._id}`)}>
                          <TableCell>{session.parsingName}</TableCell>
                          <TableCell>{session.channelInfo?.name || '-'}</TableCell>
                          <TableCell>{session.channelInfo?.adminsCount || '-'}</TableCell>
                          <TableCell>{session.channelInfo?.subscribersCount || '-'}</TableCell>
                          <TableCell>{session.channelInfo?.views || '-'}</TableCell>
                          <TableCell>{"\u20AC"}{priceEuro}</TableCell>
                          <TableCell>
                            {isAdmin ? (
                              session.paymentStatus === 'Paid' ? (
                                <StatusButton status={session.paymentStatus}>
                                  {session.paymentStatus}
                                </StatusButton>
                              ) : (
                                <FormControl size="small">
                                  <Select
                                    value={session.paymentStatus}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      handleAdminStatusSelect(session, e.target.value);
                                    }}
                                  >
                                    <MenuItem value="Waiting">Waiting</MenuItem>
                                    <MenuItem value="Checking">Checking</MenuItem>
                                    <MenuItem value="Paid">Paid</MenuItem>
                                    <MenuItem value="Failing">Failing</MenuItem>
                                  </Select>
                                </FormControl>
                              )
                            ) : (
                              <StatusButton status={session.paymentStatus}>
                                {session.paymentStatus}
                              </StatusButton>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Button variant="outlined" size="small" onClick={(ev) => { ev.stopPropagation(); openCopyDialog(session); }}>
                                Copy
                              </Button>
                              <Tooltip title={isExportDisabled ? 'Button will become active after payment' : ''}>
                                <span>
                                  <Button
                                    variant={exportButtonVariant}
                                    color={exportButtonColor}
                                    size="small"
                                    onClick={(ev) => {
                                      ev.stopPropagation();
                                      if (!isExportDisabled) {
                                        handleExport(session._id);
                                      }
                                    }}
                                    disabled={isExportDisabled}
                                  >
                                    Export
                                  </Button>
                                </span>
                              </Tooltip>
                              <Button variant="outlined" size="small" onClick={(ev) => { ev.stopPropagation(); openRenameDialogHandler(session._id, session.parsingName); }}>
                                Rename
                              </Button>
                              <Button variant="outlined" size="small" color="error" onClick={(ev) => { ev.stopPropagation(); openDeleteDialog(session._id); }}>
                                Delete
                              </Button>
                            </Box>
                          </TableCell>
                          <TableCell>{session.createdAt ? formatDate(session.createdAt) : ''}</TableCell>
                          <TableCell>{session.createdAt ? formatTime(session.createdAt) : ''}</TableCell>
                          <TableCell>{session.expireAt ? Math.max(0, Math.floor((new Date(session.expireAt) - new Date()) / 60000)) + ' min' : '-'}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination count={Math.ceil(filteredSessions.length / rowsPerPage)} page={page} onChange={(e, value) => setPage(value)} />
            </Box>
          </Paper>
        )}

        {/* Reports Tab */}
        {authenticated && (isAdmin ? activeTab === 2 : activeTab === 1) && (
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Reports</Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
              
              <Button variant="contained" sx={{ ml: 'auto' }} onClick={handleExportReport}>
                Export to Excel
              </Button>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography>
               Total Sessions: <strong>{totalSessions}</strong> | Total Paid: <strong>{"\u20AC"}{totalPaid.toFixed(2)}</strong> | Total Unpaid: <strong>{"\u20AC"}{totalUnpaid.toFixed(2)}</strong>
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Parsing Name</TableCell>
                    <TableCell><strong>Date</strong></TableCell>
                    <TableCell><strong>Time</strong></TableCell>
                    <TableCell>Closed At</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(filteredReportsFromReports, 'parsingName')}
                        value={reportFilters.parsingName}
                        onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, parsingName: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          startText="From"
                          endText="To"
                          value={reportFilters.dateRange || [null, null]}
                          onChange={(newValue) => setReportFilters(prev => ({ ...prev, dateRange: newValue }))}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField {...startProps} variant="standard" />
                              <Box sx={{ mx: 2 }}>–</Box>
                              <TextField {...endProps} variant="standard" />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Autocomplete
                          options={hoursOptions}
                          value={reportFilters.time}
                          onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, time: newValue || '' }))}
                          clearOnEscape
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Filter"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {reportFilters.time && (
                                      <IconButton
                                        size="small"
                                        onClick={() => setReportFilters(prev => ({ ...prev, time: '' }))}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    )}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(filteredReportsFromReports, 'closedAt')}
                        value={reportFilters.closedAt}
                        onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, closedAt: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(filteredReportsFromReports, 'paymentStatus')}
                        value={reportFilters.paymentStatus}
                        onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, paymentStatus: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        options={getUniqueValues(filteredReportsFromReports, 'amount')}
                        value={reportFilters.amount}
                        onChange={(event, newValue) => setReportFilters(prev => ({ ...prev, amount: newValue || '' }))}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Filter" />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredReportsFromReports
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map(report => (
                      <TableRow key={report._id}>
                        <TableCell>{report.parsingName}</TableCell>
                        <TableCell>{report.createdAt ? formatDate(report.createdAt) : '-'}</TableCell>
                        <TableCell>{report.createdAt ? formatTime(report.createdAt) : '-'}</TableCell>
                        <TableCell>{report.closedAt ? new Date(report.closedAt).toLocaleString() : '-'}</TableCell>
                        <TableCell>{report.paymentStatus}</TableCell>
                        <TableCell>{"\u20AC"}{(report.quantity / 100).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination count={Math.ceil(filteredReportsFromReports.length / rowsPerPage)} page={page} onChange={(e, value) => setPage(value)} />
            </Box>
          </Paper>
        )}

        {/* Save Parsing Dialog */}
        <Dialog open={openSaveModal} onClose={() => setOpenSaveModal(false)}>
          <DialogTitle>Save Telegram Parsing Session</DialogTitle>
          <DialogContent>
            <TextField autoFocus margin="dense" label="Parsing Name" fullWidth value={parsingName} onChange={(e) => setParsingName(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSaveModal(false)}>Cancel</Button>
            <Button onClick={handleSaveParsing} color="primary">Save</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={renameDialog.open} onClose={closeRenameDialog}>
          <DialogTitle>Rename Telegram Parsing Session</DialogTitle>
          <DialogContent>
            <TextField autoFocus margin="dense" label="New Parsing Name" fullWidth value={renameDialog.currentName} onChange={(e) => setRenameDialog({ ...renameDialog, currentName: e.target.value })} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeRenameDialog}>Cancel</Button>
            <Button onClick={handleRename} color="primary">Rename</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialog.open} onClose={closeDeleteDialog}>
          <DialogTitle>Delete Telegram Parsing Session</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this Telegram parsing session?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog}>Cancel</Button>
            <Button color="secondary" onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={copyDialogOpen} onClose={closeCopyDialog}>
          <DialogTitle>Copy Contacts to Group</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Select a group to copy {copySession?.scrapingMethod === 'Email' ? 'emails' : 'phones'} into.
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="group-select-label">Select Group</InputLabel>
              <Select
                labelId="group-select-label"
                value={selectedGroup}
                label="Select Group"
                onChange={(e) => {
                  if (e.target.value === '__new__') {
                    setIsNewGroup(true);
                    setSelectedGroup('');
                  } else {
                    setIsNewGroup(false);
                    setSelectedGroup(e.target.value);
                  }
                }}
              >
                {groupOptions.map((g) => (
                  <MenuItem key={g.groupName} value={g.groupName}>
                    {g.groupName}
                  </MenuItem>
                ))}
                <MenuItem value="__new__">Create new group</MenuItem>
              </Select>
            </FormControl>
            {isNewGroup && (
              <Box sx={{ mb: 2 }}>
                <TextField label="New Group Name" fullWidth value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} sx={{ mb: 2 }} />
                <FormControl fullWidth>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select labelId="category-select-label" value={selectedCategory} label="Category" onChange={(e) => setSelectedCategory(e.target.value)}>
                    {CATEGORIES.map(cat => (
                      <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeCopyDialog}>Cancel</Button>
            <Button onClick={handleCopy} color="primary">Copy</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={statusDialog.open} onClose={() => setStatusDialog({ ...statusDialog, open: false })}>
          <DialogTitle>Change Payment Status</DialogTitle>
          <DialogContent>
            <Typography>
              You are changing status from <strong>{statusDialog.currentStatus}</strong> to <strong>{statusDialog.newStatus}</strong>. Confirm?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setStatusDialog({ open: false, sessionId: null, currentStatus: '', newStatus: '' })}>
              Cancel
            </Button>
            <Button onClick={confirmStatusChange} color="primary">Change Status</Button>
          </DialogActions>
        </Dialog>

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingSessions}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default Telegram;
