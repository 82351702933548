// src/components/SMSPhones.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const SMSPhones = () => {
  const [phones, setPhones] = useState([]);
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [loading, setLoading] = useState(false);

  // ????????? ?????? ??????? ????????????
  const fetchPhones = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/sms-phones');
      setPhones(response.data);
    } catch (error) {
      console.error('Failed to fetch SMS phones:', error);
      alert('Failed to fetch SMS phones.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhones();
  }, []);

  // ???????? ???? ??????????? ??? ?????????? ??????
  const sendVerificationCode = async () => {
    if (!phone) {
      alert('Please enter a phone number.');
      return;
    }
    try {
      await axios.post('/api/sms-phones/send-verification-code', { phone });
      alert('Verification code sent. Please check your SMS.');
      setOpenVerifyDialog(true);
    } catch (error) {
      console.error('Failed to send verification code', error);
      alert('Failed to send verification code.');
    }
  };

  // ??????????? ??????
  const verifyPhone = async () => {
    try {
      await axios.post('/api/sms-phones/verify', { phone, verificationCode });
      fetchPhones();
      setOpenVerifyDialog(false);
      setPhone('');
      setVerificationCode('');
      alert('Phone verified successfully!');
    } catch (error) {
      console.error('Failed to verify phone', error);
      alert('Verification failed. Please check the code and try again.');
    }
  };

  // ???????? ??????
  const handleDeletePhone = async () => {
    try {
      await axios.delete(`/api/sms-phones/${selectedPhone._id}`);
      setPhones(prev => prev.filter(p => p._id !== selectedPhone._id));
      setOpenDeleteDialog(false);
      setSelectedPhone(null);
      alert('Phone deleted successfully.');
    } catch (error) {
      console.error('Failed to delete phone', error);
      alert('Failed to delete phone.');
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>SMS Phones</Typography>
      
      {/* ????? ??? ?????????? ?????? ?????? */}
      <Box mb={2}>
        <TextField
          fullWidth
          label="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={sendVerificationCode}
        fullWidth
      >
        Send Verification Code
      </Button>

      {/* ??????? ?? ??????? ??????? */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phones.length > 0 ? (
              phones.map(p => (
                <TableRow key={p._id}>
                  <TableCell>{p.phone}</TableCell>
                  <TableCell>{p.isVerified ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right">
                    {!p.isVerified && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setPhone(p.phone);
                          setOpenVerifyDialog(true);
                        }}
                        sx={{ mr: 1 }}
                      >
                        Verify
                      </Button>
                    )}
                    <Tooltip title="Delete Phone">
                      <IconButton
                        color="error"
                        onClick={() => {
                          setSelectedPhone(p);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No phones found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ?????? ??????????? ?????? */}
      <Dialog open={openVerifyDialog} onClose={() => setOpenVerifyDialog(false)}>
        <DialogTitle>Verify Phone</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the verification code sent to {phone}.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Verification Code"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerifyDialog(false)}>Cancel</Button>
          <Button onClick={verifyPhone} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      {/* ?????? ????????????? ???????? ?????? */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Phone</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete phone {selectedPhone?.phone}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeletePhone} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default SMSPhones;
