// src/components/Login.js
import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, IconButton, InputAdornment, Tooltip, Snackbar, Alert, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(''); // ????? ???? ?????? (????????, username)
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleTogglePassword = () => {
    setShowPassword(prev => !prev);
  };
  // ??????? ???????? ????? ???????????
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // ????????????, ??? API ??? ?????? ????????? login ? password
      const res = await axios.post('/api/auth/login', { login, password }, { withCredentials: true });
      // ???? ????? ????????, ????????? ????????? ??????????????
      if (res.data && res.data.authenticated) {
        onLoginSuccess();
        navigate('/');
        window.location.reload(); // ????????? ?? ??????? ????????
      } else {
        setErrorMessage("Invalid credentials.");
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please check your login and password and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '60px', // ?????? ?????? 60px
        px: 2, // ??????? ?? ?????
      }}
    >
      <Paper
        sx={{
          p: 4,
          width: { xs: '100%', sm: '400px' },
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            label="Login"
            fullWidth
            margin="normal"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </Box>
         <Tooltip title="The button will become active from 09.05.2025">
      {/* 
        ????? ??????????? ?????? ? <span>, ????? Tooltip ?? ????? ???????? ?? disabled-????????
      */}
      <span>
        <Button
          disabled
          onClick={() => navigate('/register')}
          fullWidth
          sx={{ mt: 1 }}
        >
          Register
        </Button>
      </span>
    </Tooltip>
    <Tooltip title="The button will become active from 09.05.2025">
      {/* 
        ????? ??????????? ?????? ? <span>, ????? Tooltip ?? ????? ???????? ?? disabled-????????
      */}
      <span>
        <Button
        disabled
          onClick={() => navigate('/forgot-password')}
          fullWidth
          sx={{ mt: 1 }}
        >
          Forgot Password
        </Button>
              </span>
    </Tooltip>
      </Paper>
    </Box>
  );
};

export default Login;
