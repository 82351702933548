import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const AttachmentList = ({ attachments }) => {
  return (
    <Box sx={{ mt: 2 }}>
      {attachments.map((att, idx) => {
        // ???? ?????????? ???????? ???????? ? att.content (????????, Buffer),
        // ???????? Blob ? ?????? URL ??? ??????????:
        const blob = new Blob([att.content], { type: att.contentType || 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        return (
          <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {att.filename}
            </Typography>
            <IconButton component="a" href={url} download={att.filename}>
              <DownloadIcon />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default AttachmentList;