// src/components/TelegramParsingDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar
} from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// Example using Recharts for statistics chart
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const TelegramParsingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  // Statistics object received from the API (or processed from session)
  const [stats, setStats] = useState({
    name: '',
    description: '',
    adminsCount: 0,
    subscribersCount: 0,
    views: 0,
    dailyVisits: 0,
    monthlyVisits: 0,
    reactions: 0,
    reposts: 0
  });

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await axios.get(`/api/telegram/sessions/${id}`);
        setSession(res.data);
        if (res.data.stats) {
          setStats(res.data.stats);
        }
      } catch (error) {
        console.error('Error fetching Telegram session:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSession();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }
  if (!session) {
    return <Typography variant="h6">Session not found.</Typography>;
  }

  // Example chart data; adjust according to real stats
  const chartData = [
    { name: 'Subscribers', value: stats.subscribersCount },
    { name: 'Views', value: stats.views },
    { name: 'Admins', value: stats.adminsCount }
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <>
      <Helmet>
        <title>Telegram Parsing Detail - YourAppName</title>
        <meta name="description" content="Details of parsed Telegram channel" />
      </Helmet>
      <Paper sx={{ p: 2, m: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Button variant="text" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Typography variant="h5" align="center">
            Telegram Parsing Detail: {stats.name || session.parsingName}
          </Typography>
          <Button variant="outlined" onClick={() => { /* Add export functionality if needed */ }}>
            Export
          </Button>
        </Box>

        {/* Channel/Chat Statistics Section */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6">Channel / Chat Information</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
            <Typography><strong>Name:</strong> {stats.name}</Typography>
            <Typography><strong>Description:</strong> {stats.description}</Typography>
            <Typography><strong>Admins Count:</strong> {stats.adminsCount}</Typography>
            <Typography><strong>Subscribers Count:</strong> {stats.subscribersCount}</Typography>
            <Typography><strong>Views:</strong> {stats.views}</Typography>
            <Typography><strong>Daily Visits:</strong> {stats.dailyVisits}</Typography>
            <Typography><strong>Monthly Visits:</strong> {stats.monthlyVisits}</Typography>
            <Typography><strong>Reactions:</strong> {stats.reactions}</Typography>
            <Typography><strong>Reposts:</strong> {stats.reposts}</Typography>
          </Box>
          <Box sx={{ height: 300, mt: 3 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie data={chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        {/* Users Information Table */}
        <Typography variant="h6" gutterBottom>
          Users Information
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Profile Photo</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Birth Date</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {session.results.map((user, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Avatar src={user.profilePhoto || ''} alt={user.username || 'User'} />
                  </TableCell>
                  <TableCell>{user.nickname}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.birthDate}</TableCell>
                  <TableCell>{user.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TelegramParsingDetail;
