import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, Box, Divider, CircularProgress } from '@mui/material';

const SMSDashboard = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  // ??????? ????????? ????????
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('/api/sms-campaigns');
      setCampaigns(response.data);
    } catch (error) {
      console.error("Failed to fetch SMS campaigns:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    // ???? ????????? ????????????? ?????????? ??????, ????? ????????:
    const interval = setInterval(fetchCampaigns, 10000); // ?????????? ?????? 10 ???.
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>SMS Campaign Dashboard</Typography>
      {campaigns.length === 0 ? (
        <Typography>No campaigns found.</Typography>
      ) : (
        campaigns.map(campaign => (
          <Box key={campaign._id} sx={{ border: '1px solid #ddd', borderRadius: 2, p: 2, mb: 2 }}>
            <Typography variant="h6">{campaign.campaignName || 'Unnamed Campaign'}</Typography>
            <Typography variant="body2" color="textSecondary">
              Sender: {campaign.senderPhone} | Status: {campaign.status} | Created: {new Date(campaign.createdAt).toLocaleString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body1">Message: {campaign.message}</Typography>
            <Typography variant="body2">
              Recipients Count: {campaign.recipients ? campaign.recipients.length : 0}
            </Typography>
          </Box>
        ))
      )}
    </Paper>
  );
};

export default SMSDashboard;
