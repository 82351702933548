// src/components/PaymentSuccess.js
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/parsing');
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [navigate]);

  const progressValue = ((5 - countdown) / 5) * 100;

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '80vh', 
      p: 4 
    }}>
      <CheckCircleOutlineIcon sx={{ fontSize: 100, color: 'green' }} />
      <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
        Payment Successful!
      </Typography>
      <Typography gutterBottom>Thank you for your payment!</Typography>
      {sessionId && (
        <Typography gutterBottom>
          Your session ID: <strong>{sessionId}</strong>
        </Typography>
      )}
      <Typography gutterBottom>
        Redirecting to <Button variant="text" onClick={() => navigate('/parsing')}>Parsing</Button> in {countdown} seconds...
      </Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress variant="determinate" value={progressValue} />
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
