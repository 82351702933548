// src/components/NavMenuItem.js
import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

const NavMenuItem = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMouseEnter = (event) => {
    if (item.submenu) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMouseLeave = () => {
    if (item.submenu) {
      setAnchorEl(null);
    }
  };

  if (item.submenu) {
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Button sx={{ color: '#fff', textTransform: 'none' }}>
          {item.text}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMouseLeave}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {item.submenu.map((subItem) => (
            <MenuItem key={subItem.text} component={Link} to={subItem.path}>
              {subItem.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  } else {
    return (
      <Button component={Link} to={item.path} sx={{ color: '#fff', textTransform: 'none' }}>
        {item.text}
      </Button>
    );
  }
};

export default NavMenuItem;
