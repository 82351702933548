// SidebarMenu.js
import React, { useState } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  useMediaQuery,
  Collapse,
  IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import RequestIcon from '@mui/icons-material/ListAlt';
import RequestIcon2 from '@mui/icons-material/ViewList';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ParsingIcon from '@mui/icons-material/MoveDown';

const menuIcons = {
  Home: <HomeIcon />,
  Requests: <RequestIcon />,
  Parsing: <ParsingIcon />,
  'Request Form': <RequestIcon2 />,
  SMS: <SmsIcon />, // ???????? ?? ?????????? ?????? ??? SMS, ???? ??????????
  Email: <EmailIcon />
};

function SidebarMenu({ menuItems, mobileOpen, toggleDrawer, isAuthenticated, handleLogout }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  
  // ????????? ??? ????????? ????????? ????
  const [openSubmenus, setOpenSubmenus] = useState({});

  const handleToggleSubmenu = (text, event) => {
    // ???? ?? ?????????, ?? ????????????? ???????? ????? ???? ??? ?????
    if (isMobile) {
      event.stopPropagation();
    }
    setOpenSubmenus((prev) => ({
      ...prev,
      [text]: !prev[text],
    }));
  };

  // ??????? ??? ????????: ?????????/????????? ?? ?????????
  const handleMouseEnter = (text) => {
    if (!isMobile) {
      setOpenSubmenus((prev) => ({
        ...prev,
        [text]: true,
      }));
    }
  };

  const handleMouseLeave = (text) => {
    if (!isMobile) {
      setOpenSubmenus((prev) => ({
        ...prev,
        [text]: false,
      }));
    }
  };

  // ?????? ?????? ???? ??? ????????? ?????????
  const bottomItems = isMobile
    ? [
        { text: 'Pricing', path: '/pricing', color: '#ffc800' },
        isAuthenticated
          ? { text: 'Logout', onClick: handleLogout, color: '#fff' }
          : { text: 'Login', path: '/login', color: '#fff' }
      ]
    : [];

  return (
    <Drawer
      anchor="right"
      open={mobileOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          width: '80vw',
          maxWidth: '250px',
          background: '#1f1e1f',
          color: 'white',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }
      }}
    >
      <Box
        sx={{ p: 2, overflowX: 'hidden' }}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {menuItems.map((item) => {
            if (item.submenu) {
              return (
                <Box 
                  key={item.text} 
                  onMouseEnter={() => handleMouseEnter(item.text)}
                  onMouseLeave={() => handleMouseLeave(item.text)}
                >
                  <ListItem
                    button
                    onClick={(e) => isMobile && handleToggleSubmenu(item.text, e)}
                    sx={{
                      borderRadius: '8px',
                      my: 1,
                      transition: 'background-color 0.3s',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.15)'
                      }
                    }}
                  >
                    {menuIcons[item.text] && (
                      <ListItemIcon sx={{ color: 'white' }}>
                        {menuIcons[item.text]}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        noWrap: true
                      }}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    />
                    {/* ????????? ?????? ??? ????????? ??? ???????????? */}
                    {isMobile && (
                      <IconButton
                        onClick={(e) => handleToggleSubmenu(item.text, e)}
                        sx={{ color: 'white' }}
                      >
                        {openSubmenus[item.text] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    )}
                  </ListItem>
                  <Collapse in={openSubmenus[item.text]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.submenu.map((subItem) => (
                        <ListItem
                          button
                          key={subItem.text}
                          component={Link}
                          to={subItem.path}
                          sx={{
                            pl: 4,
                            borderRadius: '8px',
                            my: 0.5,
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.15)'
                            }
                          }}
                        >
                          <ListItemText
                            primary={subItem.text}
                            primaryTypographyProps={{
                              fontWeight: 400,
                              fontSize: '0.9rem',
                              noWrap: true
                            }}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              );
            }
            
            const hasPath = !!item.path;
            return (
              <ListItem
                button
                key={item.text}
                component={hasPath ? Link : 'div'}
                to={hasPath ? item.path : undefined}
                onClick={item.onClick || null}
                sx={{
                  borderRadius: '8px',
                  my: 1,
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.15)'
                  }
                }}
              >
                {menuIcons[item.text] && (
                  <ListItemIcon sx={{ color: 'white' }}>
                    {menuIcons[item.text]}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '1rem',
                    noWrap: true
                  }}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      {isMobile && (
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid rgba(255,255,255,0.2)'
          }}
        >
          {bottomItems.map((item) => {
            if (item.onClick) {
              return (
                <Button
                  key={item.text}
                  onClick={item.onClick}
                  sx={{
                    color: item.color || '#fff',
                    width: '100%',
                    overflowX: 'hidden',
                    mb: 1,
                    textTransform: 'none',
                    border: '1px solid',
                    borderColor: item.color || '#fff',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  {item.text}
                </Button>
              );
            }
            return (
              <Button
                key={item.text}
                component={Link}
                to={item.path}
                sx={{
                  color: item.color || '#fff',
                  width: '100%',
                  mb: 1,
                  textTransform: 'none',
                  border: '1px solid',
                  borderColor: item.color || '#fff',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {item.text}
              </Button>
            );
          })}
        </Box>
      )}
    </Drawer>
  );
}

export default SidebarMenu;