//Dashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Paper,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { green, purple, red, orange } from '@mui/material/colors';

const Dashboard = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [loading, setLoading] = useState(true);

  // ????????? ?????? ? ?????????
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('/api/campaigns');
      const updatedCampaigns = response.data.map((campaign) => ({
        ...campaign,
        unsubscribed: campaign.unsubscribed || 0,
      }));
      setCampaigns(updatedCampaigns);
    } catch (error) {
      console.error('Failed to fetch campaigns:', error);
    }
  };

  // ????????? ?????? ? ???????? ????????
  const fetchSentEmails = async () => {
    try {
      const response = await axios.get('/api/send-email/sent-emails');
      setSentEmails(response.data);
    } catch (error) {
      console.error('Failed to fetch sent emails:', error);
    } finally {
      setLoading(false);
    }
  };

  // ????????? ?????? ??? ???????????? ??????????
  useEffect(() => {
    fetchCampaigns();
    fetchSentEmails();
    const interval = setInterval(fetchCampaigns, 5000); // ?????????? ?????? 5 ??????
    return () => clearInterval(interval);
  }, []);

  // ???????? ????
  const handleMenuOpen = (event, campaignId) => {
    setMenuAnchor(event.currentTarget);
    setSelectedCampaignId(campaignId);
  };

  // ???????? ????
  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedCampaignId(null);
  };

  // ???????? ????????
  const handleDelete = async () => {
    if (!selectedCampaignId) {
      console.error('No campaign ID selected for deletion');
      return;
    }

    try {
      await axios.delete(`/api/campaigns/${selectedCampaignId}`);
      setCampaigns((prev) => prev.filter((c) => c._id !== selectedCampaignId));
      handleMenuClose();
      alert('Campaign deleted successfully');
    } catch (error) {
      console.error('Failed to delete campaign:', error);
      alert('Failed to delete campaign. Please try again.');
    }
  };

  // ????????? ??????? ???????? ??? ????????
  const getCampaignStatus = (campaignId) => {
  const sentEmail = sentEmails.find((email) => email.campaignId === campaignId);
  if (!sentEmail) return 'pending';
  return sentEmail.status;
};

  // ?????? ??? ????????
  const getStatusIcon = (status) => {
    switch (status) {
      case 'sending':
        return <HourglassBottomIcon sx={{ color: orange[500], marginRight: '5px' }} />;
      case 'sent':
        return <CheckCircleIcon sx={{ color: green[500], marginRight: '5px' }} />;
      case 'failed':
        return <ErrorOutlineIcon sx={{ color: red[500], marginRight: '5px' }} />;
      default:
        return <RadioButtonCheckedIcon sx={{ color: purple[500], marginRight: '5px' }} />;
    }
  };

  // ??????? ?????????
  const getPercentage = (value, total) => {
    if (!total || total === 0) return '0%';
    const percent = (value / total) * 100;
    return `${Math.round(percent)}%`;
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Dashboard
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        campaigns.map((campaign) => {
          const {
            _id, // ID ????????
            campaignName,
            sentDate,
            recipients,
            opens,
            clicks,
            unsubscribed,
          } = campaign;

          const status = getCampaignStatus(_id);
          const statusIcon = getStatusIcon(status);

          return (
            <Box
              key={_id}
              sx={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '16px',
                marginBottom: '16px',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                {/* ?????????? ? ???????? */}
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    {campaignName || 'No Name'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#666' }}>
                    {status.charAt(0).toUpperCase() + status.slice(1)} - {sentDate || 'N/A'}
                  </Typography>
                </Box>

                {/* ?????? */}
                <Box display="flex" alignItems="center">
                  {statusIcon}
                  <Typography variant="subtitle2">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </Typography>
                </Box>
              </Box>

              {/* ??????????? */}
              <Divider sx={{ marginY: '8px' }} />

              {/* ?????????? */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginTop: '8px' }}
              >
                <Box textAlign="center" flex={1}>
                  <Typography variant="subtitle1">Recipients</Typography>
                  <Typography variant="body1">{recipients || 0}</Typography>
                  <Typography variant="body2" sx={{ color: '#888' }}>
                    100%
                  </Typography>
                </Box>

                <Box textAlign="center" flex={1}>
                  <Typography variant="subtitle1">Opens</Typography>
                  <Typography variant="body1">{opens || 0}</Typography>
                  <Typography variant="body2" sx={{ color: '#888' }}>
                    {getPercentage(opens, recipients)}
                  </Typography>
                </Box>

                <Box textAlign="center" flex={1}>
                  <Typography variant="subtitle1">Clicks</Typography>
                  <Typography variant="body1">{clicks || 0}</Typography>
                  <Typography variant="body2" sx={{ color: '#888' }}>
                    {getPercentage(clicks, recipients)}
                  </Typography>
                </Box>

                <Box textAlign="center" flex={1}>
                  <Typography variant="subtitle1">Unsubscribed</Typography>
                  <Typography variant="body1">{unsubscribed || 0}</Typography>
                  <Typography variant="body2" sx={{ color: '#888' }}>
                    {getPercentage(unsubscribed, recipients)}
                  </Typography>
                </Box>

                {/* ???? ???????? */}
                <Box>
                  <IconButton onClick={(e) => handleMenuOpen(e, _id)}>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })
      )}

      {/* ???? ???????? */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Paper>
  );
};

export default Dashboard;