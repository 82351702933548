// src/components/SMSCampaign.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

const SMSCampaign = () => {
  // ???? ?????
  const [campaignName, setCampaignName] = useState('');
  const [senderPhone, setSenderPhone] = useState('');
  // ????? ??????? ?????? ???????????: 'contacts' � ?? ???????, 'copy' � ?????? ????
  const [contactOption, setContactOption] = useState('contacts');
  // ???? ?????? ??????? "contacts", ?????? ??? ?????? ?????????
  const [selectedGroups, setSelectedGroups] = useState([]); // ?????? ???? ?????
  // ???? ?????? ??????? "copy", ?? ???????? ?????? ???????
  const [manualContacts, setManualContacts] = useState('');
  const [message, setMessage] = useState('');
  const [charCount, setCharCount] = useState(0);
  
  // ??? ???????? ????? ?????????
  const [availableGroups, setAvailableGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  // ?????????? ???????? ???????? ??? ?????????
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    setCharCount(value.length);
  };

  // ???? ?????? ??????? "contacts", ????????? ?????? ?????????
  useEffect(() => {
    if (contactOption === 'contacts') {
      axios.get('/api/sms-contacts/groups')
        .then(response => {
          setAvailableGroups(response.data);
        })
        .catch(error => {
          console.error('Error fetching SMS groups', error);
        });
    }
  }, [contactOption]);

  const handleSubmit = async () => {
    // ???????? ????????????? ?????
    if (!campaignName || !senderPhone || !message) {
      alert("Please fill in all required fields.");
      return;
    }
    
    // ?????????? ?????? ???????????
    let recipients = [];
    if (contactOption === 'contacts') {
      // ???? ??????? ??????, ???????? ?????? ???? ????? (backend ?????? ?????????? ???????? ?? ????????? ?????)
      recipients = selectedGroups;
    } else {
      recipients = manualContacts.split(',').map(s => s.trim()).filter(s => s !== '');
    }
    
    if (recipients.length === 0) {
      alert("No recipients specified.");
      return;
    }
    
    const payload = {
      campaignName,
      senderPhone,
      recipients, // ???? ???????? ??????, ??? ?????? ???? ?????; ???? "copy", ?? ?????? ???????
      message
    };

    setLoading(true);
    try {
      const response = await axios.post('/api/sms-campaigns', payload);
      alert('Campaign created successfully!');
      // ??????? ?????
      setCampaignName('');
      setSenderPhone('');
      setMessage('');
      setManualContacts('');
      setSelectedGroups([]);
    } catch (error) {
      console.error('Error creating campaign', error);
      alert('Failed to create campaign.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>SMS Campaign</Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField 
          label="Campaign Name" 
          value={campaignName} 
          onChange={(e) => setCampaignName(e.target.value)} 
          required 
        />
        <TextField 
          label="Sender Phone" 
          value={senderPhone} 
          onChange={(e) => setSenderPhone(e.target.value)} 
          required 
          helperText="Enter a verified sender phone number" 
        />
        <FormControl fullWidth>
          <InputLabel id="contact-option-label">Contact Option</InputLabel>
          <Select
            labelId="contact-option-label"
            value={contactOption}
            label="Contact Option"
            onChange={(e) => setContactOption(e.target.value)}
          >
            <MenuItem value="contacts">Contacts (Select from groups)</MenuItem>
            <MenuItem value="copy">Copy/Paste</MenuItem>
          </Select>
        </FormControl>
        {contactOption === 'contacts' ? (
          <FormControl fullWidth>
            <InputLabel id="groups-select-label">Select Groups</InputLabel>
            <Select
              labelId="groups-select-label"
              multiple
              value={selectedGroups}
              onChange={(e) => setSelectedGroups(e.target.value)}
              label="Select Groups"
              renderValue={(selected) =>
                selected.map(groupName => {
                  const group = availableGroups.find(g => g.groupName === groupName);
                  return group ? `${groupName} (${group.totalCount})` : groupName;
                }).join(', ')
              }
            >
              {availableGroups.map((group) => (
                <MenuItem key={group.groupName} value={group.groupName}>
                  {group.groupName} ({group.totalCount})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            label="Contacts (comma separated)"
            value={manualContacts}
            onChange={(e) => setManualContacts(e.target.value)}
            placeholder="Enter phone numbers separated by commas"
          />
        )}
        <TextField
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={handleMessageChange}
          required
          helperText={`Characters: ${charCount}`}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Campaign'}
        </Button>
      </Box>
    </Paper>
  );
};

export default SMSCampaign;
