import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold'
}));

function maskValue(value) {
  if (!value) return '';
  if (value.includes('@')) {
    const [left, domain] = value.split('@');
    const leftPart = left.slice(0, 2);
    const leftMask = '*'.repeat(Math.max(0, left.length - 2));
    const domainPart = domain.slice(0, 5);
    const domainMask = '*'.repeat(Math.max(0, domain.length - 5));
    return `${leftPart}${leftMask}@${domainPart}${domainMask}`;
  } else {
    if (value.length <= 4) return value;
    return `${value.slice(0, 4)}****${value.slice(-3)}`;
  }
}

const ParsingDetail = () => {
  const { authenticated, user } = useOutletContext();
  const isAdmin = (user?.role && user.role.toLowerCase() === 'admin') || user?.isAdmin;
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
  const fetchSession = async () => {
    try {
      const config = { withCredentials: true };
      // ???? ???????????? ?? ???????????????? (?????), ????????? ????????? ? guestSessionToken
      if (!authenticated) {
        const token = localStorage.getItem('guestSessionToken');
        if (token) {
          config.headers = { 'x-guest-token': token };
        }
      }
      const res = await axios.get(`/api/parsing/sessions/${id}`, config);
      setSession(res.data);
    } catch (error) {
      console.error('Error fetching session:', error);
      alert('Failed to fetch session details.');
    } finally {
      setLoading(false);
    }
  };
  fetchSession();
}, [id, authenticated]);
  
  const handleExport = async () => {
    try {
      const response = await axios.get(`/api/parsing/sessions/${id}/export`, {
        responseType: 'blob'
      });
      FileDownload(response.data, `parsing_session_${id}.xlsx`);
    } catch (error) {
      console.error('Export error:', error);
      alert('Export failed.');
    }
  };
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }
  
  if (!session) {
    return <Typography variant="h6">Session not found.</Typography>;
  }
  
  return (
    <Paper sx={{ p: 2, m: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button variant="text" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Typography variant="h5" align="center">
          Parsing Detail: {session.parsingName}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleExport}
          // ??????? ???????? ?????? ???? ???????????? ???????????????? (??????? ??????) ??? ?????? ????????
          disabled={!authenticated && session.paymentStatus !== 'Paid'}
        >
          Export
        </Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1"><strong>Scraping Method:</strong> {session.parsingMethod}</Typography>
        <Typography variant="body1"><strong>Source Type:</strong> {session.sourceType}</Typography>
        <Typography variant="body1"><strong>Source Detail:</strong> {session.sourceDetail}</Typography>
        <Typography variant="body1"><strong>Payment Status:</strong> {session.paymentStatus}</Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        Results ({session.results?.length || 0})
      </Typography>
      {session.results?.length === 0 ? (
        <Typography>No results found.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {session.results.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    {isAdmin || session.paymentStatus === 'Paid' ? item : maskValue(item)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default ParsingDetail;
