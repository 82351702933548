// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';

// Layouts
import HomeLayout from './layouts/HomeLayout';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';

// Other utilities
import CustomInstallBanner from './CustomInstallBanner';
import PrivateRoute from './components/PrivateRoute';

// Public pages
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import RequestForm from './components/RequestForm';
import PrivacyPolicy from './PrivacyPolicy';
import PaymentSuccess from './components/PaymentSuccess';
import Parsing from './components/Parsing';
import ParsingDetail from './components/ParsingDetail';
import Telegram from './components/Telegram';
import TelegramParsingDetail from './components/TelegramParsingDetail';

// Private pages
import Campaigns from './components/Campaigns';
import Requests from './components/Requests';
import ContactList from './components/ContactList';
import Dashboard from './components/Dashboard';
import Emails from './components/Emails';
import Domains from './components/Domains';
import Template from './components/Template';
import SMSDashboard from './components/SMSDashboard';
import SMSCampaign from './components/SMSCampaign';
import SMSContacts from './components/SMSContacts';
import SMSPhones from './components/SMSPhones';
import AdminDashboard from './components/AdminDashboard';
import Pricing from './components/Pricing';
import MyProfile from './components/MyProfile';
import Billing from './components/Billing';
import Notifications from './components/Notifications';
import { SnackbarProvider } from 'notistack';
import CustomNotificationContent from './components/CustomNotificationContent';
import NotificationPush from './components/NotificationPush';
import Messages from './components/Messages';
import CustomMailNotificationContent from './components/CustomMailNotificationContent';


function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    axios
      .get('/api/auth/check-session', { withCredentials: true })
      .then((res) => {
        if (res.data && res.data.authenticated) {
          setAuthenticated(true);
          setUser(res.data.user);
        } else {
          setAuthenticated(false);
        }
      })
      .catch((err) => {
        console.error('Failed to check session:', err);
        setAuthenticated(false);
      })
      .finally(() => {
        setLoadingAuth(false);
      });
  }, []);

  const handleLogout = () => {
    axios
      .post('/api/auth/logout', {}, { withCredentials: true })
      .then(() => {
        setAuthenticated(false);
        window.location.href = '/';
      })
      .catch((err) => {
        console.error('Logout error:', err);
      });
  };

  if (loadingAuth) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  

  return (

     <SnackbarProvider
       maxSnack={3}
  autoHideDuration={5000}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  Components={{
    customNotification: CustomNotificationContent }}
    >
    <Router>
         <CustomInstallBanner />
      <Routes>
        {/* HOME LAYOUT */}
        <Route
          path="/"
          element={
            <HomeLayout
              authenticated={authenticated}
              user={user}
              handleLogout={handleLogout}
            />
          }
        >
          <Route index element={<Home />} />
        </Route>

        {/* PUBLIC LAYOUT */}
        <Route
          element={
            <PublicLayout
              authenticated={authenticated}
              user={user}
              handleLogout={handleLogout}
            />
          }
        >
          <Route
            path="/login"
            element={<Login onLoginSuccess={() => setAuthenticated(true)} />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/success" element={<PaymentSuccess />} />
        </Route>

        {/* /pricing: ???? PrivateLayout, ???? PublicLayout */}
        <Route
          path="/pricing"
          element={
            authenticated ? (
              <PrivateLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            ) : (
              <PublicLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            )
          }
        >
          <Route path="/pricing" element={<Pricing />} />
        </Route>

        {/* /requestform: ???? PrivateLayout, ???? PublicLayout */}
        <Route
          path="/requestform"
          element={
            authenticated ? (
              <PrivateLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            ) : (
              <PublicLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            )
          }
        >
          <Route path="/requestform" element={<RequestForm />} />
        </Route>

        {/* /parsing/*: ???? PrivateLayout, ???? PublicLayout */}
        <Route
          path="/parsing/*"
          element={
            authenticated ? (
              <PrivateLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            ) : (
              <PublicLayout
                authenticated={authenticated}
                user={user}
                handleLogout={handleLogout}
              />
            )
          }
        >
          <Route index element={<Parsing />} />
          <Route path=":id" element={<ParsingDetail />} />
          <Route path="telegram" element={<Telegram />} />
          <Route path="telegram/:id" element={<TelegramParsingDetail />} />
        </Route>

        {/* PRIVATE LAYOUT (??? ?????????? ???????) */}
        <Route
          element={
            <PrivateLayout
              authenticated={authenticated}
              user={user}
              handleLogout={handleLogout}
            />
          }
        >
          <Route
            path="/campaigns"
            element={<PrivateRoute element={<Campaigns />} isAuthenticated={authenticated} />}
          />
          <Route
  path="/notifications"
  element={
    <PrivateRoute
      element={<Notifications />} // ?????? <Notifications />
      isAuthenticated={authenticated}
    />
  }
/>
          <Route
            path="/requests"
            element={<PrivateRoute element={<Requests />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/contacts"
            element={<PrivateRoute element={<ContactList />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/contacts/:groupName"
            element={<PrivateRoute element={<ContactList />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/templates"
            element={<PrivateRoute element={<Template />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/emails"
            element={<PrivateRoute element={<Emails />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/domains"
            element={<PrivateRoute element={<Domains />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/sms/dashboard"
            element={<PrivateRoute element={<SMSDashboard />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/sms/campaign"
            element={<PrivateRoute element={<SMSCampaign />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/sms/contacts"
            element={<PrivateRoute element={<SMSContacts />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/sms/contacts/:groupName"
            element={<PrivateRoute element={<SMSContacts />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/sms/phones"
            element={<PrivateRoute element={<SMSPhones />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/admin"
            element={<PrivateRoute element={<AdminDashboard />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/myprofile"
            element={<PrivateRoute element={<MyProfile />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/billing"
            element={<PrivateRoute element={<Billing />} isAuthenticated={authenticated} />}
          />
          <Route
            path="/messages"
            element={<PrivateRoute element={<Messages />} isAuthenticated={authenticated} />}
          />
        </Route>
      </Routes>
       {authenticated && user && (
  <>
    <NotificationPush userId={user._id} />
  </>
)}
           </Router>
     </SnackbarProvider>
  );
}

export default App;