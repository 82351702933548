// CustomNotificationContent.js
import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

const CustomNotificationContent = React.forwardRef((props, ref) => {
  const { message, closeSnackbar } = props;
  const notification = message; // ?????? ???????????

  const isUnread = !notification?.isRead;
  const loginColor = isUnread ? '#FF8C00' : '#12014B';
  const projectColor = isUnread ? '#8D00F2' : '#4B4B4B';

  return (
    <Box
      ref={ref}
      sx={{
        width: 340,
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 1.5,
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Avatar
          src={notification?.triggeredBy?.avatarUrl || '/images/user.png'}
          sx={{ width: 40, height: 40, mr: 1 }}
        >
          {notification?.triggeredBy?.firstName
            ? notification.triggeredBy.firstName.charAt(0)
            : ''}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2" sx={{ color: loginColor, fontWeight: 600 }}>
            {notification?.triggeredBy
              ? (
                  notification.triggeredBy.role === 'admin'
                    ? 'Admin'
                    : notification.triggeredBy.role === 'manager'
                      ? 'Manager'
                      : (
                          notification.triggeredBy.login ||
                          `${notification.triggeredBy.firstName || ''} ${notification.triggeredBy.lastName || ''}`.trim()
                        )
                )
              : 'System'}
            {' '}
            <Typography
              component="span"
              variant="body2"
              sx={{ color: projectColor, fontWeight: 600 }}
            >
              {notification?.projectName}
            </Typography>
          </Typography>
        </Box>
        {isUnread && <CircleIcon sx={{ fontSize: 10, color: '#FF8C00' }} />}
      </Box>

      <Typography variant="body2" sx={{ color: '#4A4A4A', mt: 1 }}>
        {notification?.changes || 'No changes text'}
      </Typography>
      <Typography variant="caption" sx={{ color: '#8F8F8F' }}>
        {new Date(notification?.createdAt || Date.now()).toLocaleString()}
      </Typography>

      <IconButton
        size="small"
        sx={{ position: 'absolute', top: 4, right: 4 }}
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
});

CustomNotificationContent.displayName = 'CustomNotificationContent';
export default CustomNotificationContent;
