// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <GoogleReCaptchaProvider
  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
  scriptProps={{
    async: true,
    defer: true,
    appendTo: 'head',
    nonce: undefined,
  }}
>
  <App />
</GoogleReCaptchaProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();