//HomeLayout.js

import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarMenu from '../components/SidebarMenu';
import Footer from '../components/Footer';
import NavMenuItem from '../components/NavMenuItem';
import ProfileMenu from '../components/ProfileMenu';
import NotificationBell from '../components/NotificationBell';
import axios from 'axios';

function HomeLayout({ authenticated, user, handleLogout }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const guestMenu = [
    { text: 'Home', path: '/' },
    { text: 'Parsing', path: '/parsing' },
    { text: 'Request Form', path: '/requestform' }
  ];

  const authMenu = [
    { text: 'Home', path: '/' },
    { text: 'Request Form', path: '/requestform' }
  ];

  const currentMenuItems = authenticated ? authMenu : guestMenu;

  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  // ??????? ??? ????????? ???????????
  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/api/notifications');
      setNotifications(response.data);
    } catch (err) {
      console.error('Failed to fetch notifications:', err);
    }
  };

  // ???????? fetchNotifications ??? ???????????? ??????????
  useEffect(() => {
    if (authenticated) {
      fetchNotifications();
    }
  }, [authenticated]);

  // ??????? ??? ??????? ??????????? ??? ????????????
  const markNotificationAsRead = async (notificationId) => {
    try {
      const res = await axios.patch('/api/notifications/read', { notificationId });
      if (res.data.success) {
        setNotifications(prev =>
          prev.map(n =>
            n._id === notificationId ? { ...n, isRead: true } : n
          )
        );
      }
    } catch (err) {
      console.error('Failed to mark notification as read:', err);
    }
  };

  // ????????? ??? (????, ?????? ? ?.?.)
  const pricingButton = (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: '#ffc800',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(204, 133, 0, 0.08)'
        }
      }}
    >
      Pricing
    </Button>
  );

  const authButton = (
    <Button
      component={Link}
      to="/login"
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Login
    </Button>
  );

  return (
    <>
      <Box sx={{ overflowX: 'hidden' }}>
        <AppBar
          position="absolute"
          sx={{
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            boxShadow: 'none',
            zIndex: 10
          }}
        >
          <Toolbar>
            <Link
              to="/"
              style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              <img
                src="/images/logo.svg"
                alt="Mailsball Logo"
                style={{ height: '17px', marginRight: '10px', maxWidth: '100%' }}
              />
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            {!isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {currentMenuItems.map((item) => (
                  <NavMenuItem key={item.text} item={item} />
                ))}
                {pricingButton}
                {authenticated ? (
                  [
                    <NotificationBell
                      key="notification-bell"
                      notifications={notifications}
                      onMarkAsRead={markNotificationAsRead}
                      user={user}
                      handleLogout={handleLogout}
                    />,
                    <ProfileMenu key="profile-menu" user={user} handleLogout={handleLogout} />
                  ]
                ) : (
                  authButton
                )}
              </Box>
            )}
            {isMobile && (
              <IconButton sx={{ color: '#fff', ml: 1 }} onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <SidebarMenu
        menuItems={currentMenuItems}
        mobileOpen={mobileOpen}
        toggleDrawer={toggleDrawer}
        isAuthenticated={authenticated}
        handleLogout={handleLogout}
      />

      {/* ???????? notifications ? markNotificationAsRead ????? Outlet context */}
      <Outlet context={{ authenticated, user, handleLogout, notifications, markNotificationAsRead }} />
      <Footer authenticated={authenticated} />
    </>
  );
}

export default HomeLayout;
