//Billing.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
function Billing() {
  // ??????? ???????
  const [tabIndex, setTabIndex] = useState(0);

  // ????????? ??? ??????? (? ???????? ??????? � ??????? ? ???????)
  const [currentPlan, setCurrentPlan] = useState({
    name: 'Basic',
    limit: 100,
  });
  const [usedEmails, setUsedEmails] = useState(0); // ??? ?????????? ?????
  const [billingHistory, setBillingHistory] = useState([]);
  // ??? ???? "?????????" ? ?????? ?????? Billing history
  const [anchorEls, setAnchorEls] = useState({}); // { invoiceId: HTMLElement }

  const handleMenuOpen = (invoiceId, event) => {
    setAnchorEls((prev) => ({ ...prev, [invoiceId]: event.currentTarget }));
  };

  const handleMenuClose = (invoiceId) => {
    setAnchorEls((prev) => ({ ...prev, [invoiceId]: null }));
  };

  // ?????????? �Download invoice�
   const handleDownloadInvoice = (record) => {
    // ????????, ????????? ?????? ??? ?????????? ???????
    window.open(`/api/billing/download-invoice/${record.invoiceId}`, '_blank');
    handleMenuClose(record.invoiceId);
  };

  useEffect(() => {
    // ??????????? ?????? ???????? ?????
    axios
      .get('/api/billing/current-plan', { withCredentials: true })
      .then((res) => {
        const data = res.data;
        setCurrentPlan({ name: data.planName, limit: data.planLimit });
        setUsedEmails(data.usedEmails);
      })
      .catch((err) => console.error('Failed to fetch current plan', err));

    // ??????????? ??????? ????????
    axios
      .get('/api/billing/history', { withCredentials: true })
      .then((res) => {
        setBillingHistory(res.data);
      })
      .catch((err) => console.error('Failed to fetch billing history', err));
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  // ?????? ?????? (????? ??????? ?? ???????)
  const plans = [
    { name: 'Basic', price: 0, limit: 100 },
    { name: 'Start', price: 30, limit: 30000 },
    { name: 'Business', price: 60, limit: 100000 },
    { name: 'Enterprise', price: 100, limit: 300000 },
  ];

  // ?????????? ??????? ????? (?????????)
  const handlePurchasePlan = async (plan) => {
    try {
      // ???????? ?????? ??? ???????? Stripe Checkout-??????
      const response = await axios.post('/api/billing/create-checkout-session', {
        planName: plan.name,
        price: plan.price,
        planLimit: plan.limit,
      });
      const sessionId = response.data.sessionId;

      // ????????? Stripe
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      if (error) {
        console.error('Stripe checkout error:', error);
      }
    } catch (error) {
      console.error('Purchase plan error:', error);
      alert('Failed to initiate payment. Please try again later.');
    }
  };

  // ????????????, ??????? ????????? ??? ?????????????
  const usagePercent = currentPlan.limit
    ? Math.round((usedEmails / currentPlan.limit) * 100)
    : 0;

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* ??????? */}
      <Tabs value={tabIndex} onChange={handleChangeTab}>
        <Tab label="Account usage" />
        <Tab label="Email Marketing Plan" />
        <Tab label="Billing history" />
      </Tabs>

      {/* ??????? "Account usage" */}
      {tabIndex === 0 && (
        <Paper sx={{ p: 3, mt: 2, boxShadow: 3 }}>
          <Typography variant="h6" gutterBottom>
            Your current plan: {currentPlan.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Emails used this month: {usedEmails} / {currentPlan.limit}
          </Typography>

          {/* ????? ????????? */}
          <Box sx={{ width: '100%', my: 2 }}>
            <LinearProgress variant="determinate" value={usagePercent} />
            <Typography variant="body2" sx={{ mt: 1 }}>
              {usagePercent}% of your monthly limit used
            </Typography>
          </Box>
        </Paper>
      )}

      {/* ??????? "Email Marketing Plan" */}
      {tabIndex === 1 && (
        <Paper sx={{ p: 3, mt: 2, boxShadow: 3 }}>
          <Typography variant="h5" gutterBottom>
            Email Marketing Plan
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'center',
              mt: 3,
            }}
          >
            {plans.map((plan) => (
              <Paper
                key={plan.name}
                sx={{
                  width: 220,
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  border:
                    currentPlan.name === plan.name
                      ? '2px solid #6c44cf'
                      : '1px solid #ccc',
                  boxShadow:
                    currentPlan.name === plan.name
                      ? '0 0 10px rgba(108,68,207,0.5)'
                      : 'none',
                }}
              >
                {/* ???? ??? ??????? ????, ?????????? ????? "Your Plan" */}
                {currentPlan.name === plan.name && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      backgroundColor: '#6c44cf',
                      color: '#fff',
                      py: 0.5,
                      mb: 1,
                      borderRadius: 1,
                    }}
                  >
                    Your Plan
                  </Typography>
                )}
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {plan.name}
                </Typography>
                <Typography variant="body2">
                  Email distribution up to {plan.limit} letters per month
                </Typography>
                <Typography variant="h5" sx={{ my: 1 }}>
                  {"\u20AC"}{plan.price}
                  <Typography variant="caption"> per month</Typography>
                </Typography>

                <Box sx={{ mt: 'auto' }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#6c44cf',
                      '&:hover': { backgroundColor: '#4a2fa0' },
                      width: '100%',
                    }}
                    onClick={() => handlePurchasePlan(plan)}
                    disabled={currentPlan.name === plan.name}
                  >
                    Upgrade
                  </Button>
                </Box>
              </Paper>
            ))}
          </Box>
        </Paper>
      )}

      {/* ??????? "Billing history" */}
      {tabIndex === 2 && (
        <Paper sx={{ p: 3, mt: 2, boxShadow: 3 }}>
          <Typography variant="h5" gutterBottom>
            Billing history
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingHistory.map((record) => (
                  <TableRow key={record.invoiceId}>
                    <TableCell>{record.invoiceId}</TableCell>
                    <TableCell>{record.date}</TableCell>
                    <TableCell>{record.product}</TableCell>
                    <TableCell>{record.total}</TableCell>
                    <TableCell>{record.status}</TableCell>
                    <TableCell align="center">
                      {/* "?????????" ? ??????? "Download invoice" */}
                      <IconButton
                        onClick={(event) => handleMenuOpen(record.invoiceId, event)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEls[record.invoiceId]}
                        open={Boolean(anchorEls[record.invoiceId])}
                        onClose={() => handleMenuClose(record.invoiceId)}
                      >
                        <MenuItem onClick={() => handleDownloadInvoice(record)}>
                          Download invoice
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default Billing;
