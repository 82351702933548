// src/components/EmailListContainer.jsx
import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Tooltip
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import EmailList from './EmailList';

// ??????? ??? ?????????????? ???? ? ??????? dd.mm.yyyy HH:mm
function formatDate(date) {
  if (!date) return '';
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

const EmailListContainer = ({ emails, onSelectEmail, selectedEmail }) => {
  // ????????? ??? ??????
  const [searchQuery, setSearchQuery] = useState('');

  // ?????? ? ?????????? ?? ?????????
  const [filterOption, setFilterOption] = useState('all'); // "all" ??? "unread"
  const [sortField, setSortField] = useState('date'); // date, from, subject
  const [sortOrder, setSortOrder] = useState('desc'); // desc ??? asc

  // ????????? ??? ??????????? ??????????? ???? ???????
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterIconClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // ??????????
  const filteredEmails = emails.filter((email) => {
    const combinedText = (
      (email.subject || '') +
      ' ' +
      (email.from || '') +
      ' ' +
      (email.to || '') +
      ' ' +
      (email.text || '')
    ).toLowerCase();
    if (searchQuery && !combinedText.includes(searchQuery.toLowerCase())) {
      return false;
    }
    if (filterOption === 'unread' && !email.unread) {
      return false;
    }
    return true;
  });

  // ??????????
  const sortedEmails = [...filteredEmails].sort((a, b) => {
    if (sortField === 'date') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    }
    if (sortField === 'from') {
      const nameA = (a.from || '').toLowerCase();
      const nameB = (b.from || '').toLowerCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }
    if (sortField === 'subject') {
      const subjA = (a.subject || '').toLowerCase();
      const subjB = (b.subject || '').toLowerCase();
      if (subjA < subjB) return sortOrder === 'asc' ? -1 : 1;
      if (subjA > subjB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  return (
    <Box sx={{ pt: 2, width: '100%', minWidth: 400 }}>
      {/* ??????? ??????: ?????? ?????? ? ?????? ??????? */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <TextField
          label="Search Mail"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{ flex: 1 }}
        />
        <Tooltip title="Filter">
          <IconButton onClick={handleFilterIconClick} size="small">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        PaperProps={{ sx: { width: 250 } }}
      >
        <MenuItem disabled>
          <strong>Filter Options</strong>
        </MenuItem>
        <MenuItem
          selected={filterOption === 'all'}
          onClick={() => { setFilterOption('all'); handleFilterClose(); }}
        >
          All messages
        </MenuItem>
        <MenuItem
          selected={filterOption === 'unread'}
          onClick={() => { setFilterOption('unread'); handleFilterClose(); }}
        >
          Unread messages
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <strong>Sort Options</strong>
        </MenuItem>
        <MenuItem
          selected={sortField === 'date'}
          onClick={() => { setSortField('date'); handleFilterClose(); }}
        >
          Sort by Date
        </MenuItem>
        <MenuItem
          selected={sortField === 'from'}
          onClick={() => { setSortField('from'); handleFilterClose(); }}
        >
          Sort by Sender
        </MenuItem>
        <MenuItem
          selected={sortField === 'subject'}
          onClick={() => { setSortField('subject'); handleFilterClose(); }}
        >
          Sort by Subject
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <strong>Order</strong>
        </MenuItem>
        <MenuItem
          selected={sortOrder === 'desc'}
          onClick={() => { setSortOrder('desc'); handleFilterClose(); }}
        >
          Descending
        </MenuItem>
        <MenuItem
          selected={sortOrder === 'asc'}
          onClick={() => { setSortOrder('asc'); handleFilterClose(); }}
        >
          Ascending
        </MenuItem>
      </Menu>
      <Divider sx={{ mb: 2 }} />

      {/* ??? ?????? ????? */}
      <Box sx={{ width: '100%' }}>
        <EmailList
          emails={sortedEmails}
          onSelectEmail={onSelectEmail}
          selectedEmail={selectedEmail}
          formatDate={formatDate}
        />
      </Box>
    </Box>
  );
};

export default EmailListContainer;
