// src/components/email/EmailDetails.jsx
import React from 'react';
import { 
  Box, 
  Typography, 
  Divider, 
  IconButton, 
  Menu, 
  MenuItem 
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import AttachmentList from './AttachmentList';

const EmailDetails = ({ email, accountId, onReply, onDelete, onRelocate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!email) return null;

  // ??????? ??? ???????? ???? ?????? ?????
  const handleRelocateClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ???????? ????; ???? ??????? ?????, ???????? onRelocate ? ????????? ?????????
  const handleRelocateClose = (folder) => {
    setAnchorEl(null);
    if (folder && onRelocate) {
      onRelocate(folder);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* ??????? ?????? ? ??????????? ? ?????? ? ???????? */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {email.subject || 'No Subject'}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            From: {email.from}
          </Typography>
        </Box>
        {/* ?????? Reply */}
        <IconButton onClick={() => onReply(email.from)} title="Reply">
          <ReplyIcon />
        </IconButton>
        {/* ?????? Relocate (??????????? ? ?????? ?????) */}
        <IconButton onClick={handleRelocateClick} title="Relocate">
          <MoveToInboxIcon />
        </IconButton>
        {/* ?????? Delete */}
        <IconButton onClick={onDelete} title="Delete">
          <DeleteIcon />
        </IconButton>
      </Box>
      
      <Divider sx={{ my: 1 }} />
      
      {/* ??????????? ?????????? ?????? */}
      <Box sx={{ whiteSpace: 'pre-line' }}>
        {email.html ? (
          <div style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{ __html: email.html }} />
        ) : (
          <Typography variant="body1">{email.text}</Typography>
        )}
      </Box>
      
      {/* ??????????? ????????, ???? ??? ???? */}
{email.attachments && email.attachments.length > 0 && (
  <AttachmentList attachments={email.attachments} />
)}

      {/* ???? ?????? ????? ??? Relocate */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleRelocateClose()}
      >
        {['Inbox', 'Sent', 'Important', 'Drafts', 'Trash'].map((folder) => (
          <MenuItem key={folder} onClick={() => handleRelocateClose(folder)}>
            {folder}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default EmailDetails;
