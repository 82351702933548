import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import AccountStatusIcon from './AccountStatusIcon';

function SmtpTab() {
  const [accounts, setAccounts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // ??????? "Add/Edit Email"
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add'); // 'add' | 'edit'
  const [formData, setFormData] = useState({
    email: '',
    incomingServer: '',
    incomingPort: 993,
    incomingConnection: 'SSL/TLS',
    incomingPassword: '',
    outgoingServer: '',
    outgoingPort: 465,
    outgoingConnection: 'SSL/TLS',
    outgoingPassword: '',
    status: 'Active',
  });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const res = await axios.get('/api/mail/accounts');
      setAccounts(res.data);
    } catch (error) {
      console.error('Error fetching SMTP accounts:', error);
    }
  };

  const handleOpenMenu = (event, account) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedAccount(null);
  };

  const handleAddEmail = () => {
    setDialogMode('add');
    setFormData({
      email: '',
      incomingServer: '',
      incomingPort: 993,
      incomingConnection: 'SSL/TLS',
      incomingPassword: '',
      outgoingServer: '',
      outgoingPort: 465,
      outgoingConnection: 'SSL/TLS',
      outgoingPassword: '',
      status: 'Active',
    });
    setOpenDialog(true);
  };

  const handleChangeEmail = () => {
    if (!selectedAccount) return;
    setDialogMode('edit');
    setFormData({
      email: selectedAccount.email,
      incomingServer: selectedAccount.incomingServer,
      incomingPort: selectedAccount.incomingPort,
      incomingConnection: selectedAccount.incomingConnection,
      incomingPassword: selectedAccount.incomingPassword,
      outgoingServer: selectedAccount.outgoingServer,
      outgoingPort: selectedAccount.outgoingPort,
      outgoingConnection: selectedAccount.outgoingConnection,
      outgoingPassword: selectedAccount.outgoingPassword,
      status: selectedAccount.status,
    });
    setOpenDialog(true);
  };

  const handleDeleteEmail = async () => {
    if (!selectedAccount) return;
    // ????????????? ????????
    const confirmDelete = window.confirm('Do you really want to delete this email?');
    if (!confirmDelete) return;
    try {
      await axios.delete(`/api/mail/accounts/${selectedAccount._id}`);
      handleCloseMenu();
      fetchAccounts();
    } catch (error) {
      console.error('Error deleting email account:', error);
    }
  };

  const handleSaveEmail = async () => {
    try {
      if (dialogMode === 'add') {
        await axios.post('/api/mail/accounts', formData);
      } else {
        await axios.put(`/api/mail/accounts/${selectedAccount._id}`, formData);
      }
      setOpenDialog(false);
      fetchAccounts();
    } catch (error) {
      console.error('Error saving email account:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeField = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Button variant="contained" onClick={handleAddEmail}>
        Add email
      </Button>

      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Connection protection</TableCell>
            <TableCell>Port</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((acc) => (
            <TableRow key={acc._id}>
              <TableCell>{acc.email}</TableCell>
              <TableCell>{acc.incomingConnection}</TableCell>
              <TableCell>{acc.incomingPort}</TableCell>
              <TableCell>
  <AccountStatusIcon status={acc.status} validationErrors={acc.validationErrors} />
</TableCell>
              <TableCell>
                <IconButton onClick={(e) => handleOpenMenu(e, acc)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* ???? ?????????? */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleChangeEmail}>Change email</MenuItem>
        <MenuItem onClick={handleDeleteEmail}>Delete</MenuItem>
      </Menu>

      {/* ?????? Add/Edit email */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          {dialogMode === 'add' ? 'Add email' : 'Change email'}
        </DialogTitle>
        <DialogContent>
          {/* ???????? ?????? (IMAP) */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Incoming mail server</Typography>
            <TextField
              margin="dense"
              label="Server name imap"
              fullWidth
              value={formData.incomingServer}
              onChange={(e) => handleChangeField('incomingServer', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Port"
              type="number"
              fullWidth
              value={formData.incomingPort}
              onChange={(e) => handleChangeField('incomingPort', e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Connection protection</InputLabel>
              <Select
                native
                value={formData.incomingConnection}
                label="Connection protection"
                onChange={(e) => handleChangeField('incomingConnection', e.target.value)}
              >
                <option value="SSL/TLS">SSL/TLS</option>
                <option value="STARTTLS">STARTTLS</option>
                <option value="None">None</option>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChangeField('email', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={formData.incomingPassword}
              onChange={(e) => handleChangeField('incomingPassword', e.target.value)}
            />
          </Box>

          {/* ????????? ?????? (SMTP) */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Outgoing mail server</Typography>
            <TextField
              margin="dense"
              label="Server name smtp"
              fullWidth
              value={formData.outgoingServer}
              onChange={(e) => handleChangeField('outgoingServer', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Port"
              type="number"
              fullWidth
              value={formData.outgoingPort}
              onChange={(e) => handleChangeField('outgoingPort', e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Connection protection</InputLabel>
              <Select
                native
                value={formData.outgoingConnection}
                label="Connection protection"
                onChange={(e) => handleChangeField('outgoingConnection', e.target.value)}
              >
                <option value="SSL/TLS">SSL/TLS</option>
                <option value="STARTTLS">STARTTLS</option>
                <option value="None">None</option>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Email (same or different?)"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChangeField('email', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={formData.outgoingPassword}
              onChange={(e) => handleChangeField('outgoingPassword', e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveEmail}>
            {dialogMode === 'add' ? 'Add email' : 'Save changes'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SmtpTab;
