// MenuNavLeft.js
import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Toolbar,
  Box
} from '@mui/material';
import { Link, useOutletContext } from 'react-router-dom'; // <-- добавили useOutletContext

// Иконки для основных пунктов
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Requests
import EmailIcon from '@mui/icons-material/Email';              // Email
import SmsIcon from '@mui/icons-material/Sms';                  // SMS
import SearchIcon from '@mui/icons-material/Search';            // Parsing

// Иконки для подпунктов Email
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'; // "Dashboard Campaigns"
import CampaignIcon from '@mui/icons-material/Campaign';                     // "Campaigns"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';               // "Templates"
import ContactsIcon from '@mui/icons-material/Contacts';                     // "Contact List"
import MailOutlineIcon from '@mui/icons-material/MailOutline';               // "Emails"
import LanguageIcon from '@mui/icons-material/Language';                     // "Domains"

// Иконки для подпунктов SMS
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

// Иконки для подпунктов Parsing
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TelegramIcon from '@mui/icons-material/Telegram'; // Telegram
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // WhatsApp
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'; // Viber
import ContactMailIcon from '@mui/icons-material/ContactMail'; // Emails / Phone
import Badge from '@mui/material/Badge';

// === ВАЖНО: Заменяем SomeAdminIcon на AdminPanelSettingsIcon ===
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 270;

export default function MenuNavLeft({ user, unreadCount }) {
  // Берём user из OutletContext (если вы так передаёте)
  
  console.log('MenuNavLeft user:', user);

  // Состояния для открытия/закрытия подменю
  const [openEmail, setOpenEmail] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);
  const [openParsing, setOpenParsing] = useState(false);

  const handleClickEmail = () => setOpenEmail(!openEmail);
  const handleClickSMS = () => setOpenSMS(!openSMS);
  const handleClickParsing = () => setOpenParsing(!openParsing);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: '1px solid #eee',
        },
      }}
    >
      {/* Отступ под AppBar (если есть фиксированный AppBar) */}
      <Toolbar />

      <Box sx={{ overflow: 'auto' }}>
        <List component="nav">

          {user?.role === 'admin' && (
            <ListItemButton component={Link} to="/admin">
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="User Management" />
            </ListItemButton>
          )}

          {/* Dashboard */}
          <ListItemButton component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>

          {/* Requests */}
        <ListItemButton component={Link} to="/requests">
  <ListItemIcon>
    <RequestQuoteIcon />
  </ListItemIcon>
  <ListItemText primary="Requests" />
  {unreadCount > 0 && (
    <Badge color="error" badgeContent={unreadCount} />
  )}
</ListItemButton>

          {/* Email (с подменю) */}
          <ListItemButton onClick={handleClickEmail}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" />
            {openEmail ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openEmail} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Dashboard Campaigns */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/dashboard">
                <ListItemIcon>
                  <DashboardCustomizeIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>

              {/* Campaigns */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/campaigns">
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Campaigns" />
              </ListItemButton>

              {/* Templates */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/templates">
                <ListItemIcon>
                  <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText primary="Templates" />
              </ListItemButton>

              {/* Contact List */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/contacts">
                <ListItemIcon>
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Contact List" />
              </ListItemButton>

              {/* Emails */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/emails">
                <ListItemIcon>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Emails" />
              </ListItemButton>

              {/* Domains */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/domains">
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary="Domains" />
              </ListItemButton>
            </List>
          </Collapse>

          {/* SMS (с подменю) */}
          {user?.role === 'admin' && (
          <ListItemButton onClick={handleClickSMS}>
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText primary="SMS" />
            {openSMS ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          
          )}
          
          <Collapse in={openSMS} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* SMS Dashboard */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/sms/dashboard">
                <ListItemIcon>
                  <DashboardCustomizeIcon />
                </ListItemIcon>
                <ListItemText primary="SMS Dashboard" />
              </ListItemButton>

              {/* SMS Campaign */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/sms/campaign">
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="SMS Campaign" />
              </ListItemButton>

              {/* SMS Contacts */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/sms/contacts">
                <ListItemIcon>
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText primary="SMS Contacts" />
              </ListItemButton>

              {/* SMS Phones */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/sms/phones">
                <ListItemIcon>
                  <PhoneAndroidIcon />
                </ListItemIcon>
                <ListItemText primary="SMS Phones" />
              </ListItemButton>
            </List>
          </Collapse>

          {/* Parsing (с подменю) */}
          <ListItemButton onClick={handleClickParsing}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Parsing" />
            {openParsing ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openParsing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Telegram */}
              {user?.role === 'admin' && (
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/parsing/telegram">
                <ListItemIcon>
                  <TelegramIcon />
                </ListItemIcon>
                <ListItemText primary="Telegram" />
              </ListItemButton>
)}
              {/* WhatsApp */}
              {user?.role === 'admin' && (
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/parsing/whatsapp">
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText primary="WhatsApp" />
              </ListItemButton>
)}
              {/* Viber */}
              {user?.role === 'admin' && (
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/parsing/viber">
                <ListItemIcon>
                  <PhoneIphoneIcon />
                </ListItemIcon>
                <ListItemText primary="Viber" />
              </ListItemButton>
)}
              {/* Emails / Phone */}
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/parsing">
                <ListItemIcon>
                  <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary="Emails / Phone" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
    </Drawer>
  );
}
