// servicesData.js

export const servicesData = [
  {
    title: 'Email campaigns',
    whatIsIt: 'A comprehensive set of tasks for creating and running effective email newsletters.',
    whyYouNeedIt: [
      'Attract and retain customers through regular, informative emails.',
      'Increase conversion rates with personalized content and proper audience segmentation.',
      'Strengthen loyalty by showing customers you care with timely news, promotions, and offers.',
    ],
    keyPoints: [
      'Segmentation of the audience for precise targeting.',
      'Creation of automated email sequences (welcome, trigger-based, promotional, etc.).',
      'Analysis of metrics (open rates, clicks, unsubscribes, conversions) for continuous improvement.',
    ],
  },
  {
    title: 'Parsing contacts',
    whatIsIt: 'Collecting and systematizing potential customer contacts from open sources or specialized platforms.',
    whyYouNeedIt: [
      'Quickly expand your lead database for newsletters and direct sales.',
      'Focus on the most promising market segments.',
      'Save time and effort with automated tools handling most routine tasks.',
    ],
    keyPoints: [
      'Legality and ethics: compliance with GDPR and local data protection regulations.',
      'Accuracy: removing invalid or outdated contacts.',
      'Integration: delivering data in convenient formats for CRM systems or mailing services.',
    ],
  },
  {
    title: 'Google ads',
    whatIsIt: 'Setting up and managing ad campaigns in Google (Search, Display Network, YouTube, Shopping, etc.).',
    whyYouNeedIt: [
      'Drive high-quality traffic from the world�s leading search engine.',
      'Quickly reach your audience via relevant keywords, boosting sales and brand awareness.',
      'Flexible budgeting and bidding strategies for cost optimization.',
    ],
    keyPoints: [
      'Selecting effective keywords and negative keywords for maximum targeting accuracy.',
      'Optimizing ads (A/B testing headlines and descriptions).',
      'Analyzing conversions and ROI to refine performance and reduce cost-per-click.',
    ],
  },
  {
    title: 'Facebook ads',
    whatIsIt: 'Advertising on Facebook, including boosted posts, in-feed and story ads, as well as retargeting.',
    whyYouNeedIt: [
      'Tap into a massive audience with a high level of engagement.',
      'Flexible targeting by interests, demographics, and user behavior.',
      'Strengthen your brand with visually appealing ad formats: images, videos, carousels, etc.',
    ],
    keyPoints: [
      'Testing different ad formats (Single Image, Video, Carousel, etc.).',
      'Utilizing Facebook Pixel for tracking on-site actions and precise retargeting.',
      'Analyzing key metrics: clicks, reach, frequency, cost per result.',
    ],
  },
  {
    title: 'VK ads',
    whatIsIt: 'Placing ads in the VKontakte social network, which is popular in the CIS and offers broad promotion opportunities.',
    whyYouNeedIt: [
      'Reach a large Russian-speaking audience that is active and highly engaged.',
      'Ideal for local or niche projects, thanks to detailed targeting by location, interests, and communities.',
      'Ad formats include sponsored posts in the feed, stories, banner ads, and targeting users of specific groups.',
    ],
    keyPoints: [
      'Using VK Retargeting tools to re-engage visitors who have already been to your website.',
      'Testing different landing pages (product pages, offers, lead forms).',
      'In-depth analysis in Ads Manager (CTR, reach, sign-ups, conversions).',
    ],
  },
  {
    title: 'SMM marketing',
    whatIsIt: 'Comprehensive social media promotion (Facebook, Instagram, VK, TikTok, etc.) to boost brand awareness and audience engagement.',
    whyYouNeedIt: [
      'Create unique content that builds a loyal community and drives interest in your brand.',
      'Enable direct communication with customers, offering quick feedback and increasing trust.',
      'Grow both organic and paid reach through a well-planned content strategy and regular interaction.',
    ],
    keyPoints: [
      'Developing a content plan aligned with trends and audience interests.',
      'Using stories, live streams, contests, and themed posts to foster engagement.',
      'Monitoring and analyzing key metrics (likes, comments, shares, reach, new subscribers) for strategic adjustments.',
    ],
  },
];
