// CustomMailNotificationContent.js
import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

const CustomMailNotificationContent = React.forwardRef((props, ref) => {
  const { message, closeSnackbar } = props;
  // ???????????, ??? ??????????? ??? ????? ???????? ?????? ? ???? email,
  // ?????????? from, subject, text ? ?.?.
  const notification = message;
  
  // ???? ?????? ?? ???????? ??? ???????????, ??????? ??? ?????
  const isUnread = !notification?.email?.isRead;
  // ????? ??? ??????????? ??????????? ? ????
  const fromColor = isUnread ? '#FF8C00' : '#12014B';
  const subjectColor = isUnread ? '#8D00F2' : '#4B4B4B';

  return (
    <Box
      ref={ref}
      sx={{
        width: 340,
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 1.5,
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Avatar
          src={notification?.email?.avatarUrl || '/images/user.png'}
          sx={{ width: 40, height: 40, mr: 1 }}
        >
          {notification?.email?.from
            ? notification.email.from.charAt(0)
            : ''}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2" sx={{ color: fromColor, fontWeight: 600 }}>
            {notification?.email?.from || 'Unknown'}
            {' '}
            <Typography
              component="span"
              variant="body2"
              sx={{ color: subjectColor, fontWeight: 600 }}
            >
              {notification?.email?.subject || ''}
            </Typography>
          </Typography>
        </Box>
        {isUnread && <CircleIcon sx={{ fontSize: 10, color: '#FF8C00' }} />}
      </Box>

      <Typography variant="body2" sx={{ color: '#4A4A4A', mt: 1 }}>
        {notification?.email?.text || 'No content available'}
      </Typography>
      <Typography variant="caption" sx={{ color: '#8F8F8F' }}>
        {new Date(notification?.createdAt || Date.now()).toLocaleString()}
      </Typography>

      <IconButton
        size="small"
        sx={{ position: 'absolute', top: 4, right: 4 }}
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
});

CustomMailNotificationContent.displayName = 'CustomMailNotificationContent';
export default CustomMailNotificationContent;
