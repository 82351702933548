import React, { useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { io } from 'socket.io-client';
import CustomNotificationContent from './CustomNotificationContent';

const NotificationPush = ({ userId }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const audioRef = useRef(null);
  const interactedRef = useRef(false);

  // ??????? ????? ???????
  useEffect(() => {
    audioRef.current = new Audio('/assets/sounds/mailsball-chime.mp3');

    // ????????????
    audioRef.current.load();

    // ????????????? "??????????" ?? ???????????? ??? ?????? ??????????????
    const enableSound = () => {
      if (!interactedRef.current && audioRef.current) {
        audioRef.current.play().catch(() => {}); // ????? ??????
        interactedRef.current = true;
      }
    };

    document.addEventListener('click', enableSound);
    document.addEventListener('keydown', enableSound);

    return () => {
      document.removeEventListener('click', enableSound);
      document.removeEventListener('keydown', enableSound);
    };
  }, []);

  useEffect(() => {
    const socket = io('https://mailsball.com', { withCredentials: true });

    if (userId) {
      socket.emit('join', userId);
    }

    socket.on('notificationCreated', (notification) => {
      console.log('Received notification in NotificationPush:', notification);

      if (
        notification.user &&
        userId &&
        notification.user.toString() === userId.toString()
      ) {
        // ??????????? ????
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play().catch(err =>
            console.error('Failed to play sound:', err)
          );
        }

        // ?????????? ???????????
        enqueueSnackbar('', {
          variant: 'customNotification',
          content: (key) => (
            <CustomNotificationContent
              key={key}
              message={notification}
              closeSnackbar={() => closeSnackbar(key)}
            />
          )
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [enqueueSnackbar, userId, closeSnackbar]);

  return null;
};

export default NotificationPush;
