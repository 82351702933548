//ComposeDialoge.jsx

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

/**
 * mode: 'new' | 'reply'
 * defaultTo: ? ?????? reply ??????????? email.from
 * accountId: ???????, ? ???????? ????? ??????????
 * defaultSubject: ????????? ???????? ??? ???? (??? reply)
 */
const ComposeDialog = ({ open, onClose, mode, accountId, defaultTo, defaultSubject, onSuccess }) => {
  // ????????? ?????
  const [to, setTo] = useState(defaultTo || '');
  const [subject, setSubject] = useState(mode === 'reply' ? (defaultSubject || 'Re: ') : '');
  const [editorContent, setEditorContent] = useState('');

  // ????????? ??? ??????????? ???????????
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // ?????????? ?????? "Send"
  const handleSend = async () => {
    if (!accountId) return;

    try {
      if (mode === 'new') {
        // ?????????? ????? ??????
        await axios.post(`/api/mail/messages/${accountId}/send`, {
          to,
          subject,
          htmlContent: editorContent,
        });
      } else {
        // ?????????? reply
        const fakeMessageId = '123'; // ??? ???????? id ??????, ???? ? ??? ????
        await axios.post(`/api/mail/messages/${fakeMessageId}/reply/${accountId}`, {
          to,
          subject,
          htmlContent: editorContent,
        });
      }
      // ???????
      
      onClose();
      
      onSuccess('Email sent successfully!');
      // ???? ?????? ????? ??????? ?????? ????? ??????, ????????????????:
      
      
    } catch (error) {
      setErrorMessage('Failed to send email.');
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      {/* ??? ?????? */}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          {mode === 'new' ? 'New Message' : 'Reply'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="To"
            fullWidth
            margin="dense"
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
          <TextField
            label="Subject"
            fullWidth
            margin="dense"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <ReactQuill
            theme="snow"
            value={editorContent}
            onChange={setEditorContent}
            style={{ height: 200, marginTop: 10 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSend}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar ??? ?????? */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert
          onClose={() => setSuccessMessage('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar ??? ?????? */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ComposeDialog;
