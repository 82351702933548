// menuItems.js
export const getMenuItems = (authenticated) => {
  if (authenticated) {
    return [
      { text: 'Home', path: '/' },
      { text: 'Parsing', path: '/parsing' },
      { text: 'Requests', path: '/requests' },
      { text: 'Request Form', path: '/requestform' },
      { 
        text: 'Email',
        submenu: [
      { text: 'Dashboard', path: '/dashboard' },
      { text: 'Campaigns', path: '/campaigns' },
      { text: 'Templates', path: '/template' },
      { text: 'Contact List', path: '/contacts' },
      { text: 'Emails', path: '/emails' },
      { text: 'Domains', path: '/domains' },
       ]
      },
      // ????? ????? ??? SMS ? ?????????? ??????????
      { 
        text: 'SMS',
        submenu: [
          { text: 'SMS Dashboard', path: '/sms/dashboard' },
          { text: 'SMS Campaign', path: '/sms/campaign' },
          { text: 'SMS Contacts', path: '/sms/contacts' },
          { text: 'SMS Phones', path: '/sms/phones' },
        ]
      },
    ];
  } else {
    return [
      { text: 'Home', path: '/' },
      { text: 'Parsing', path: '/parsing' },
      { text: 'Request Form', path: '/requestform' }
    ];
  }
};