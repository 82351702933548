//Emails.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  Avatar
} from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// ??????: ???? ? ??? ??? Material UI Icon Add, ????? ?????????????:
// import AddIcon from '@mui/icons-material/Add';

const Emails = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [openVerify, setOpenVerify] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  // === ????? ????????? ??? ?????? ? ???????? ===
  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarUploading, setAvatarUploading] = useState(false);
  // =============================================

  // Function to fetch the list of emails
  const fetchEmails = async () => {
    try {
      const response = await axios.get('/api/emails');
      setEmails(response.data);
    } catch (error) {
      console.error('Failed to fetch email list', error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  // Function to send verification email
  const sendVerificationEmail = async () => {
    if (!email) {
      alert('Please enter an email.');
      return;
    }

    try {
      await axios.post('/api/emails/send-verification-code', { email });
      alert('Verification email sent. Please check your inbox.');
      setOpenVerify(true);
    } catch (error) {
      console.error('Failed to send verification email', error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert('Failed to send verification email. Please try again later.');
      }
    }
  };

  // Function to verify email
  const verifyEmail = async () => {
    try {
      await axios.post('/api/emails/verify', { email, verificationCode });
      fetchEmails();
      setOpenVerify(false);
      setEmail('');
      setVerificationCode('');
      alert('Email successfully verified!');
    } catch (error) {
      console.error('Failed to verify email', error);
      alert('Verification failed. Please check the code and try again.');
    }
  };

  // Function to send verification code for the selected email
  const handleSendCode = async (email) => {
    console.log('handleSendCode called with email:', email);
    try {
      await axios.post('/api/emails/send-verification-code', { email });
      alert('Verification code sent. Please check your inbox.');
      setEmail(email);
      setOpenVerify(true);
    } catch (error) {
      console.error('Failed to send verification code', error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert('Failed to send verification code. Please try again later.');
      }
    }
  };

  // Function to open the delete confirmation dialog
  const handleOpenDeleteDialog = (email) => {
    setSelectedEmail(email);
    setOpenDelete(true);
  };

  // Function to close the delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
    setSelectedEmail(null);
  };

  // Function to delete an email
  const handleDeleteEmail = async () => {
    try {
      await axios.delete(`/api/emails/${selectedEmail._id}`);
      setEmails(emails.filter((e) => e._id !== selectedEmail._id));
      setOpenDelete(false);
      setSelectedEmail(null);
      alert('Email successfully deleted.');
    } catch (error) {
      console.error('Failed to delete email:', error);
      alert('Failed to delete email. Please try again later.');
    }
  };

  // ============ ?????? ? ???????? ============
  // ????????? ?????? ???????? ??????? (???????? email-??????)
  const handleOpenAvatarDialog = (emailObj) => {
    setSelectedEmail(emailObj);
    setAvatarFile(null); // ?????????? ?????????? ?????
    setOpenAvatarDialog(true);
  };

  const handleCloseAvatarDialog = () => {
    setOpenAvatarDialog(false);
    setSelectedEmail(null);
    setAvatarFile(null);
  };

  // ????? ???????????? ???????? ????
  const handleAvatarFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
    }
  };

  // ?????????? (????????) ????? ?? ??????
  const handleAvatarUpload = async () => {
    if (!avatarFile) {
      alert('Please select an image file.');
      return;
    }
    if (!selectedEmail) {
      alert('No email is selected.');
      return;
    }

    try {
      setAvatarUploading(true);
      const formData = new FormData();
      formData.append('avatar', avatarFile);

      // ????????, PUT /api/emails/:id/avatar
      await axios.put(`/api/emails/${selectedEmail._id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Avatar uploaded successfully!');
      // ????????? ?????? email
      fetchEmails();
      window.dispatchEvent(new CustomEvent("domainsUpdated"));

      setAvatarUploading(false);
      handleCloseAvatarDialog();
    } catch (error) {
      console.error('Failed to upload avatar:', error);
      alert('Failed to upload avatar. Please try again later.');
      setAvatarUploading(false);
    }
  };
  // ==========================================

  return (
    <div>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={sendVerificationEmail} fullWidth>
        Send Verification Email
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {/* ????????? ????? ??????? Avatar */}
              <TableCell>Avatar</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails.length > 0 ? (
              emails.map((e) => (
                <TableRow key={e._id}>
                  {/* ?????? ? ???????? */}
                  <TableCell>
                    {e.avatarUrl ? (
                      <Tooltip title="Change avatar">
                        <IconButton onClick={() => handleOpenAvatarDialog(e)}>
                          {/* ?????????? ?????? ? ?????? */}
                          <Avatar src={e.avatarUrl} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Upload avatar">
                        <IconButton onClick={() => handleOpenAvatarDialog(e)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell>{e.email}</TableCell>
                  <TableCell>{e.isVerified ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right">
                    {!e.isVerified && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleSendCode(e.email)}
                        style={{ marginRight: '10px' }}
                      >
                        Send Code
                      </Button>
                    )}
                    <Tooltip title="Delete Email">
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(e)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No emails found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for delete confirmation */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the email <strong>{selectedEmail?.email}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteEmail} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for email verification */}
      <Dialog open={openVerify} onClose={() => setOpenVerify(false)}>
        <DialogTitle>Email Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We have sent a verification code to {email}. Please enter the code below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Verification Code"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerify(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={verifyEmail} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Avatar upload */}
      <Dialog open={openAvatarDialog} onClose={handleCloseAvatarDialog}>
        <DialogTitle>Upload or Change Avatar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select an image file to set/change the avatar.
          </DialogContentText>
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarFileChange}
          />
          {avatarFile && (
            <p>Selected file: {avatarFile.name}</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAvatarDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleAvatarUpload} 
            color="primary"
            disabled={avatarUploading}
          >
            {avatarUploading ? 'Uploading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Emails;
