// components/NotificationBell.js
import React, { useState } from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
  Typography,
  Avatar,
  Divider,
  Button
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from 'react-router-dom';

function NotificationBell({ notifications, onMarkAsRead }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const unreadCount = notifications.filter(n => !n.isRead).length;

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const truncateText = (text, maxLength = 50) =>
    text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  const handleNotificationClick = (notification) => {
    if (!notification.isRead) {
      onMarkAsRead(notification._id);
    }
    handleClose();
  };

return (
  <>
    <IconButton onClick={handleOpen} sx={{ color: '#fff' }}>
      <Badge badgeContent={unreadCount} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          mt: 1,
          width: 340,
          maxHeight: 400,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {/* ?????????????? ??????? ??????????? */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', pr: 1 }}>
        {notifications.length === 0 ? (
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="body2">No new notifications</Typography>
          </Box>
        ) : (
          notifications.map((n) => {
            const isUnread = !n.isRead;
            const loginColor = isUnread ? '#FF8C00' : '#12014B';
            const projectColor = isUnread ? '#8D00F2' : '#4B4B4B';
            return (
              <MenuItem
                key={n._id}
                onClick={() => handleNotificationClick(n)}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', py: 1.5 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Avatar
                    src={n.triggeredBy?.avatarUrl || "/images/user.png"}
                    sx={{ width: 40, height: 40, mr: 1 }}
                  >
                    {n.triggeredBy?.firstName ? n.triggeredBy.firstName.charAt(0) : ""}
                  </Avatar>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ color: loginColor, fontWeight: 600 }}>
                      {n.triggeredBy 
                        ? (
                            n.triggeredBy.role === 'admin'
                              ? 'Admin'
                              : n.triggeredBy.role === 'manager'
                                ? 'Manager'
                                : (n.triggeredBy.login || `${n.triggeredBy.firstName || ""} ${n.triggeredBy.lastName || ""}`.trim())
                          )
                        : "System"}
                      {" "}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: projectColor, fontWeight: 600 }}
                      >
                        {n.projectName}
                      </Typography>
                    </Typography>
                  </Box>
                  {isUnread && (
                    <CircleIcon sx={{ fontSize: 10, color: '#FF8C00' }} />
                  )}
                </Box>

                <Typography
  variant="body2"
  sx={{
    color: '#4A4A4A',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  }}
>
  {truncateText(n.changes, 60)}
</Typography>
                <Typography variant="caption" sx={{ color: '#8F8F8F' }}>
                  {new Date(n.createdAt).toLocaleString()}
                </Typography>
              </MenuItem>
            );
          })
        )}
      </Box>

      {/* ????????????? ??????? ? ??????? All Notifications */}
      {notifications.length > 0 && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#FFFFFF',
            zIndex: 1,
            p: 1,
            borderTop: '1px solid rgba(0,0,0,0.12)',
          }}
        >
          <Button
            component={Link}
            to="/notifications"
            variant="text"
            sx={{ textTransform: 'none', fontWeight: 600, width: '100%' }}
            onClick={handleClose}
          >
            All Notifications
          </Button>
        </Box>
      )}
    </Menu>
  </>
);
}

export default NotificationBell;
