// src/components/MyProfile.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const MyProfile = () => {
 const { updateUser } = useOutletContext();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  // ????????? ??? ???????? (?????, email, etc.)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogField, setDialogField] = useState(null);
  const [dialogValue, setDialogValue] = useState('');

  // ????????? ??? ????????? ??????
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // ????????? ??? ???????? ???????
  const [avatarFile, setAvatarFile] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  // 1. ??? ???????????? ?????????? ??????????? ?????????? ?????? ????????????
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // ???????????, ??? ? ??? ???? ???????? GET /api/users/me,
        // ??????? ?????????? ?????? ???????? ???????????? (?? ????? ?????????? ID).
        const res = await axios.get('/api/users/me');
        setProfileData(res.data); // ????????, { login, email, firstName, ... }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  // 2. ???? ???? ????????, ?????????? ??????? ??? ????? ?????? ?????????
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  // 3. ???? ?? ?????-?? ??????? ??????? ?? ??????, ????? ??????? ?????????
  if (!profileData) {
    return <Typography sx={{ mt: 4, textAlign: 'center' }}>Profile not found.</Typography>;
  }

  // --- ????????/???????? ??????? ??? ????????????? ???? ---
  const handleOpenDialog = (fieldKey) => {
    setDialogField(fieldKey);
    setDialogValue(profileData[fieldKey] || '');
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogField(null);
    setDialogValue('');
  };

  // --- ?????????? ????????? (?????, email, etc.) ---
  const handleSaveDialog = async () => {
  if (!dialogField) return;
  try {
    const res = await axios.put('/api/users/me', {
      [dialogField]: dialogValue,
    });
    // ????????? ????????? ?????
    setProfileData((prev) => ({ ...prev, [dialogField]: dialogValue }));
    // ???????? ??????????? ?????? ? ?????????? ????????? ????? updateUser
    updateUser(res.data);
    handleCloseDialog();
    alert(`${formatDialogField(dialogField)} Success!`);
  } catch (error) {
    console.error('Update error:', error);
    alert('Update error');
  }
};

  // --- ????????/???????? ??????? ??? ?????? ---
  const handleOpenPasswordDialog = () => {
    setPasswordDialogOpen(true);
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false);
  };

  // --- ????????? ?????? ---
  const handleChangePassword = async () => {
  if (newPassword !== confirmPassword) {
    alert('?????? ?? ?????????!');
    return;
  }
  try {
    const res = await axios.put('/api/users/me/password', { newPassword });
    alert('?????? ??????? ???????!');
    handleClosePasswordDialog();
    // ???? ?????? ?????????? ???????????? ????????????, ??:
    if (res.data && res.data.user) {
      updateUser(res.data.user);
    }
  } catch (error) {
    console.error('Error changing password:', error);
    alert('Error changing password.');
  }
};

  // --- ?????? ? ???????? ---
  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
    }
  };

 const handleUploadAvatar = async () => {
  if (!avatarFile) return;
  try {
    setUploadingAvatar(true);
    const formData = new FormData();
    formData.append('avatar', avatarFile);

    // ?????????? ?????? ???????? ??? ?????? ? ??????? ?????????????
    const res = await axios.put('/api/users/me/avatar', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (res.data && res.data.user) {
      setProfileData((prev) => ({
        ...prev,
        avatarUrl: res.data.user.avatarUrl,
      }));
      updateUser({ avatarUrl: res.data.user.avatarUrl });
    }
    alert('Avatar updated successfully!');
    setAvatarFile(null);
  } catch (error) {
    console.error('Avatar upload error:', error);
    alert('Avatar update error.');
  } finally {
    setUploadingAvatar(false);
  }
};
  
  const formatDialogField = (field) => {
  if (!field) return "";
  // ????????? ?????? ????? ?????? ????????? ??????, ????? ?????? ?????? ????? ?????????
  const withSpaces = field.replace(/([A-Z])/g, ' $1');
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
};

  // 4. ?????????? JSX ? ??????????? (??? ? ?????????? ???????)
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box
        sx={{
          maxWidth: '1000px',
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          mx: 'auto',
          p: { xs: 3, md: 5 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          My Profile
        </Typography>

        {/* ?????? */}
        <Box sx={{ position: 'relative', mb: 3 }}>
          <Avatar
            src={
              avatarFile
                ? URL.createObjectURL(avatarFile)
                : profileData.avatarUrl
            }
            sx={{ width: 100, height: 100, cursor: 'pointer' }}
          />
          <IconButton
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor: 'white',
              '&:hover': { backgroundColor: '#ddd' },
            }}
            size="small"
            component="label"
          >
            <EditIcon fontSize="small" />
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleAvatarChange}
            />
          </IconButton>
        </Box>

        {avatarFile && (
          <Box sx={{ mb: 3 }}>
            <Button
              variant="contained"
              onClick={handleUploadAvatar}
              disabled={uploadingAvatar}
            >
              {uploadingAvatar ? <CircularProgress size={20} /> : 'Save new avatar!'}
            </Button>
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'grid',
            rowGap: '24px',
          }}
        >
          {/* Login */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              required
              label="Login"
              value={profileData.login}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('login')}>
              Change
            </Button>
          </Box>

          {/* Email */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              required
              label="Email address"
              value={profileData.email}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('email')}>
              Change
            </Button>
          </Box>

          {/* Password */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              required
              label="Password"
              value="******"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={handleOpenPasswordDialog}>
              Change
            </Button>
          </Box>

          {/* First Name */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="First name"
              value={profileData.firstName}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('firstName')}>
              Change
            </Button>
          </Box>

          {/* Last Name */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Last name"
              value={profileData.lastName}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('lastName')}>
              Change
            </Button>
          </Box>

          {/* Phone */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Phone number"
              value={profileData.phone}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('phone')}>
              Change
            </Button>
          </Box>

          {/* Company */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Company name"
              value={profileData.company}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mr: 2 }}
            />
            <Button variant="outlined" onClick={() => handleOpenDialog('company')}>
              Change
            </Button>
          </Box>
        </Box>
      </Box>

      {/* ?????? ?????????????? ????????????? ???? */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Change {formatDialogField(dialogField)}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            value={dialogValue}
            onChange={(e) => setDialogValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveDialog}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* ?????? ????????? ?????? */}
      <Dialog open={passwordDialogOpen} onClose={handleClosePasswordDialog} maxWidth="sm">
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleChangePassword}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyProfile;
