//Notifications.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useOutletContext } from 'react-router-dom';

function Notifications() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
const { notifications, markNotificationAsRead } = useOutletContext();

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const displayed = notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        All Notifications
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell><strong>Author</strong></TableCell>
            <TableCell><strong>Project</strong></TableCell>
            <TableCell><strong>Changes</strong></TableCell>
            <TableCell><strong>Time</strong></TableCell>
            <TableCell><strong>Action</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayed.map(n => {
            const isUnread = !n.isRead;
            const loginColor = isUnread ? '#FF8C00' : '#12014B';
            const projectColor = isUnread ? '#8D00F2' : '#4B4B4B';
            return (
              <TableRow key={n._id}>
  <TableCell>
    {isUnread && (
      <CircleIcon sx={{ fontSize: 12, color: '#FF8C00' }} />
    )}
  </TableCell>
  <TableCell>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {/* ??????????? ??????, ???? ????, ????? /images/user.png */}
      <Avatar
        src={n.triggeredBy?.avatarUrl || '/images/user.png'}
        sx={{ width: 32, height: 32 }}
      />
      <Typography sx={{ color: loginColor, fontWeight: 600 }}>
        {/* ??????? ????? (??? email, ??? ???) */}
        {n.triggeredBy 
                        ? (
                            n.triggeredBy.role === 'admin'
                              ? 'Admin'
                              : n.triggeredBy.role === 'manager'
                                ? 'Manager'
                                : (n.triggeredBy.login || `${n.triggeredBy.firstName || ""} ${n.triggeredBy.lastName || ""}`.trim())
                          )
                        : "System"}
                      {" "}
      </Typography>
    </Box>
  </TableCell>
  <TableCell>
    <Typography sx={{ color: projectColor, fontWeight: 600 }}>
      {n.projectName}
    </Typography>
  </TableCell>
  <TableCell sx={{ color: '#4A4A4A' }}>
    {n.changes}
  </TableCell>
  <TableCell sx={{ color: '#8F8F8F' }}>
    {new Date(n.createdAt).toLocaleString()}
  </TableCell>
  <TableCell>
    <Button
      variant="outlined"
      size="small"
      sx={{ textTransform: 'none' }}
      onClick={() => markNotificationAsRead(n._id)}
    >
      Viewed
    </Button>
  </TableCell>
</TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={notifications.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default Notifications;
