// src/components/MessagesTab.jsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import ComposeDialog from './email/ComposeDialog';
import axios from 'axios';
import EmailListContainer from './email/EmailListContainer';
import EmailDetails from './email/EmailDetails';
import io from 'socket.io-client';

const folderOptions = ['Inbox', 'Sent Mail', 'Important', 'Drafts', 'Trash'];

function MessagesTab() {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('Inbox');
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  // ????????? ??? ???????? Compose / Reply
  const [openCompose, setOpenCompose] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [replyTo, setReplyTo] = useState('');
  const [replySubject, setReplySubject] = useState('');

  // ????????? ??? ???????????
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // ????????? ??? Relocate
  const [relocateFolder, setRelocateFolder] = useState('Inbox');

  // ????????? loading
  const [loading, setLoading] = useState(false);
  
  const handleComposeSuccess = () => {
  setSuccessMessage('Email sent successfully.');
};

const handleComposeError = (msg) => {
  setErrorMessage(msg);
};

 const loadEmails = useCallback(async () => {
  if (!selectedAccount) return;
  console.log('loadEmails invoked');
  try {
    setLoading(true);
    const res = await axios.get('/api/mail/messages', {
      params: { accountId: selectedAccount._id, folder: selectedFolder },
      withCredentials: true,
    });
    console.log('Received emails from API:', res.data);
    const allEmails = res.data.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setEmails(allEmails);
    setSelectedEmail(null);
  } catch (error) {
    console.error('Error loading emails:', error);
  } finally {
    setLoading(false);
  }
}, [selectedAccount, selectedFolder]);


useEffect(() => {
  const handleRefreshEmails = () => {
    console.log('refreshEmails event received in MessagesTab');
    loadEmails();
  };
  window.addEventListener('refreshEmails', handleRefreshEmails);
  return () => {
    window.removeEventListener('refreshEmails', handleRefreshEmails);
  };
}, [loadEmails]);

  // ???????? ?????????
  useEffect(() => {
    async function fetchAccounts() {
      try {
        const res = await axios.get('/api/mail/accounts', { withCredentials: true });
        setAccounts(res.data || []);
        if (res.data && res.data.length > 0) {
          setSelectedAccount(res.data[0]);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    }
    fetchAccounts();
  }, []);

  // ???????? ????? ??? ????????? ?????????? ???????? ??? ?????
useEffect(() => {
  const loadEmails = async () => {
    if (!selectedAccount) return;
    try {
      setLoading(true);
      console.log('BEFORE axios get /api/mail/messages');
      const res = await axios.get('/api/mail/messages', {
        params: { accountId: selectedAccount._id, folder: selectedFolder },
        withCredentials: true
      });
      console.log('AFTER axios get, result=', res.data);
      const allEmails = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setEmails(allEmails);
      setSelectedEmail(null);
    } catch (error) {
      console.error('Error loading emails:', error);
    } finally {
      setLoading(false);
    }
  };

  // ????????? ?????? ??? ????????????/????? ???????? ??? ?????
  loadEmails();

  // ???????????? ? Socket.io ????? ?????
  const socket = io('https://mailsball.com', { withCredentials: true });
  socket.emit('join', selectedAccount ? selectedAccount.userId : ''); // ?????????, ??? ? ??? ?????????? userId

  // ??????? ???????, ??????? ???????? ? ???????
  socket.on('notificationCreated', (notification) => {
    console.log('MessagesTab received notificationCreated:', notification);
    // ???? ??????? ????????????? ?????? ??????, ????????? ??????
    if (notification && notification.type === 'newEmail') {
      loadEmails();
    }
  });

  return () => {
    socket.off('notificationCreated');
    socket.disconnect();
  };
}, [selectedAccount, selectedFolder]);

  const handleNewMessage = () => {
    setOpenCompose(true);
  };

  const handleOpenReply = (fromAddress, subjectValue) => {
    setReplyTo(fromAddress || '');
    setReplySubject(subjectValue ? `Re: ${subjectValue}` : '');
    setOpenReply(true);
  };

  const handleDelete = () => {
    if (!selectedEmail || !selectedAccount) return;
    axios
      .post(`/api/mail/messages/${selectedAccount._id}/move`, {
        messageSeqno: selectedEmail.seqno.toString(),
        sourceFolder: selectedFolder,
        targetFolder: 'Trash'
      }, { withCredentials: true })
      .then(() => {
        // ???????? ???????? ? ??????? ??????
        axios.get('/api/mail/messages', {
          params: { accountId: selectedAccount._id, folder: selectedFolder },
          withCredentials: true
        })
        .then((res) => setEmails(res.data));
        setSelectedEmail(null);
        setSuccessMessage('Email successfully deleted');
      })
      .catch((err) => {
        console.error('Delete error:', err);
        setErrorMessage('Failed deleting email');
      });
  };

  const handleRelocate = (targetFolder) => {
    if (!selectedEmail || !selectedAccount) return;
    axios
      .post(`/api/mail/messages/${selectedAccount._id}/move`, {
        messageSeqno: selectedEmail.seqno.toString(),
        sourceFolder: selectedFolder,
        targetFolder
      }, { withCredentials: true })
      .then(() => {
        axios.get('/api/mail/messages', {
          params: { accountId: selectedAccount._id, folder: selectedFolder },
          withCredentials: true
        })
        .then((res) => setEmails(res.data));
        setSuccessMessage('Email successfully moved');
      })
      .catch((err) => {
        console.error('Relocate error:', err);
        setErrorMessage('Failed moving email');
      });
  };

  // ??? ?????? ??????
  const handleSelectEmail = (email) => {
    // ??????? ???? unread ????????
    const updatedEmail = { ...email, unread: false };
    setSelectedEmail(updatedEmail);
    setEmails((prevEmails) =>
      prevEmails.map((e) => (e.seqno === email.seqno ? updatedEmail : e))
    );
    // ????????? markAsRead
    axios.post(`/api/mail/markAsRead/${selectedAccount._id}`, {
      messageUid: email.uid,
      folder: selectedFolder,
    }, { withCredentials: true })
    .catch((err) => console.error('markAsRead error:', err));
  };

  return (
    <Box sx={{ backgroundColor: '#F3F7FA', p: 2, height: 'calc(100vh - 100px)' }}>
      
      {/* ??? ????? ?????????? Snackbar ??? success ? error */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert
          onClose={() => setSuccessMessage('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Paper
        elevation={2}
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        }}
      >
        {/* ??????? ?????? */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Select
            size="small"
            value={selectedAccount ? selectedAccount._id : ''}
            onChange={(e) => {
              const acc = accounts.find((a) => a._id === e.target.value);
              setSelectedAccount(acc);
            }}
            sx={{ mr: 2 }}
          >
            {accounts.map((acc) => (
              <MenuItem key={acc._id} value={acc._id}>
                {acc.email}
              </MenuItem>
            ))}
          </Select>
          <Select
            size="small"
            value={selectedFolder}
            onChange={(e) => setSelectedFolder(e.target.value)}
            sx={{ mr: 2 }}
          >
            {folderOptions.map((f) => (
              <MenuItem key={f} value={f}>
                {f}
              </MenuItem>
            ))}
          </Select>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Button
  variant="outlined"
  onClick={loadEmails}
  sx={{ mr: 2 }}
>
  Update mail
</Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewMessage}
            sx={{ mr: 2 }}
          >
            New message
          </Button>
          <Button
            variant="outlined"
            startIcon={<ReplyIcon />}
            onClick={() =>
              handleOpenReply(selectedEmail?.from, selectedEmail?.subject)
            }
            disabled={!selectedEmail}
            sx={{ mr: 2 }}
          >
            Reply
          </Button>
          <Select
            size="small"
            value={relocateFolder}
            onChange={(e) => setRelocateFolder(e.target.value)}
            sx={{ mr: 1 }}
          >
            {folderOptions.map((f) => (
              <MenuItem key={f} value={f}>
                {f}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            onClick={() => handleRelocate(relocateFolder)}
            disabled={!selectedEmail}
          >
            <MoveToInboxIcon />
          </IconButton>
          <IconButton onClick={handleDelete} disabled={!selectedEmail} sx={{ ml: 2 }}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Divider />

        {/* ????????? ???????? */}
        {loading && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, ml: 2 }}>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            <Typography variant="body2">Loading emails...</Typography>
          </Box>
        )}

        {/* ??? ??????? */}
        <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden', mt: 1 }}>
          {/* ?????? ????? */}
          <Box
            sx={{
              width: 400,
              minWidth: 400,
              borderRight: '1px solid #ccc',
              mr: 1,
              overflowY: 'auto',
              pr: 1
            }}
          >
            <EmailListContainer
              emails={emails}
              onSelectEmail={handleSelectEmail}
              selectedEmail={selectedEmail}
            />
          </Box>
          {/* ?????? ?????? */}
          <Box sx={{ flex: 1, overflowY: 'auto', pl: 1 }}>
            {selectedEmail ? (
              <EmailDetails
                email={selectedEmail}
                accountId={selectedAccount ? selectedAccount._id : null}
                onReply={handleOpenReply}
                onDelete={handleDelete}
                onRelocate={handleRelocate}
              />
            ) : (
              <Typography variant="body1" sx={{ m: 2 }}>
                Select an email to view
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>

      {/* ?????? "New message" */}
      {selectedAccount && openCompose && (
        <ComposeDialog
          open={openCompose}
          onClose={() => setOpenCompose(false)}
          onSuccess={(msg) => setSuccessMessage(msg)}
          mode="new"
          accountId={selectedAccount._id}
          defaultTo=""
        />
      )}
      {/* ?????? "Reply" */}
      {selectedAccount && openReply && (
        <ComposeDialog
          open={openReply}
          onClose={() => setOpenReply(false)}
          onSuccess={(msg) => setSuccessMessage(msg)}
          mode="reply"
          accountId={selectedAccount._id}
          defaultTo={replyTo}
          defaultSubject={replySubject}
        />
      )}
    </Box>
  );
}

export default MessagesTab;
