// src/components/email/EmailList.jsx
import React from 'react';
import {
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const EmailList = ({ emails, onSelectEmail, selectedEmail, formatDate }) => {


  return (
    <List sx={{ p: 0 }}>
      {emails.map((email, index) => {
        // ?????????? ????? ??? ?????? (unread) ??????
        console.log('Rendering email subject:', email.subject);
        const isSelected = selectedEmail && selectedEmail.seqno === email.seqno;
        const isNew = email.unread === true;
        const bgColor = isSelected
          ? '#ECECEC'
          : isNew
          ? '#8F4AFF33'
          : 'transparent';

        // ??? ??????????? (????????, "First Last name")
        let fromName = email.from || 'Unknown';
        if (fromName.includes('<')) {
          fromName = fromName.split('<')[0].trim();
        }
        const avatarLetter = fromName.charAt(0).toUpperCase() || '?';

        // ?????? "Description (text)" � ????? ?????? ?? email.text
        // ????? ?????????? ?? ????? (snippet), ????? ?? ???????????
        let description = (email.text || '').split('\n')[0];
        if (description.length > 60) {
          description = description.slice(0, 60) + '...';
        }

        // ???????? ?? ????????
        const hasAttachments = email.attachments && email.attachments.length > 0;

        return (
          <ListItemButton
            key={index}
            onClick={() => onSelectEmail(email)}
            selected={isSelected}
            sx={{
              backgroundColor: bgColor,
              py: 1.5,
              px: 1.5,
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            {/* ???????? ????? */}
            <ListItemAvatar>
              <Avatar>{avatarLetter}</Avatar>
            </ListItemAvatar>

            {/* ???????? ???? (???????????, ????, ????????) */}
            <Box sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
              {/* ??????????? ? ????? ? ????? ??????, ???? ?????? */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: isNew ? 'bold' : 'normal',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mr: 1
                  }}
                >
                  {fromName}
                </Typography>

                {/* ???? ? ????? ?????? (? ????? ??????) */}
                <Box sx={{ flexShrink: 0, ml: 'auto', display: 'flex', alignItems: 'center' }}>
                  {hasAttachments && (
                    <AttachFileIcon sx={{ fontSize: 16, mr: 1, opacity: 0.7 }} />
                  )}
                  <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                    {email.date ? formatDate(email.date) : 'Time'}
                  </Typography>
                </Box>
              </Box>

              {/* ???? ?????? */}
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: isNew ? 'bold' : 'normal',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {email.subject || 'No subject'}
              </Typography>

              {/* ??????? ???????? (snippet) */}
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {description}
              </Typography>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default EmailList;
