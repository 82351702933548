import React from 'react';
import { Box, 
Typography, 
useMediaQuery, 
Accordion,
AccordionSummary,
AccordionDetails } from '@mui/material';
import { keyframes } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { servicesData } from './servicesData';
// ?????? ?????? ??? ??????? ?????????? "Digital-marketing"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SmsIcon from '@mui/icons-material/Sms';
import CampaignIcon from '@mui/icons-material/Campaign';
import FacebookIcon from '@mui/icons-material/Facebook';
import PublicIcon from '@mui/icons-material/Public';

import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// ?????? ?????????????? ?????? ??? ??????????? "Web & UX/UI & Graphic design" ? "SEO Optimization"
import PaletteIcon from '@mui/icons-material/Palette';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';
import ParsingIcon from '@mui/icons-material/MoveDown';
import { Helmet } from 'react-helmet-async';
// 1) ???????? ????????? ??? ????????
const logoPulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.15); }
`;

// 2) ???????? �????????�
const floatAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

// 3) ???????? �?????� ??? ????????? ???????
const waveAnimation = keyframes`
  0% { height: 50%; }
  25% { height: 70%; }
  50% { height: 90%; }
  75% { height: 60%; }
  100% { height: 50%; }
`;

// ?????? ??? ????????? (??????)
const chartBars = [
  { color: '#36d399', animationDelay: '0s' },
  { color: '#ffb100', animationDelay: '0.5s' },
  { color: '#ff58d9', animationDelay: '1s' }
];

// ?????? ????? ??? ?????????? "Digital-marketing"
const digitalMarketingServices = [
  { title: 'Email campaigns', icon: <MailOutlineIcon sx={{ fontSize: 25, color: '#3E00FA' }} /> },
  { title: 'Parsing contacts', icon: <ParsingIcon sx={{ fontSize: 25, color: '#B900AC' }} /> },
  { title: 'Google ads', icon: <CampaignIcon sx={{ fontSize: 25, color: '#009851' }} /> },
  { title: 'Facebook ads', icon: <CampaignIcon sx={{ fontSize: 25, color: '#0A0094' }} /> },
  { title: 'VK ads', icon: <CampaignIcon sx={{ fontSize: 25, color: '#006CC3' }} /> },
  { title: 'SMM marketing', icon: <PublicIcon sx={{ fontSize: 25, color: '#FF6C17' }} /> }
];

// ?????? ????? ??? ?????????? "Web & UX/UI & Graphic design"
const designServices = [
  { title: 'Graphic design', icon: <PaletteIcon sx={{ fontSize: 25, color: '#FF4081' }} /> },
  { title: 'UX/UI design', icon: <DesignServicesIcon sx={{ fontSize: 25, color: '#3F51B5' }} /> },
  { title: 'Web design', icon: <LanguageIcon sx={{ fontSize: 25, color: '#4CAF50' }} /> }
];

// ?????? ????? ??? ?????????? "SEO Optimization"
const seoServices = [
  { title: 'SEO optimization', icon: <SearchIcon sx={{ fontSize: 25, color: '#FFC107' }} /> }
];

function Home() {
  const [searchParams] = useSearchParams();
  const [successMessage, setSuccessMessage] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');
useEffect(() => {
    if (searchParams.get('verified') === '1') {
      setSuccessMessage('Email confirmed successfully!');
    }
  }, [searchParams]);
  return (
  <>
      <Helmet>
        <title>Next-generation marketing | Mailsball</title>
        <meta
          name="description"
          content="Comprehensive business growth: Email and SMS campaigns, targeted advertising, SMM, graphic and UX/UI design, SEO, and sales funnel strategy."
        />
        <meta
          name="keywords"
          content="email campaigns, SMS campaigns, digital marketing, SEO optimization, graphic design, UX/UI design, advertising"
        />
        <link rel="canonical" href="https://mailsball.com" />
        <meta property="og:title" content="Next-generation marketing | Mailsball" />
        <meta
          property="og:description"
          content="Comprehensive business growth: Email and SMS campaigns, targeted advertising, SMM, graphic and UX/UI design, SEO, and sales funnel strategy."
        />
        <meta property="og:url" content="https://mailsball.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://mailsball.com/images/Loogoo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Next-generation marketing | Mailsball" />
        <meta
          name="twitter:description"
          content="Comprehensive business growth: Email and SMS campaigns, targeted advertising, SMM, graphic and UX/UI design, SEO, and sales funnel strategy."
        />
        <meta name="twitter:image" content="https://mailsball.com/images/Loogoo.png" />
      </Helmet>
    <Box sx={{ overflowX: 'hidden' }}>
      {/* ?????? ? ?????????? */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          background: 'linear-gradient(135deg, #2b014a, #300259, #340270)'
        }}
      >
        {/* ????????? ????? */}
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: '15%',
            width: '120px',
            height: '120px',
            backgroundColor: 'rgba(255,255,255,0.2)',
            borderRadius: '50%',
            animation: `${floatAnimation} 4s infinite ease-in-out`
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '45%',
            left: '70%',
            width: '80px',
            height: '80px',
            backgroundColor: 'rgba(255,255,255,0.15)',
            borderRadius: '50%',
            animation: `${floatAnimation} 5s infinite ease-in-out`,
            animationDelay: '1s'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '5%',
            left: '40%',
            width: '100px',
            height: '100px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '50%',
            animation: `${floatAnimation} 6s infinite ease-in-out`,
            animationDelay: '2s'
          }}
        />

        {/* ??????? ??????? */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: '#fff',
            textAlign: 'center',
            px: 2
          }}
        >
          <Box
            component="img"
            src="/images/logo2.svg"
            alt="Mailsball Logo2"
            sx={{
              width: 80,
              mb: 2,
              animation: `${logoPulse} 4s infinite ease-in-out`
            }}
          />
           {/* ????????? H1 ??? SEO */}
        <Typography
  variant="h1"
  sx={{
    fontWeight: 'bold',
    mb: 2,
    fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' }  // ?????? ?????????? ????????
  }}
>
  Next-generation marketing
</Typography>
          <Typography
            variant="h5"
            sx={{
              maxWidth: 600,
              mb: 4,
              fontSize: isMobile ? '1rem' : '1.25rem',
              fontWeight: 400,  // regular
              fontFamily: '"Hind", sans-serif'
            }}
          >
            Comprehensive business growth: Email and SMS campaigns, targeted advertising,
            SMM, graphic and UX/UI design, SEO, and sales funnel strategy.
          </Typography>

          {/* ??????? ? �??????????� */}
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              height: isMobile ? '120px' : '180px',
              width: isMobile ? '90%' : '600px',
              backgroundColor: 'rgba(255,255,255,0.1)',
              borderRadius: '8px',
              overflow: 'hidden',
              p: 2
            }}
          >
            {chartBars.map((bar, idx) => (
              <Box
                key={idx}
                sx={{
                  width: `${100 / chartBars.length}%`,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '30px',
                    height: '100%',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      backgroundColor: bar.color,
                      borderRadius: '4px 4px 0 0',
                      animation: `${waveAnimation} 4s infinite ease-in-out`,
                      animationDelay: bar.animationDelay,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end'
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* ???? "Our services" */}
<Box sx={{ pt: 8, pb: 2, px: { xs: 4, md: 4 }, backgroundColor: '#f7f7f7' }}>
  <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 4 }}>
    Our services
  </Typography>

  {/* ??????? "Digital-marketing" */}
  <Box sx={{ mb: 4 }}>
    <Typography
      variant="h5"
      align="left"
      sx={{
        maxWidth: 1200,
        mx: 'auto',
        fontWeight: 500,
        mb: 2,
        fontFamily: '"Hind", sans-serif'
      }}
    >
      Digital-marketing
    </Typography>
    
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mx: 'auto',
        py: 2
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: { xs: 3, md: 4 },
          gridTemplateColumns: {
            xs: 'repeat(2, 150px)',
            md: digitalMarketingServices.length < 10
              ? `repeat(${digitalMarketingServices.length}, 120px)`
              : 'repeat(10, 120px)'
          },
          width: 'fit-content'
        }}
      >
        {digitalMarketingServices.map((service, idx) => (
          <Box
            key={idx}
            sx={{
              background: '#fff',
              borderRadius: 2,
              p: 1,
              width: 120,
              height: 120,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 1,
              transition: 'transform 0.3s',
              '&:hover': { transform: 'translateY(-4px)' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 25
              }}
            >
              {service.icon}
            </Box>
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                textAlign: 'center',
                fontSize: '0.75rem',
                fontWeight: 500,
                fontFamily: '"Hind", sans-serif'
              }}
            >
              {service.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
    
  {/* ???? ??????????? ??? 6 ????? */}
<Box 
  sx={{ 
    maxWidth: 1200, 
    mx: 'auto', 
    px: { xs: 2, md: 4 }, 
    py: 4 
  }}
>
  <Typography variant="h5" sx={{ mb: 3, fontWeight: 400 }}>
    Detailed Services
  </Typography>

  {servicesData.map((service, index) => (
    <Accordion key={index} sx={{ mb: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Typography sx={{ fontWeight: 400 }}>
          {service.title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {/* 1. What it is */}
        <Typography variant="body2" sx={{ color: '#444', mb: 2 }}>
          <strong>What it is:</strong> {service.whatIsIt}
        </Typography>

        {/* 2. Why you need it */}
        <Typography variant="body2" sx={{ color: '#444', mb: 1 }}>
          <strong>Why you need it:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          {service.whyYouNeedIt.map((item, i) => (
            <li key={i}>
              <Typography variant="body2" sx={{ color: '#444' }}>
                {item}
              </Typography>
            </li>
          ))}
        </Box>

        {/* 3. Key points */}
        <Typography variant="body2" sx={{ color: '#444', mb: 1 }}>
          <strong>Key points:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4 }}>
          {service.keyPoints.map((point, i) => (
            <li key={i}>
              <Typography variant="body2" sx={{ color: '#444' }}>
                {point}
              </Typography>
            </li>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  ))}
</Box>
    
  </Box>


    </Box>
    
{/* ????? ?????? ? ????? ?????????? ? ???????????? */}
<Box sx={{pb: { xs: 0, sm: 4 }, backgroundColor: '#f7f7f7' }}>
<Box
  sx={{
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
    alignItems: 'center',       // ?????????? ???????? ?? ?????????
    justifyItems: 'center',     // ?????????? ???????? ?? ???????????
    gap: 4,
    py: 4,
    px: { md: 4 },
    backgroundColor: '#1a1a1a',
    width: '100%',
    maxWidth: { xs: '100%', md: '1200px' }, // ?? ????????? ?? ??? ??????
    height: { xs: 'auto', md: '450px' },
    mx: 'auto',               // ?????????? ??? ???? ?? ??????????? ? ???????????? ??????????
    boxShadow: 3
  }}
>
  {/* ????? ?????: ??????? */}
  <Box sx={{ color: '#fff', textAlign: 'center', px: 4 }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
      Email campaigns
    </Typography>
    <Typography
      variant="subtitle1"
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mb: 3,
        fontWeight: 300,
        lineHeight: 1.5,
        fontSize: '0.95rem'
      }}
    >
      We offer a professional email marketing service with the flexibility
      to send messages to existing contact databases within our system or to client-provided databases.
    </Typography>

    {/* ???????? */}
    <Box
      sx={{
        display: 'flex',
        overflowX: 'hidden', // ?????????????? ?????????????? ?? ?????????
        gap: 2,
        mb: 3,
        justifyContent: { xs: 'center', md: 'center' } // ?? ????????? ? ??????, ?? ????????? ?? ??????
      }}
    >
      {/* ???????? Email campaigns */}
      <Box
        sx={{
          background: '#fff',
          borderRadius: 2,
          p: 2,
          minWidth: 120, // ??????????? ?????? ??? ??????????????? ???????
          height: 120,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 1,
          transition: 'transform 0.3s',
          '&:hover': { transform: 'translateY(-4px)' }
        }}
      >
        <MailOutlineIcon sx={{ fontSize: 30, color: '#3E00FA' }} />
        <Typography sx={{ mt: 1, fontSize: '0.9rem', fontWeight: 500, color: '#000' }}>
          Email campaigns
        </Typography>
      </Box>
    </Box>

    {/* ?????? "send an application" */}
<Box
  component="button"
  onClick={() => window.location.href = "https://mailsball.com/requestform"}
  sx={{
    backgroundColor: 'transparent', // ?????????? ???
    color: '#ffc800', // ???? ??????
    px: 4, // ?????????????? ???????
    py: 1.5, // ???????????? ???????
    border: '1px solid #ffc800', // ?????
    borderRadius: '3px', // ?????????? ?????
    fontSize: '1rem', // ?????? ??????
    fontWeight: 600, // ????????
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffc800', // ??? ????????? ???????? ???
      color: '#fff' // ???????? ???? ??????
    },
    mx: 'auto',
    display: 'block',
    transition: 'all 0.3s ease' // ??????? ???????
  }}
>
  send an application
</Box>
  </Box>

  {/* ?????? ?????: ???????????, ?????????? ?? ????????? */}
  <Box
    component="img"
    src="/images/banner2.webp"
    alt="Marketing Banner"
    sx={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      maxWidth: '550px',
      maxHeight: '400px',
      borderRadius: '5px',
      mx: 'auto',
      display: { xs: 'none', md: 'block' } // ???????? ???????? ?? ?????????
    }}
  />
</Box>
      </Box>
      </Box> 
      </>
  );
}

export default Home;
