// src/layouts/PublicLayout.js
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Outlet } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import { getMenuItems } from '../menuItems';
import NavMenuItem from '../components/NavMenuItem';
import Footer from '../components/Footer';
import ProfileMenu from '../components/ProfileMenu';
import NotificationBell from '../components/NotificationBell';
import axios from 'axios';

function PublicLayout({ authenticated, user, handleLogout }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const menuItems = getMenuItems(authenticated);

  const toggleDrawer = (open) => () => {
    setMobileOpen(open);
  };

  const pricingButton = (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: '#ffc800',
        textTransform: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(204, 133, 0, 0.08)'
        }
      }}
    >
      Pricing
    </Button>
  );

  const authButton = authenticated ? (
    <Button
      onClick={handleLogout}
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Logout
    </Button>
  ) : (
    <Button
      component={Link}
      to="/login"
      sx={{
        color: '#fff',
        textTransform: 'none',
        border: '1px solid #fff',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      Login
    </Button>
  );

  // ??????? ??? ????????? ???????????, ???? ???????????? ????????????????
  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/api/notifications', { withCredentials: true });
      setNotifications(response.data);
    } catch (err) {
      console.error('Failed to fetch notifications:', err);
    }
  };

  // ???????? fetchNotifications ??? ???????????? ??????????, ???? ???????????? ????????????????
  useEffect(() => {
    if (authenticated) {
      fetchNotifications();
    }
  }, [authenticated]);

  // ??????? ??? ??????? ??????????? ??? ????????????
  const markNotificationAsRead = async (notificationId) => {
    try {
      const res = await axios.patch(
        '/api/notifications/read',
        { notificationId },
        { withCredentials: true }
      );
      if (res.data.success) {
        setNotifications(prev =>
          prev.map(n =>
            n._id === notificationId ? { ...n, isRead: true } : n
          )
        );
      }
    } catch (err) {
      console.error('Failed to mark notification as read:', err);
    }
  };

  return (
    <>
      {/* Header */}
      <AppBar
        position="absolute"
        sx={{
          width: '100%',
          backgroundColor: '#300259',
          boxShadow: 'none',
          zIndex: 10
        }}
      >
        <Toolbar>
          <Link
            to="/"
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <img
              src="/images/logo.svg"
              alt="Mailsball Logo"
              style={{ height: '17px', marginRight: '10px' }}
            />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {!isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => (
                <NavMenuItem key={item.text} item={item} />
              ))}
              {pricingButton}
              {authenticated ? (
                [
                  <NotificationBell
                    key="notification-bell"
                    notifications={notifications}
                    onMarkAsRead={markNotificationAsRead}
                    user={user}
                    handleLogout={handleLogout}
                  />,
                  <ProfileMenu key="profile-menu" user={user} handleLogout={handleLogout} />
                ]
              ) : (
                authButton
              )}
            </Box>
          ) : (
            <IconButton sx={{ color: '#fff', ml: 1 }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <SidebarMenu
        menuItems={menuItems}
        mobileOpen={mobileOpen}
        toggleDrawer={toggleDrawer}
        isAuthenticated={authenticated}
        handleLogout={handleLogout}
      />

      {/* ???????? ??????? */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          pt: 10, // ?????? ??? AppBar
        }}
      >
        {/* ??????? ???????? */}
        <Box sx={{ flex: '1 0 auto', p: 2 }}>
          <Outlet context={{ authenticated, user, handleLogout, notifications, markNotificationAsRead }} />
        </Box>

        {/* Footer */}
        <Box sx={{ flexShrink: 0 }}>
          <Footer authenticated={authenticated} />
        </Box>
      </Box>
    </>
  );
}

export default PublicLayout;