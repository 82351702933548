// src/components/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  TableContainer,
  Select,
  MenuItem
} from '@mui/material';
import axios from 'axios';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // ??? ??????? ????? ??????
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // ??? ??????? ????? ????
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  // ???????? ???? ?????????????
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/admin/users'); // <-- ?????? ?? ??????
      setUsers(res.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      alert('Failed to fetch users.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // ======== ????? ?????? ========
  const openPasswordDialog = (user) => {
  const { password, ...rest } = user;
    setSelectedUser(user);
    setNewPassword('');
    setConfirmPassword('');
    setPasswordDialogOpen(true);
  };

  const closePasswordDialog = () => {
    setPasswordDialogOpen(false);
    setSelectedUser(null);
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }
    try {
      await axios.put(`/api/admin/users/${selectedUser._id}/password`, {
        newPassword
      });
      alert('Password changed successfully.');
      closePasswordDialog();
      fetchUsers();
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Failed to change password.');
    }
  };

  // ======== ????? ???? ========
  const openRoleDialog = (user) => {
    setSelectedUser(user);
    setSelectedRole(user.role); // ?? ????????? ?????? ??????? ????
    setRoleDialogOpen(true);
  };

  const closeRoleDialog = () => {
    setRoleDialogOpen(false);
    setSelectedUser(null);
  };

  const handleChangeRole = async () => {
    if (!selectedRole) {
      alert('Please select a role');
      return;
    }
    try {
      await axios.put(`/api/admin/users/${selectedUser._id}/role`, {
        role: selectedRole
      });
      alert('Role changed successfully.');
      closeRoleDialog();
      fetchUsers();
    } catch (error) {
      console.error('Error changing role:', error);
      alert('Failed to change role.');
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard (User Management)
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Login</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.login}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    {new Date(user.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="outlined"
                      onClick={() => openPasswordDialog(user)}
                    >
                      Change Password
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => openRoleDialog(user)}
                    >
                      Change Role
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* ?????? ??? ????? ?????? */}
      <Dialog open={passwordDialogOpen} onClose={closePasswordDialog}>
        <DialogTitle>Change Password for {selectedUser?.login}</DialogTitle>
        <DialogContent>
          <TextField
  label="New Password"
  type="password"
  autoComplete="new-password"
  fullWidth
  margin="normal"
  value={newPassword}
  onChange={(e) => setNewPassword(e.target.value)}
/>
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePasswordDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleChangePassword}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* ?????? ??? ????? ???? */}
      <Dialog open={roleDialogOpen} onClose={closeRoleDialog}>
        <DialogTitle>Change Role for {selectedUser?.login}</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            sx={{ mt: 2 }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="manager">Manager</MenuItem>
            <MenuItem value="support">Support</MenuItem>
            {/* ???? ????? ???????? ??????, ?????????? ???? */}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRoleDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleChangeRole}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDashboard;
