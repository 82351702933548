// src/components/Register.js
import React, { useState } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Register = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  // ????? ?????? ??? ???????? ????????????
  const [loginError, setLoginError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };


// ???????? ???????????? ?????? ??? ????? ? ???? (onBlur)
  const checkLoginUnique = async () => {
    if (!login) return;
    try {
      const res = await axios.get(`/api/auth/check-duplicate-login?login=${encodeURIComponent(login)}`);
      if (res.data.exists) {
        setLoginError("A user with this login already exists.");
      } else {
        setLoginError("");
      }
    } catch (error) {
      console.error("Error checking login uniqueness:", error);
    }
  };

  // ???????? ???????????? email ??? ????? ? ???? (onBlur)
  const checkEmailUnique = async () => {
    if (!email) return;
    try {
      const res = await axios.get(`/api/auth/check-duplicate-email?email=${encodeURIComponent(email)}`);
      if (res.data.exists) {
        setEmailError("A user with this email already exists.");
      } else {
        setEmailError("");
      }
    } catch (error) {
      console.error("Error checking email uniqueness:", error);
    }
  };
  
  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (!executeRecaptcha) {
      setErrorMessage("Recaptcha not yet available");
      return;
    }
    // ????????? reCAPTCHA v3 ? ???????? ????? ??? ???????? "register"
    const token = await executeRecaptcha("register");
    setCaptchaValue(token);
    if (!token) {
      setErrorMessage("Failed to get recaptcha token");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post('/api/auth/register', {
        login,
        email,
        password,
        captcha: token,
      });
      setSuccessMessage("Registration successful! Please check your email to verify your account.");
      // ????? ???????? ??????????? ?????????????? ?? ???????? ??????
      navigate('/login');
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '60px', // ?????? ?????? 60px
        px: 2, // ??????? ?? ?????
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: { xs: '90%', sm: '400px' },
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Register
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Login"
            variant="outlined"
            fullWidth
            margin="normal"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            onBlur={checkLoginUnique}
            error={!!loginError}
            helperText={loginError}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={checkEmailUnique}
            error={!!emailError}
            helperText={emailError}
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* reCAPTCHA v3 ?? ???????? ??????? ??????? */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
          </Button>
        </form>
        <Button onClick={() => navigate('/login')} fullWidth sx={{ mt: 1 }} disabled>
          Login
        </Button>
        <Button onClick={() => navigate('/forgot-password')} fullWidth sx={{ mt: 1 }} disabled>
          Forgot Password
        </Button>
      </Paper>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Register;
