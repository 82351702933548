// src/components/ProfileMenu.js

import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Иконки MUI (пример — подберите по желанию)
import WorkspacesIcon from '@mui/icons-material/Workspaces';        // для Workflow
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'; // для Messages
import ApiIcon from '@mui/icons-material/Api';                      // для API
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';      // для Billing
import SettingsIcon from '@mui/icons-material/Settings';            // для Settings
import LogoutIcon from '@mui/icons-material/Logout';                // для Logout
import StarIcon from '@mui/icons-material/Star';

const ProfileMenu = ({ user, handleLogout }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Если данные о пользователе ещё не загрузились
  if (!user) {
    return <CircularProgress size={24} color="inherit" />;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Список пунктов меню с иконками
  const menuItems = [
    { text: 'Workflow', path: '/dashboard', Icon: WorkspacesIcon },
    { text: 'Messages', path: '/messages', Icon: ChatBubbleOutlineIcon },
    { text: 'API', path: '/api-settings', Icon: ApiIcon },
    { text: 'Billing', path: '/billing', Icon: ReceiptLongIcon, color: 'orange' },
    { text: 'My profile', path: '/myprofile', Icon: SettingsIcon },
  ];
  
  // === ДОБАВЛЯЕМ УСЛОВИЕ ===
if (user.role === 'admin') {
  menuItems.push({
    text: 'Management',
    path: '/admin',
    Icon: WorkspacesIcon // или любая другая иконка на твой вкус
  });
}

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* Кнопка с аватаром и именем пользователя */}
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 2,
          color: '#fff',
          padding: '5px 10px',
        }}
      >
        <Avatar src={user.avatarUrl || '/default-avatar.png'} />
        <Box sx={{ ml: 1, textAlign: 'left',  justifyContent: 'center' }}>
          <Typography variant="body2" fontWeight="bold" sx={{ lineHeight: '0.5', mt: 1 }}>
            {user.login}
          </Typography>
          <Typography variant="caption" sx={{ lineHeight: '0.5', opacity: 0.8 }}>
            {user.email}
          </Typography>
        </Box>
      </IconButton>

  {/* Меню профиля */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        
        {/* Пункт с информацией о плане: только если роль не admin */}
        {user.role !== 'admin' && (
          <MenuItem disabled divider sx={{ pointerEvents: 'none' }}>
            <ListItemIcon>
              <StarIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`Plan: ${user.planName || 'Basic'}`}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            />
          </MenuItem>
        )}

        {/* Пункты меню из массива menuItems */}
        {menuItems.map((item) => (
          <MenuItem
            key={item.text}
            onClick={() => {
              handleClose();
              navigate(item.path);
            }}
          >
            <ListItemIcon sx={{ color: item.color || 'inherit' }}>
              <item.Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: { color: item.color || 'inherit' },
              }}
            />
          </MenuItem>
        ))}

        {/* Отдельный пункт для Logout (выход) */}
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon sx={{ color: 'error.main' }}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ sx: { color: 'error.main' } }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
