//Requests.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  OutlinedInput,
  MenuItem as MuiMenuItem,
  ListItemText,
  Snackbar,
  Alert,
  TableFooter,
  TablePagination,
  Avatar
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';
import { getNames, getCode } from 'country-list';
import currencyCodes from 'currency-codes';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import { getIconByMimeType } from '../iconMap';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import PaymentIcon from '@mui/icons-material/Payment';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { jsPDF } from "jspdf";
import Collapse from '@mui/material/Collapse';

// ????????? ??? ???????
const countriesList = getNames();
const currencies = currencyCodes.codes();
const services = [
  "Email Campaigns",
  "SMS Campaigns",
  "Google Ads",
  "Facebook Ads",
  "VK Ads",
  "SMM marketing"
];
const objectives = [
  "Increase sales", "Attract new customers", "Increase brand awareness",
  "Drive more website traffic", "Generate leads", "Promote a new product or service",
  "Engage the audience", "Promote special offers or discounts",
  "Grow subscribers", "Improve customer retention", "Collect feedback",
  "Improve conversion rates", "Retargeting"
];
const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];
const genders = ["Male", "Female", "All"];
const contactDatabases = ["I have my own database", "Use the agency database"];

// ????????? ????????? ????? ?????????????? ???????
const emptyFormData = {
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  projectName: '',
  chosenService: '',
  status: '',
  serviceSelection: '',
  campaignObjective: '',
  countries: [],
  cities: [],
  ageRange: [],
  gender: '',
  keywords: '',
  approximateBudget: '',
  currency: '',
  targetAudienceDescription: '',
  contactDatabase: '',
  additionalMessage: '',
  graphicService: '',
  graphicDescription: '',
  graphicWebsite1: '',
  graphicWebsite2: '',
  graphicWebsite3: '',
  graphicFiles: [],
  seoWebpage: '',
  seoDescription: '',
  privacyPolicyAgreed: false,
  price: '' // ????? ???? ??? ????
};

// ????? ??? ?????? ??????? ? ????????
const statusOptions = [
  { value: 'New', label: 'New', icon: <NewReleasesIcon fontSize="small" /> },
  { value: 'Pending', label: 'Pending', icon: <NewReleasesIcon fontSize="small" /> },
  { value: 'Communication', label: 'Communication', icon: <ChatIcon fontSize="small" /> },
  { value: 'Pending payment', label: 'Pending payment', icon: <PaymentIcon fontSize="small" /> },
  { value: 'In processing', label: 'In processing', icon: <SyncIcon fontSize="small" /> },
  { value: 'Closed', label: 'Closed', icon: <CheckCircleIcon fontSize="small" /> },
];

export default function Requests() {
  const { requests, setRequests, user, updateUser } = useOutletContext();
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    chosenService: '',
    status: '',
    projectName: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [closeProjectDialogOpen, setCloseProjectDialogOpen] = useState(false);
  const [sendMailDialogOpen, setSendMailDialogOpen] = useState(false);
  const [formData, setFormData] = useState(emptyFormData);
  const [allCities, setAllCities] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showHistory, setShowHistory] = useState(false);
const [updatedRequests, setUpdatedRequests] = useState(new Set());
  // ????????? ??? ??????? ???????? ??????
  const [mailRecipient, setMailRecipient] = useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('personal');
  const [unreadRequests, setUnreadRequests] = useState(new Set());

 // ?????????? ??????? ?? ????????? ??????? (??? ????)
  const selectedCountryCodes = formData.countries.map(name => getCode(name)).filter(Boolean);
  const filteredCities = selectedCountryCodes.length > 0 ? allCities.filter(city => selectedCountryCodes.includes(city.country)) : [];

  useEffect(() => {
    fetch('/api/requests')
      .then(res => res.json())
      .then(data => {
        console.log('Fetched requests:', data);
        setRequests(data);
      })
      .catch(err => {
        console.error('Failed to fetch requests:', err);
        setErrorMessage('Failed to fetch requests.');
      });
  }, []);
  
  

  useEffect(() => {
    fetch('/api/cities')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
        return response.json();
      })
      .then(data => setAllCities(data))
      .catch(err => {
        console.error('Failed to load city data:', err);
        setErrorMessage('Failed to load city data.');
      });
  }, []);


  function applyFilters(list, f) {
    return list.filter(item => {
      if (f.firstName && !item.firstName?.toLowerCase().includes(f.firstName.toLowerCase())) return false;
      if (f.lastName && !item.lastName?.toLowerCase().includes(f.lastName.toLowerCase())) return false;
      if (f.companyName && !item.companyName?.toLowerCase().includes(f.companyName.toLowerCase())) return false;
      if (f.phoneNumber && !item.phoneNumber?.toLowerCase().includes(f.phoneNumber.toLowerCase())) return false;
      if (f.email && !item.email?.toLowerCase().includes(f.email.toLowerCase())) return false;
      if (f.chosenService && !item.chosenService?.toLowerCase().includes(f.chosenService.toLowerCase())) return false;
      if (f.status && !item.status?.toLowerCase().includes(f.status.toLowerCase())) return false;
      if (f.projectName && !item.projectName?.toLowerCase().includes(f.projectName.toLowerCase())) return false;
      return true;
    });
  }
  const filteredRequests = applyFilters(requests, filters);
  const totalFiltered = filteredRequests.length;
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageItems = filteredRequests.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
  };

  function getUniqueValues(field) {
    return Array.from(new Set(requests.map(r => r[field]).filter(Boolean)));
  }

  // --- ???? ---
  function handleMenuOpen(e, req) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedRequest(req);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  // --- ???????? ---
  function handleDeleteConfirmOpen() {
    setDeleteDialogOpen(true);
  }
  function handleDeleteConfirmClose() {
    setDeleteDialogOpen(false);
  }
  async function handleDeleteRequest() {
    if (!selectedRequest) return;
    const requestId = selectedRequest.id || selectedRequest._id;
    try {
      const res = await fetch(`/api/requests/${requestId}`, { method: 'DELETE' });
      if (!res.ok) throw new Error(`Delete error: ${res.status}`);
      setRequests(prev => prev.filter(r => (r.id || r._id) !== requestId));
      setSuccessMessage('Request deleted successfully.');
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to delete request.');
    } finally {
      setDeleteDialogOpen(false);
      setSelectedRequest(null);
      setAnchorEl(null);
    }
  }
  
    // ?????????? ?????? ???? ??????? (????????, ??????, ????, ??????)
  async function updateRequestField(reqItem, updateObj, updatedRequest) {
    const requestId = reqItem.id || reqItem._id;
    try {
      const response = await fetch(`/api/requests/${requestId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateObj)
      });
      if (!response.ok) throw new Error(`HTTP error ${response.status}`);
      const updatedReq = await response.json();
      
      updatedReq.updated = true;
       setUpdatedRequests(prev => new Set([...prev, requestId]));
    setRequests(prev =>
      prev.map(r => (String(r.id || r._id) === requestId ? updatedReq : r))
    );
     
      setSuccessMessage('Request updated successfully.');
    } catch (err) {
      console.error('Update error:', err);
      setErrorMessage('Failed to update request.');
    }
    
  }

  // --- ?????? (View/Edit) ---
function handleDetailOpen(req) {
  const requestToEdit = req || selectedRequest;
  if (!requestToEdit) return;

  // ???? ?????? ??? ?? ??????????? ??????? ????????????? � ????????? ?? ????????
  if (user && !requestToEdit.viewedBy?.includes(user._id)) {
    const updatedRequest = {
      ...requestToEdit,
      viewedBy: [...(requestToEdit.viewedBy || []), user._id],
    };
    setRequests(prev => prev.map(r => r._id === updatedRequest._id ? updatedRequest : r));
  }

  setSelectedRequest(requestToEdit);
  setFormData(transformForEdit(requestToEdit));
if (
  requestToEdit.status === 'New' &&
  (user?.role === 'admin' || user?.role === 'manager')
) {
  updateRequestField(requestToEdit, { status: 'Pending' });
  setFormData(prev => ({ ...prev, status: 'Pending' }));
  setRequests(prev =>
    prev.map(r =>
      r._id === requestToEdit._id ? { ...r, status: 'Pending' } : r
    )
  );
}
  // ?????????? ?????? ?? ?????? ??? ??????? ??? "?????????????"
  fetch(`/api/requests/${requestToEdit.id}/view`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' }
  })
    .catch((err) => {
      console.error('Error marking request as viewed:', err);
    });

  setDetailDialogOpen(true);
  setAnchorEl(null);
}
  function handleDetailClose() {
    setDetailDialogOpen(false);
    setFormData(emptyFormData);
  }

async function handlePaymentSuccess(requestId) {
  // ???????, ??????? ????? ??????? ????? ???????? ??????
  // (???? ?????????? ? ????? ??????????? Webhook ?? ????)

  try {
    await updateRequestField({ id: requestId }, { status: 'In processing', paymentStatus: 'Paid' });
    // ? ??????, ????????, ??????????? ?????????
    setSuccessMessage('????? ???????. ?????? -> In processing.');
  } catch (err) {
    console.error('Failed to set In processing:', err);
  }
}

  // ????????? ??????? ????? ?????????????? ???????
async function handleUpdateSubmit(e) {
  e?.preventDefault();
  if (!selectedRequest) return;
  if (selectedRequest.status === 'Closed') {
    setErrorMessage('Closed requests cannot be modified');
    return;
  }
  
  const requestId = selectedRequest.id || selectedRequest._id;
  const priceNum = Number(formData.price) || 0;
  let newStatus = formData.status;
  
   if (
    priceNum > 0 &&
    !['Closed', 'In processing', 'Pending payment'].includes(newStatus)
  ) {
    newStatus = 'Pending payment';
  }
  // ???? ???? ???????? ? 0 ? ??????? ?????? "Pending payment" -> "Communication"
  else if (priceNum === 0 && newStatus === 'Pending payment') {
    newStatus = 'Communication';
  }
  const updatedFormData = {
    ...formData,
    price: priceNum,
    status: newStatus
  };

  try {
    const hasNewFiles = updatedFormData.graphicFiles.some(f => f.file);
    let response;
    if (hasNewFiles) {
      const formDataToSend = new FormData();
      // ?????????? updatedFormData, ? ?? formData
      for (let key in updatedFormData) {
        if (key === 'graphicFiles') continue;
        formDataToSend.append(key, updatedFormData[key]);
      }
      const existingFiles = updatedFormData.graphicFiles.filter(file => !file.file);
      formDataToSend.append('existingGraphicFiles', JSON.stringify(existingFiles));
      updatedFormData.graphicFiles.forEach(fileObj => {
        if (fileObj.file) {
          formDataToSend.append('graphicFiles', fileObj.file, fileObj.name);
        }
      });
      response = await fetch(`/api/requests/${requestId}`, {
        method: 'PUT',
        body: formDataToSend
      });
    } else {
      const payload = {
        ...updatedFormData,
        graphicFiles: updatedFormData.graphicFiles.map(file => ({
          path: file.path,
          originalName: file.originalName,
          mimeType: file.mimeType
        }))
      };
      response = await fetch(`/api/requests/${requestId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
    }
    if (!response.ok) throw new Error(`HTTP error ${response.status}`);
    const updatedReq = await response.json();
    setRequests(prev => prev.map(r => ((r.id || r._id) === requestId ? updatedReq : r)));
    setSuccessMessage('Request updated successfully.');
    setDetailDialogOpen(false);
    setFormData(emptyFormData);
  } catch (err) {
    console.error('Update error:', err);
    setErrorMessage('Failed to update request.');
  }
}


  // ??????????? ????????? ?????
  function handleChange(e) {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  }
  function handleMultiSelectChange(fieldName) {
    return (event) => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: event.target.value
      }));
    };
  }
  const handleRemoveFile = (index) => {
    setFormData(prev => {
      const updatedFiles = [...prev.graphicFiles];
      updatedFiles.splice(index, 1);
      return { ...prev, graphicFiles: updatedFiles };
    });
  };
  function handleAutocompleteChange(fieldName) {
    return (event, newValue) => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: newValue
      }));
    };
  }
  function handleAutocompleteChangeForCities(event, newValue) {
    const cityNames = newValue.map(cityObj => cityObj.name);
    setFormData(prev => ({
      ...prev,
      cities: cityNames
    }));
  }
  function handleFileChange(e) {
    const newFiles = Array.from(e.target.files).map(file => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      type: file.type
    }));
    setFormData(prev => ({
      ...prev,
      graphicFiles: [...prev.graphicFiles, ...newFiles]
    }));
  }

  // --- ???????? ?????? ---
  function handleSendMailOpen() {
    if (!selectedRequest) return;
    setMailRecipient(selectedRequest.email);
    const template = `
<div style="background-color: #d9d9d9; padding: 30px;">
  <div style="background-color: #ffffff; border-radius: 5px; max-width: 600px; margin: 0 auto; padding: 30px; font-family: Arial, sans-serif; color: #2e2e2e; line-height: 1.5;">
    <div style="text-align: center; margin-bottom: 30px;">
      <a href="https://mailsball.com" target="_blank">
        <img src="cid:logo1" style="max-width: 250px;" />
      </a>
    </div>
    <h2 style="text-align: center; color: #300259;">Payment Confirmation</h2>
    <p>Dear ${selectedRequest.firstName} ${selectedRequest.lastName},</p>
    <p>Thank you for your payment. We have successfully processed your transaction.</p>
    <h3>Payment Details:</h3>
    <ul>
      <li><strong>Invoice #:</strong> ${selectedRequest.invoiceNum || 'N/A'}</li>
      <li><strong>Payment Date:</strong> ${new Date().toLocaleDateString()}</li>
      <li><strong>Total Amount:</strong> \u20AC${Number(selectedRequest.price || 0).toFixed(2)}</li>
      <li><strong>Payment Method:</strong> ${selectedRequest.cardDisplay || 'N/A'}</li>
    </ul>
    <p>Please find attached your payment invoice for your records.</p>
    <hr style="border: none; border-top: 1px solid #ccc; margin: 20px 0;" />
    <p style="text-align: center; margin: 0; font-size: 14px;"><strong>Mailsball Team</strong></p>
    <p style="text-align: center; margin: 0; font-size: 14px;">Email: info@mailsball.com</p>
  </div>
</div>
    `;
    const notPaidOrClosed = !['Closed', 'In processing', 'Pending payment'].includes(
    selectedRequest.status
  );

  if (
    (user.role === 'admin' || user.role === 'manager') &&
    notPaidOrClosed &&
    (selectedRequest.status === 'New' || selectedRequest.status === 'Pending')
  ) {
    updateRequestField(selectedRequest, { status: 'Communication' });
    // ? ?????? ???? ??????????????:
    setSelectedRequest((prev) => ({ ...prev, status: 'Communication' }));
  }
    setMailSubject('Payment Confirmation');
    setMailBody(template);
    setSendMailDialogOpen(true);
  }
  function handleSendMailClose() {
    setSendMailDialogOpen(false);
  }
  async function handleSendMail() {
    try {
      const res = await axios.post(`/api/requests/${selectedRequest.id || selectedRequest._id}/sendmail`, {
        to: mailRecipient,
        subject: mailSubject,
        html: mailBody
      });
      alert('Email sent successfully!');
      setSendMailDialogOpen(false);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }

  // --- ???????? ??????? ---
  function handleCloseProjectOpen() {
    setCloseProjectDialogOpen(true);
  }
  function handleCloseProjectClose() {
    setCloseProjectDialogOpen(false);
  }
  async function handleConfirmCloseProject() {
    if (!selectedRequest) return;
    await updateRequestField(selectedRequest, { status: 'Closed' });
    setCloseProjectDialogOpen(false);
    if (detailDialogOpen) {
      setFormData(prev => ({ ...prev, status: 'Closed' }));
    }
  }

  // --- ?????? (Pay) ---
  async function handlePayRequest(reqItem) {
  try {
    const amountInCents = Number(reqItem.price) * 100;
    const response = await fetch('/api/request-payment/checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount: amountInCents,
        currency: 'eur',
        requestId: reqItem.id,
        firstName: reqItem.firstName,
        lastName: reqItem.lastName,
        email: reqItem.email,
        isCompanyPayment: selectedPaymentType === 'company',
      })
    });
    const { id: sessionId } = await response.json();
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) {
      console.error('Stripe redirect error:', error);
      alert('?????? ??? ???????? ?? ???????? ??????');
    }
  } catch (error) {
    console.error('Payment error:', error);
    alert('?????? ?? ?????????.');
  }
}
  

// ??????? ??? ?????? ????? ?????? (TTF) ? base64
async function loadFont(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// ??????? ??? ?????? ??????????? (PNG/JPG) ? base64
async function getBase64ImageFromUrl(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// ??????? ??? ???????? ??????????? ??? ??????? Image (??? ?????????)
function loadImage(base64Str) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = base64Str;
  });
}

// ??????? ?????
const fieldLabels = {
  firstName: "First Name",
  lastName: "Last Name",
  companyName: "Company Name",
  phoneNumber: "Phone Number",
  email: "Email Address",
  projectName: "Project Name",
  chosenService: "Chosen Service",
  status: "Status",
  serviceSelection: "Service Selection",
  campaignObjective: "Campaign Objective",
  countries: "Countries",
  cities: "Cities",
  ageRange: "Age Range",
  gender: "Gender",
  keywords: "Keywords",
  approximateBudget: "Approximate Budget",
  currency: "Currency",
  targetAudienceDescription: "Target Audience Description",
  contactDatabase: "Contact Database",
  additionalMessage: "Additional Message",
  graphicService: "Graphic Service",
  graphicDescription: "Graphic Description",
  graphicWebsite1: "Graphic Website 1",
  graphicWebsite2: "Graphic Website 2",
  graphicWebsite3: "Graphic Website 3",
  price: "Price"
};

// ??????? ???????? ???????
function isEmptyValue(v) {
  if (v === null || v === undefined) return true;
  if (Array.isArray(v) && v.length === 0) return true;
  if (typeof v === "string" && v.trim() === "") return true;
  return false;
}

async function handleDownloadProject() {
  if (!selectedRequest) return;

  const doc = new jsPDF();

  // 1. ????????? ????? DejaVuSans ??? ?????????
  const fontUrl = `${window.location.origin}/fonts/DejaVuSans.ttf`;
  const fontDataUrl = await loadFont(fontUrl);
  const fontBase64 = fontDataUrl.split(",")[1];
  doc.addFileToVFS("DejaVuSans.ttf", fontBase64);
  doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
  doc.setFont("DejaVuSans");

  // 2. ????????? ???????
  const logoUrl = `${window.location.origin}/images/logo1.png`;
  const logoBase64 = await getBase64ImageFromUrl(logoUrl);
  const logoImg = await loadImage(logoBase64);
  const ratio = logoImg.naturalWidth / logoImg.naturalHeight;
  const desiredWidth = 40;
  const desiredHeight = desiredWidth / ratio;
  doc.addImage(logoBase64, "PNG", 10, 10, desiredWidth, desiredHeight);

  // ????????????? ????????? ??????? ???? ????????
  let currentY = 10 + desiredHeight + 10;

  // ?????????
  doc.setFontSize(16);
  doc.text("Project Details", 10, currentY);
  currentY += 10;

  doc.setFontSize(12);

  // ??????? ????, ?????? ???? ??? ?? ??????
  for (const [field, label] of Object.entries(fieldLabels)) {
    const value = selectedRequest[field];
    if (isEmptyValue(value)) continue;
    let displayValue = Array.isArray(value) ? value.join(", ") : String(value);
    doc.text(`${label}: ${displayValue}`, 10, currentY);
    currentY += 10;
    if (currentY > doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      doc.setFont("DejaVuSans", "normal"); // ???????? ?????? ????? ?? ????? ????????
      currentY = 20;
    }
  }

  const pdfName = selectedRequest.projectName || "project";
  doc.save(`${pdfName}.pdf`);
}
  
const handleDownloadHistory = () => {
  if (!selectedRequest?.history) return;

  const doc = new jsPDF();
  doc.setFontSize(12);

  // ??????? ? ???????
  let yPosition = 20;          // ??????? ???????????? ??????? ??? ?????
  const leftMargin = 10;       // ?????? ?????
  const boxWidth = 190;        // ?????? ?????????????? (?4 ?? ????????? ~210??)
  const boxPadding = 5;        // ?????????? ?????? ?????? ?????? ?????
  const lineHeight = 6;        // ?????? ????? ?????? ??????
  const pageHeight = doc.internal.pageSize.height; // ?????? ????????

  selectedRequest.history.forEach((entry, index) => {
    // ????????? ?????? ? ????? ? ?????????????
    const dateString = new Date(entry.timestamp).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const changedByLine = `${entry.changedBy || "Unknown user"} \u2022 ${dateString}`;

    // ????????? ???? changes ?? ?????? (???? ??? ???? ??????? ????? ", ")
    // ????? ???????? ????????, ????????, ??????? " - " ????? ?????? ???????.
    const splittedChanges = (entry.changes || "").split(", ");
    const changesText = splittedChanges.join("\n");

    // jsPDF ?? ????? ????????????? ???????????? ?????? ?????? ??? ????,
    // ??????? ?????????? splitTextToSize.
    // ???????????? ?????? (boxWidth - 2*boxPadding), ????? ????? ?? ??????? ?? ???? ?????.
    const changedByLines = doc.splitTextToSize(changedByLine, boxWidth - 2 * boxPadding);
    const changesLines = doc.splitTextToSize(changesText, boxWidth - 2 * boxPadding);

    // ?????? ????? = ?????????? ????? * ?????? ?????? + ???????
    const blockHeight =
      (changedByLines.length + changesLines.length) * lineHeight + 2 * boxPadding;

    // ?????????, ?????????? ?? ???? ?? ??????? ????????.
    // ???? ??? � ????????? ????? ????????.
    if (yPosition + blockHeight + 10 > pageHeight) {
      doc.addPage();
      yPosition = 20;
    }

    // ?????? ???????????? ????????????? ? ???????? (???)
    doc.setFillColor(245, 245, 245); // #f5f5f5
    // roundedRect(x, y, width, height, ???????, ??????Y, ?????)
    doc.roundedRect(leftMargin, yPosition, boxWidth, blockHeight, 2, 2, "F");

    // ??????? ????? ?????? ??????????????
    doc.setTextColor(0, 0, 0);
    let textY = yPosition + boxPadding + lineHeight;
    changedByLines.forEach((line) => {
      doc.text(line, leftMargin + boxPadding, textY);
      textY += lineHeight;
    });
    changesLines.forEach((line) => {
      doc.text(line, leftMargin + boxPadding, textY);
      textY += lineHeight;
    });

    // ???????? yPosition ??? ?????????? ?????
    yPosition += blockHeight + 5;
  });

  doc.save("change-history.pdf");
};

   const statusOptionsForEdit =
  selectedRequest?.paymentStatus === 'Paid'
    ? [{ value: 'Pending', label: 'Pending' }, { value: 'Closed', label: 'Closed' }]
    : [{ value: formData.status, label: formData.status }];
      

  return (
    <Box sx={{ px: 4, py: 6 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Requests
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Avatar</strong></TableCell>
              <TableCell><strong>First Name</strong></TableCell>
              <TableCell><strong>Last Name</strong></TableCell>
              <TableCell><strong>Company</strong></TableCell>
              <TableCell><strong>Phone</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Project Name</strong></TableCell>
              <TableCell><strong>Service</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Price</strong></TableCell>
              <TableCell><strong>Payment</strong></TableCell>
              <TableCell><strong>Invoice</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{/* Avatar filter (????? ???????? ??????) */}</TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('firstName')}
                  value={filters.firstName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, firstName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, firstName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('lastName')}
                  value={filters.lastName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, lastName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, lastName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('companyName')}
                  value={filters.companyName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, companyName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, companyName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('phoneNumber')}
                  value={filters.phoneNumber}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, phoneNumber: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, phoneNumber: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('email')}
                  value={filters.email}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, email: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, email: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('projectName')}
                  value={filters.projectName}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, projectName: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, projectName: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('chosenService')}
                  value={filters.chosenService}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, chosenService: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, chosenService: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo
                  options={getUniqueValues('status')}
                  value={filters.status}
                  onChange={(event, newValue) =>
                    setFilters(prev => ({ ...prev, status: newValue || '' }))
                  }
                  onInputChange={(event, newInputValue) =>
                    setFilters(prev => ({ ...prev, status: newInputValue }))
                  }
                  renderInput={(params) => <TextField {...params} variant="standard" placeholder="Filter" />}
                />
              </TableCell>
              <TableCell>{/* Price filter */}</TableCell>
              <TableCell>{/* Payment filter */}</TableCell>
              <TableCell>{/* Invoice filter */}</TableCell>
              <TableCell>{/* Actions filter */}</TableCell>
            </TableRow>
          </TableHead>
         <TableBody>
  {pageItems.map(req => {
   const rowId = String(req.id || req._id);
  const userIdStr = String(user?._id);  // ??? user?.id
const viewedByAsStrings = req.viewedBy?.map(v => String(v)) || [];
const isNotViewed = !viewedByAsStrings.includes(userIdStr);
console.log("Row:", req._id, "viewedBy:", req.viewedBy, "myId:", userIdStr, "isNotViewed:", isNotViewed);

    // ?????? ?????:
    const rowStyle = {
      backgroundColor:
        req.status === 'Closed'
          ? 'rgba(0, 128, 0, 0.1)'
          : isNotViewed
          ? 'rgba(108, 68, 207, 0.1)'  // ?????????
          : 'inherit'
    };
    return (
      <TableRow
        key={rowId}
        onClick={() => handleDetailOpen(req)}
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
          ...rowStyle
        }}
      >
        <TableCell>
          <Avatar src={req.userId?.avatarUrl || ''}>
            {req.userId?.firstName ? req.userId.firstName.charAt(0) : ''}
          </Avatar>
        </TableCell>
        <TableCell>{req.firstName}</TableCell>
        <TableCell>{req.lastName}</TableCell>
        <TableCell>{req.companyName}</TableCell>
        <TableCell>{req.phoneNumber}</TableCell>
        <TableCell>{req.email}</TableCell>
        <TableCell>{req.projectName}</TableCell>
        <TableCell>{req.chosenService}</TableCell>
        <TableCell>{req.status}</TableCell>
        <TableCell>{req.price ? `\u20AC ${req.price}` : '-'}</TableCell>
        <TableCell>
          {user.role === 'user' ? (
            req.price && Number(req.price) > 0 ? (
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePayRequest(req);
                }}
              >
                Pay
              </Button>
            ) : (
              '-'
            )
          ) : (
            req.price && Number(req.price) > 0
              ? (req.paymentStatus || 'Waiting')
              : '-'
          )}
        </TableCell>
        <TableCell>
          {req.invoiceUrl ? (
            <IconButton component="a" href={req.invoiceUrl} target="_blank">
              <ReceiptIcon />
            </IconButton>
          ) : '-'}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleMenuOpen(e, req);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalFiltered}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100, 200, 500]}
                labelRowsPerPage="Rows per page"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* ???? ? ??????????????? ?????????? */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleDetailOpen()}>View / Edit</MenuItem>
        <MenuItem onClick={() => handleDownloadProject()}>Download Project</MenuItem>
        <MenuItem onClick={() => handleDownloadHistory()}>Download History</MenuItem>
        <MenuItem onClick={handleCloseProjectOpen}>Close project</MenuItem>
        {(user?.role === 'admin' || user?.role === 'manager') && (
          <>
            <MenuItem onClick={handleSendMailOpen}>Send mail</MenuItem>
          </>
        )}
        <MenuItem onClick={handleDeleteConfirmOpen}>Delete</MenuItem>
      </Menu>

      {/* ?????? ????????????? ???????? */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteConfirmClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose}>Cancel</Button>
          <Button onClick={handleDeleteRequest} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* ?????? ????????????? ???????? ??????? */}
      <Dialog open={closeProjectDialogOpen} onClose={handleCloseProjectClose}>
        <DialogTitle> {selectedRequest?.status === 'Closed' ? 'Download Project' : 'Close Project'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedRequest?.status === 'Closed'
              ? 'Do you want to download the project details as a PDF?'
              : 'Do you really want to close this project?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProjectClose}>Cancel</Button>
          {selectedRequest?.status === 'Closed' ? (
            <Button onClick={handleDownloadProject} variant="contained" sx={{
              backgroundColor: '#6c44cf',
              color: '#fff',
              textTransform: 'none',
              '&:hover': { backgroundColor: '#5a39b5' }
            }}>
              Download Project
            </Button>
          ) : (
            <Button onClick={handleConfirmCloseProject} variant="contained" sx={{
              backgroundColor: '#6c44cf',
              color: '#fff',
              textTransform: 'none',
              '&:hover': { backgroundColor: '#5a39b5' }
            }}>
              Close Project
            </Button>
          )}
        </DialogActions>
      </Dialog>


      {/* ?????? ???????? ?????? */}
      <Dialog open={sendMailDialogOpen} onClose={handleSendMailClose} fullWidth maxWidth="sm">
        <DialogTitle>Send Mail</DialogTitle>
        <DialogContent>
          <TextField
            label="To"
            fullWidth
            margin="normal"
            value={mailRecipient}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Subject"
            fullWidth
            margin="normal"
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
          />
          <TextField
            label="Email Content"
            fullWidth
            margin="normal"
            multiline
            minRows={6}
            value={mailBody}
            onChange={(e) => setMailBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendMailClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSendMail}>Send</Button>
        </DialogActions>
      </Dialog>

      {/* ?????? ?????????????? ??????? */}
      <Dialog open={detailDialogOpen} onClose={handleDetailClose} maxWidth="md" fullWidth>
        <DialogTitle>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography>Edit Request</Typography>

    {/* ?????? Select � �??????�-????? ?? ???????? */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {(() => {
        // ??????? ?????? ?? ??????? ?? ???????? ???????
        const currentOption =
          statusOptions.find((opt) => opt.value === formData.status) || {
            label: formData.status,
            icon: null,
          };

        return (
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              // ?????????? ???
              backgroundColor: '#6c44cf',
              color: '#fff',
              px: 2,
              py: 1,
              borderRadius: 2,
              // ??????? ????, ???? ?????? ??? "????????"
              boxShadow: 1,
            }}
          >
            {currentOption.icon && (
              <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                {currentOption.icon}
              </Box>
            )}
            <Typography variant="body1" sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
              {currentOption.label}
            </Typography>
          </Box>
        );
      })()}
    </Box>
  </Box>
</DialogTitle>
        <DialogContent dividers>
  <Box component="form" onSubmit={handleUpdateSubmit} noValidate sx={{ mt: 1 }}>
    <Section title="Client Information">
      <TextField
        name="firstName"
        label="First name"
        fullWidth
        required
        disabled // ?????? ?????????? ??? ??????????????
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.firstName}
      />
      <TextField
        name="lastName"
        label="Last name"
        fullWidth
        required
        disabled
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.lastName}
      />
      <TextField
        name="companyName"
        label="Company name"
        fullWidth
        disabled
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.companyName}
      />
      <TextField
        name="phoneNumber"
        label="Phone number"
        fullWidth
        required
        disabled
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.phoneNumber}
      />
      <TextField
        name="email"
        label="Email address"
        fullWidth
        required
        disabled
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.email}
      />
    </Section>

    <Section title="Project Name">
      <TextField
        name="projectName"
        label="Project Name"
        fullWidth
        required
        disabled
        sx={{ mb: 2 }}
        onChange={handleChange}
        value={formData.projectName}
      />
    </Section>

            <Section title="Choose a Service">
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Choose a Service</InputLabel>
                <Select name="chosenService" value={formData.chosenService} onChange={handleChange} label="Choose a Service" required disabled={selectedRequest?.paymentStatus === 'Paid'}>
                  <MuiMenuItem value="Digital-marketing">Digital-marketing</MuiMenuItem>
                  <MuiMenuItem value="Web & UX/UI & Graphic design">Web & UX/UI & Graphic design</MuiMenuItem>
                  <MuiMenuItem value="SEO">SEO</MuiMenuItem>
                </Select>
              </FormControl>
              
            </Section>

            {formData.chosenService === "Digital-marketing" && (
              <>
                <Section title="Marketing Service">
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Service Selection</InputLabel>
                    <Select name="serviceSelection" value={formData.serviceSelection} onChange={handleChange} label="Service Selection" required disabled={selectedRequest?.paymentStatus === 'Paid'}>
                      {services.map(service => (
                        <MuiMenuItem key={service} value={service}>
                          {service}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Campaign Objective</InputLabel>
                    <Select name="campaignObjective" value={formData.campaignObjective} onChange={handleChange} label="Campaign Objective" disabled={selectedRequest?.paymentStatus === 'Paid'}>
                      {objectives.map(objective => (
                        <MuiMenuItem key={objective} value={objective}>
                          {objective}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Target Audience">
                  <Autocomplete
                    multiple
                    options={countriesList}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    onChange={handleAutocompleteChange('countries')}
                    value={formData.countries}
                    disabled={selectedRequest?.paymentStatus === 'Paid'}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Country(ies)" placeholder="Select countries..." / >
                    )}
                    sx={{ mb: 2, width: '100%' }}
                  />
                  <Autocomplete
                    multiple
                    options={filteredCities}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    onChange={handleAutocompleteChangeForCities}
                    disabled={selectedRequest?.paymentStatus === 'Paid'}
                    value={filteredCities.filter(cityObj => formData.cities.includes(cityObj.name))}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="City(ies)" placeholder={formData.countries.length > 0 ? "Select cities..." : "Select country first"} />
                    )}
                    sx={{ mb: 2, width: '100%' }}
                  />
                  <MultiSelectField
                    label="Age Range"
                    options={ageRanges}
                    value={formData.ageRange}
                    onChange={handleMultiSelectChange('ageRange')}
                    disabled={selectedRequest?.paymentStatus === 'Paid'}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Gender</InputLabel>
                    <Select name="gender" value={formData.gender} onChange={handleChange} label="Gender" disabled={selectedRequest?.paymentStatus === 'Paid'}>
                      {genders.map(g => (
                        <MuiMenuItem key={g} value={g}>
                          {g}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Keywords">
                  <TextField name="keywords" label="Keywords" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={formData.keywords} disabled={selectedRequest?.paymentStatus === 'Paid'}/>
                </Section>

                <Section title="Campaign Details">
                  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField name="approximateBudget" label="Approximate Budget" type="number" fullWidth onChange={handleChange} value={formData.approximateBudget} />
                    <Autocomplete
                      options={currencies.sort((a, b) => {
                        const favorites = ['EUR', 'USD'];
                        const aFav = favorites.includes(a) ? 0 : 1;
                        const bFav = favorites.includes(b) ? 0 : 1;
                        if (aFav !== bFav) return aFav - bFav;
                        return a.localeCompare(b);
                      })}
                      groupBy={(option) =>
                        ['EUR', 'USD'].includes(option)
                          ? 'Popular'
                          : 'Search for your currency'
                      }
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Currency" placeholder="Search for your currency" disabled={selectedRequest?.paymentStatus === 'Paid'} />
                      )}
                      onChange={(event, newValue) =>
                        setFormData(prev => ({ ...prev, currency: newValue }))
                      }
                      value={formData.currency || ''}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                  <TextField name="targetAudienceDescription" label="Target Audience Description" fullWidth multiline rows={4} sx={{ mb: 2 }} onChange={handleChange} value={formData.targetAudienceDescription} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Contact Database</InputLabel>
                    <Select name="contactDatabase" value={formData.contactDatabase} onChange={handleChange} label="Contact Database" disabled={selectedRequest?.paymentStatus === 'Paid'}>
                      {contactDatabases.map(db => (
                        <MuiMenuItem key={db} value={db}>
                          {db}
                        </MuiMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Section>

                <Section title="Additional Information">
                  <TextField name="additionalMessage" label="Message" fullWidth multiline rows={4} sx={{ mb: 2 }} onChange={handleChange} value={formData.additionalMessage} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                </Section>
              </>
            )}

            {formData.chosenService === "Web & UX/UI & Graphic design" && (
              <>
                <Section title="Graphic Service">
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Graphic Service</InputLabel>
                    <Select name="graphicService" value={formData.graphicService} onChange={handleChange} label="Graphic Service" required disabled={selectedRequest?.paymentStatus === 'Paid'}>
                      <MuiMenuItem value="Web design">Web design</MuiMenuItem>
                      <MuiMenuItem value="UX/UI design">UX/UI design</MuiMenuItem>
                      <MuiMenuItem value="Graphic design">Graphic design</MuiMenuItem>
                    </Select>
                  </FormControl>
                </Section>

                {formData.graphicService && (
                  <Section title="Description">
                    <TextField name="graphicDescription" label="Description" fullWidth multiline rows={4} sx={{ mb: 2 }} onChange={handleChange} value={formData.graphicDescription} required disabled={selectedRequest?.paymentStatus === 'Paid'} />
                    {(formData.graphicService === "Web design" ||
                      formData.graphicService === "UX/UI design") && (
                      <>
                        <TextField name="graphicWebsite1" label="Inspiration Website 1" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={formData.graphicWebsite1} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                        <TextField name="graphicWebsite2" label="Inspiration Website 2" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={formData.graphicWebsite2} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                        <TextField name="graphicWebsite3" label="Inspiration Website 3" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={formData.graphicWebsite3} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                      </>
                    )}
                    <Button variant="outlined" component="label" sx={{ mb: 2 }}>
                      Upload Files
                      <input type="file" hidden multiple onChange={handleFileChange} disabled={selectedRequest?.paymentStatus === 'Paid'} />
                    </Button>
                    {formData.graphicFiles.length > 0 && (
                      <Box mt={2}>
                        {formData.graphicFiles.map((file, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            border="1px solid #ccc"
                            borderRadius="4px"
                            p={1}
                            mb={1}
                          >
                            {file.preview && file.type && file.type.startsWith('image/') ? (
                              <a href={file.preview} target="_blank" rel="noopener noreferrer">
                                <img
                                  src={file.preview}
                                  alt="Preview"
                                  width={50}
                                  height={50}
                                  style={{ marginRight: 10 }}
                                />
                              </a>
                            ) : (
                              <a href={file.preview} target="_blank" rel="noopener noreferrer">
                                <Box sx={{ mr: 2 }}>
                                  {getIconByMimeType(file.type)}
                                </Box>
                              </a>
                            )}
                            <Typography variant="body2" sx={{ flexGrow: 1 }}>
                              <a href={file.preview} target="_blank" rel="noopener noreferrer">
                                {file.name}
                              </a>
                            </Typography>
                            <IconButton onClick={() => handleRemoveFile(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                      
                    )}
                  </Section>
                )}
              </>
            )}

            {formData.chosenService === "SEO" && (
              <Section title="Your Webpage">
                <TextField name="seoWebpage" label="Webpage" fullWidth sx={{ mb: 2 }} onChange={handleChange} value={formData.seoWebpage} required disabled={selectedRequest?.paymentStatus === 'Paid'} />
                <TextField name="seoDescription" label="Description" fullWidth multiline rows={4} sx={{ mb: 2 }} onChange={handleChange} value={formData.seoDescription} required disabled={selectedRequest?.paymentStatus === 'Paid'} />
              </Section>
            )}

            {/* ???? Price (????? ?????? admin/manager) */}
            {(user?.role === 'admin' || user?.role === 'manager') && (
              <Section title="Price">
               <TextField
  name="price"
  label="Price"
  type="number"
  disabled={selectedRequest?.paymentStatus === 'Paid'}
  fullWidth
  sx={{ mb: 2, backgroundColor: '#f5f5f5', borderRadius: 1 }}
  onChange={handleChange}
  value={formData.price}
/>
              </Section>
            )}
          </Box>
          
           {/* ?????? ??? ?????????/??????? ??????? */}
    <Box sx={{ mt: 2 }}>
      <Button
        variant="outlined"
        onClick={() => setShowHistory(prev => !prev)}
      >
        {showHistory ? 'Hide History' : 'Show History'}
      </Button>
    </Box>

    {/* ???? ???????, ????????? ? Collapse */}
    <Collapse in={showHistory} unmountOnExit sx={{ mt: 2 }}>
      <Section title={
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Typography variant="h5">Change History</Typography>
    <Button variant="contained" onClick={handleDownloadHistory}>
      Download History
    </Button>
  </Box>
}>
  {selectedRequest?.history?.map((entry, index) => (
    <Box key={index} sx={{ mb: 2, p: 1, bgcolor: "#f5f5f5", borderRadius: 1 }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
        {entry.changedBy || "Unknown user"} {"\u2022"}{" "}
        {new Date(entry.timestamp).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </Typography>
      {(entry.changes || "").split(", ").map((change, i) => (
        <Typography key={i} variant="body2" sx={{ fontFamily: "monospace" }}>
          {change.replace('?', '?')}
        </Typography>
      ))}
    </Box>
  ))}
</Section>
    </Collapse>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
  {(user?.role === 'admin' || user?.role === 'manager') && (
    <Button
      onClick={handleCloseProjectOpen}
      sx={{
        backgroundColor: '#6c44cf',
        color: '#fff',
        textTransform: 'none',
        '&:hover': { backgroundColor: '#5a39b5' }
      }}
    >
      <CheckCircleIcon sx={{ mr: 1 }} />
      Close Project
    </Button>
  )}
  <Box>
    <Button onClick={handleDetailClose}>Close</Button>
    {/* ???? ?????? ?? ????????, ?????????? ?????? Save, ????? ????????? ????????? */}
    {selectedRequest?.paymentStatus !== 'Paid' && (
      <Button variant="contained" color="primary" onClick={handleUpdateSubmit}>
        Save
      </Button>
    )}
  </Box>
</DialogActions>

      </Dialog>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );

  // ??????? ??? ?????????????? ?????? ??????? ??? ??????????????
  function transformForEdit(request) {
    const transformed = {
      ...emptyFormData,
      ...request,
    };
    if (transformed.graphicFiles && transformed.graphicFiles.length > 0) {
      transformed.graphicFiles = transformed.graphicFiles.map(file => ({
        ...file,
        name: file.originalName,
        preview: file.path ? '/' + file.path : null,
        type: file.mimeType || ''
      }));
    }
    return transformed;
  }
}

function Section({ title, children }) {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}

function MultiSelectField({ label, options, value, onChange }) {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map(option => (
          <MuiMenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MuiMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
